<template>
  <section class="home">
    <Slider v-if="slides && slides.length" :items="slides"></Slider>
    <div v-if="settings.categoryProducts">
      <div v-for="(category, i) in categoryProducts" :key="'even' + i">
        <ItemsCarousel
          v-if="i % 2 === 0"
          :title="category.title"
          :slug="category.slug"
          :products="category.products"
        ></ItemsCarousel>
      </div>
    </div>

    <Banners
      v-if="topBanners && topBanners.length"
      :items="topBanners"
    ></Banners>
    <Spinner v-else :circle="true"></Spinner>

    <!-- lateset products -->
    <NewReleases v-if="settings.latestProducts"></NewReleases>
    <!-- lateset products -->

    <Spinner v-if="loading" :circle="true"></Spinner>
    <div v-else>
      <FavoriteSlider
        v-if="
          settings.favoriteProducts &&
            homepage.Favorites &&
            homepage.Favorites.length
        "
        :products="homepage.Favorites"
      ></FavoriteSlider>

      <BestSell
        v-if="
          settings.bestSaleProducts &&
            homepage.BestSales &&
            homepage.BestSales.length
        "
        :products="homepage.BestSales"
        :banner="bestSellBanner"
      ></BestSell>

      <div v-if="settings.categoryProducts">
        <div v-for="(category, i) in categoryProducts" :key="'odd' + i">
          <ItemsCarousel
            v-if="i % 2 !== 0"
            :title="category.title"
            :slug="category.slug"
            :products="category.products"
          ></ItemsCarousel>
        </div>
      </div>
      <br />

      <!-- amazing products -->
      <AzmazingProducts v-if="settings.amazingProducts"></AzmazingProducts>
      <!-- amazing products -->

      <br />
      <SelectedProducts
        v-if="
          settings.selectedProducts &&
            homepage.SelectiveProducts &&
            homepage.SelectiveProducts.length
        "
        :products="homepage.SelectiveProducts"
      ></SelectedProducts>
      <br />
      <br />
    </div>
  </section>
</template>
<script>
// @ is an alias to /src
import ItemsCarousel from "@/components/ItemsCarousel.component";
import Slider from "@/components/Slider.component";
import Banners from "@/components/slider/Banners.component";
import AzmazingProducts from "@/components/slider/AzmazingProducts.component";
import FavoriteSlider from "@/components/slider/Favorite.component";
import BestSell from "@/components/slider/BestSell.component";
import SelectedProducts from "@/components/slider/SelectedProducts.component";
import NewReleases from "@/components/slider/NewReleases.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "Home",
  data: function() {
    return {
      slides: [],
      topBanners: [],
      categoryProducts: [],
      homepage: {
        BestSales: [],
        SelectiveProducts: []
      },
      latestProducts: [],
      loading: true,
      newReleasesBanner: {},
      bestSellBanner: {},
      settings: {}
    };
  },
  components: {
    Spinner,
    Slider,
    ItemsCarousel,
    Banners,
    FavoriteSlider,
    BestSell,
    AzmazingProducts,
    SelectedProducts,
    NewReleases
  },
  computed: {},
  // mounted() {},
  created() {
    //get slides
    this.$store.dispatch("getSlides").then(result => {
      this.slides = result;
    });

    //get top banners
    this.$store.dispatch("getTopBanners").then(result => {
      this.topBanners = result;
    });

    //get products per category
    this.$store.dispatch("getProductsPerCategory").then(result => {
      this.categoryProducts = result;
    });

    this.$store.dispatch("getHomePage").then(result => {
      this.homepage = result;
      this.settings = result.settings;

      // this.newReleasesBanner = {
      //   image: result.banner01,
      //   url: result.banner01_url,
      // };

      this.bestSellBanner = {
        image: result.banner02,
        url: result.banner02_url
      };
      this.loading = false;
    });
  },
  methods: {}
};
</script>
<style scoped>
.not-exist-product {
  text-align: center;
  padding: 20px;
  margin-top: 100px;
  margin-bottom: 100px;
}
</style>
