<template>
  <section>
    <Breadcrumb currentPageName="سبد خرید شما"></Breadcrumb>
    <Spinner v-if="loading"></Spinner>
    <div v-else>
      <!-- basket flow -->

      <!-- basket flow -->

      <!-- basket details -->
      <div v-if="products.length !== 0" class="m-0">
        <div class="container-fluid">
          <div class="row mt-3">
            <div class="col-md-8">
              <div class="table-responsive"
              >
                <!-- basket items -->
                <table class="table table-striped table-hover text-right">
                  <thead class="table-light">
                  <tr>
                    <th>تصویر</th>
                    <th>کالا</th>
                    <th>ناشر</th>
                    <th class="text-center">تعداد</th>
                    <th>نوبت چاپ</th>
                    <th>قیمت واحد</th>
                    <th>حذف محصول</th>
                  </tr>
                  </thead>
                  <tbody style="border: none;box-shadow: 2px 2px 7px rgba(232,232,232,0.5)">
                  <tr v-for="(product, index) in products" :key="product[index]">
                    <td>
                      <router-link
                          tag="a"
                          value="مشاهده‌ی جزئیات محصول"
                          type="button"
                          :to="'/product/' + product.slug"
                      >
                        <img style="border-radius: 10px;box-shadow: 2px 2px 7px rgba(139,140,157,0.55)"
                             :src="product.image"
                             width="50px"
                             class="product-img"
                             @error="product.image = '/template/images/no-image.png'"
                             alt="مشاهده‌ی جزئیات محصول"
                        />
                      </router-link>
                    </td>
                    <td>
                      <router-link
                          tag="a"
                          value="مشاهده‌ی جزئیات محصول"
                          type="button"
                          :to="'/product/' + product.slug"
                      >
                        {{ product.name }}
                      </router-link>
                    </td>
                    <td>
                      <p class="mb-0">{{ product.brand }}</p>
                    </td>
                    <td style="position: relative">
                      <!-- update loading -->

                      <div
                          v-if="getLoader(product.productId)"
                          class="counter-loader"
                      >
                        <div class="spinner-grow spinner-grow-sm" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <!-- update loading -->
                      <!-- counter -->
                      <div class="d-flex flex-column align-items-center">
                    <span
                        v-if="
                        Number(product.quantity) > Number(product.max_limit)
                      "
                        style="color: red; font-size: 12px; margin-top: 5px"
                    >
                      درخواست بیش از موجودی
                      <br/>
                      تعداد درخواستی : {{ product.quantity }} موجودی :
                      {{ product.max_limit }}
                    </span>
                        <div class="apply-counter d-flex" @click="updateProductBasket(product)">
                          <counter
                              :key="index"
                              :min="Number(product.min_limit)"
                              :max="Number(product.max_limit)"
                              :value="product.quantity ? Number(product.quantity) : 1"
                              @input="product.quantity = Number($event)"
                          ></counter>
                          <!--                      <button @click="updateProductBasket(product)">-->
                          <!--                        <i class="fa fa-edit"></i>-->
                          <!--                        ثبت-->
                          <!--                      </button>-->
                        </div>
                      </div>
                      <!-- counter -->
                    </td>
                    <td>
                      {{ product.publish }}
                    </td>
                    <td>
                      <!-- special price -->
                      <div
                          v-if="product.special_price && product.special_price !== 0"
                      >
                        <div
                            class="d-flex align-items-center justify-content-center"
                        >
                          <p class="old-price">
                            {{ new Intl.NumberFormat().format(product.price) }}
                            ریال
                          </p>
                          <p class="discount-percent mr-2" style="width: 50px">
                            {{
                              new Intl.NumberFormat().format(
                                  (
                                      ((Number(product.price) -
                                              Number(product.special_price)) /
                                          Number(product.price)) *
                                      100
                                  ).toFixed(0)
                              ) + "%"
                            }}
                          </p>
                        </div>
                        <p class="new-price">
                          {{
                            new Intl.NumberFormat().format(product.special_price)
                          }}
                          ریال
                        </p>
                      </div>
                      <!-- special price -->

                      <!-- price -->
                      <p v-else class="new-price">
                        {{ new Intl.NumberFormat().format(product.price) }}
                        ریال
                      </p>
                      <!-- price -->
                    </td>
                    <td>
                      <div class="delete-icon">
                        <i
                            class="fa fa-remove"
                            @click="removeFromBasket(product)"
                        ></i>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!-- basket items -->
              </div>
            </div>
            <div class="col-md-4 d-flex align-items-end justify-content-center my-3">
              <div class="row">
                <div class="col-12">
                  <ul class="basket-flow">
                    <li>
                      <img src="/template/images/shoping-icon-top.svg" alt="shoping"/>
                      <p>سبد خرید</p>
                    </li>
                    <li class="line active"></li>
                    <li class="line"></li>
                    <li>
                      <img src="/template/images/address-icon.svg" alt="address"/>
                      <p>اطلاعات ارسال</p>
                    </li>
                    <li class="line"></li>
                    <li class="line"></li>
                    <li>
                      <img src="/template/images/cart-shoping.svg" alt="cart"/>
                      <p>ثبت نهایی</p>
                    </li>
                  </ul>
                </div>
                <div class="col-12">
                  <div class="card" style="background: #eee;border: none;border-radius: 25px">
                    <div class="card-body">
                      <table class="invoice-table">
                        <tbody>
                        <tr>
                          <td>قیمت نهایی</td>
                          <td>{{ new Intl.NumberFormat().format(price.total) }} ریال</td>
                        </tr>
                        <!-- <tr>
                        <td>میزان تخفیف</td>
                        <td>{{ new Intl.NumberFormat().format(discount.res) }}%</td>
                      </tr> -->
                        <tr>
                          <td>
                            <button
                                class="info-btn ml-3 mr-3"
                                @click="$router.push('/search')"
                            >
                              ادامه‌ی سفارش
                            </button>
                          </td>
                          <td>
                            <button class="submit-btn mr-3 ml-3" @click="registerOrder()">
                              تکمیل خرید
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>


            </div>


          </div>

        </div>

        <!-- basket final info -->

        <!-- basket final info -->
      </div>
      <!-- basket details -->

      <!-- empty basket-->
      <div v-else class="d-flex justify-content-center empty-basket my-3">

        <div class="card" style=";border: none;box-shadow: 2px 2px 7px rgba(139,140,157,0.5);border-radius: 25px">
          <div class="card-body">
            <div class="col-xl-12" style="text-align: center;">
              <img src="/template/images/empty-basket.svg" alt=""/>
              <p>شما هیچ کالایی در سبد خرید خود ندارید.</p>
              <button class="info-btn ml-3 mr-3" @click="$router.push('/search')">
                ادامه‌ی سفارش
              </button>
            </div>
          </div>
        </div>

      </div>
      <!-- empty basket -->
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.component";
import counter from "@/components/counter.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "Shopping-Cart",
  components: {Breadcrumb, counter, Spinner},
  data() {
    return {
      loading: false,
      active: true,
      loaders: [],
      products: []
    };
  },
  computed: {
    // products() {
    //   return this.$store.getters.getBasket;
    // },
    price() {
      let total = 0;

      this.products.forEach(item => {
        total +=
            item.special_price && item.special_price != 0
                ? Number(item.special_price) * Number(item.quantity)
                : Number(item.price) * Number(item.quantity);
      });

      return {total};
    },
    discount() {
      let total = 0;

      this.products.forEach(
          item => (total += Number(item.price) * Number(item.quantity))
      );

      let special_prices = 0;

      this.products.forEach(
          item =>
              (special_prices +=
                  item.special_price && item.special_price != 0
                      ? Number(item.special_price) * Number(item.quantity)
                      : 0)
      );

      let res = (
          ((Number(total) - Number(special_prices)) / Number(total)) *
          100
      ).toFixed(0);
      return {res};
    }
  },
  created() {
    //enable loader
    this.loading = true;

    this.$store.dispatch("getBasketReq").then(() => {
      this.products = this.$store.getters.getBasket;
      //disable loader
      this.loading = false;
    });
  },
  beforeRouteUpdate() {
    //enable loader
    this.loading = true;

    this.$store.dispatch("getBasketReq").then(() => {
      this.products = this.$store.getters.getBasket;
      //disable loader
      this.loading = false;
    });
  },
  methods: {
    getLoader(id) {
      let res = this.loaders.find(obj => obj == id);
      return res ? true : false;
    },
    singlePrice(special_price, price) {
      return special_price > 100
          ? Number(price) - Number(price)
          : (
              Number(price) -
              (Number(price) * Number(special_price)) / 100
          ).toFixed(0);
    },
    removeFromBasket(product) {
      this.showProfileQuickAccess = false;
      this.$store
          .dispatch("removeFromBasket", {
            product: product
          })
          .then(() => {
            this.products = this.$store.getters.getBasket;
          });
      //is token exist remove from db
      if (localStorage.getItem("token")) {
        this.$store
            .dispatch("removeFromBasketReq", {
              product: product
            })
            .then(res => {
              if (!res) {
                this.$swal
                    .fire({
                      position: "center",
                      icon: "warning",
                      title: "خطا در حذف محصول",
                      showConfirmButton: false,
                      timer: 1500
                    })
                    .then(() => {
                      //failed then add to basket again
                      this.$store.dispatch("addToBasket", {
                        product: product
                      });
                    });
              }
            });
      } else {
        //failed then add to basket again
        this.$store.dispatch("addToBasket", {
          product: product
        });
      }
      // });
    },
    updateProductBasket(product) {
      let vm = this;
      // setTimeout(function () {

      //enable loader
      this.loaders.push(product.productId);

      // let selected = {
      //   image: product.image,
      //   productId: product.productId,
      //   productTitle: product.productTitle,
      //   price: product.price,
      //   min_limit: product.min_limit,
      //   max_limit: product.max_limit,
      //   quantity: product.quantity,
      //   publish: product.publish.publish,
      //   options: product.options,
      // };

      vm.$store
          .dispatch("updateProductBasketReq", {
            product: product
          })
          .then(res => {
            if (res) {
              //disable loader
              this.loaders = this.loaders.filter(
                  obj => obj !== product.productId
              );
            } else {
              //disable loader
              this.loaders = this.loaders.filter(
                  obj => obj !== product.productId
              );

              this.loading = true;
              //update basket & get products again
              vm.$store.dispatch("getBasketReq").then(items => {
                this.products = items;
                this.loading = false;
              });

              vm.$swal.fire({
                position: "center",
                icon: "warning",
                title: "خطا در ویرایش کردن محصول",
                showConfirmButton: false,
                timer: 1500
              });
            }
          });
      // }, 1000);
    },
    registerOrder() {
      let arr = [];
      this.products.map(function (value) {
        arr.push(value);
        // this.updateProductBasket(value)
        console.info(arr.push(value))
      });
      let res = this.getError();
      if (res) {
        this.$swal.fire({
          position: "center",
          icon: "error",
          title: "لطفا کالا های ناموجود را حذف کنید",
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }

      //enable loader
      this.loading = true;

      let products = this.products;
      this.$store
          .dispatch("reqRegisterOrder", {
            products: products
          })
          .then(res => {
            if (res.status === 200) {
              this.$router.push(res.path);
            } else if (res.status === 401) {
              this.$swal
                  .fire({
                    position: "center",
                    icon: "warning",
                    title: "لطفا وارد حساب کاربری خود شوید !",
                    showConfirmButton: false,
                    timer: 1500
                  })
                  .then(() => {
                    this.$router.push({name: "auth"});
                    return;
                  });
            } else if (
                res.status === 400 &&
                res.data.status == "account_not_accept"
            ) {
              this.$swal.fire({
                position: "center",
                icon: "warning",
                title: "حساب کاربری شما تایید نشده است",
                showConfirmButton: false,
                timer: 1500
              });
              //disable loader
              this.loading = false;
            } else if (
                res.status === 400 &&
                res.data.status == "address_not_exist"
            ) {
              this.$router.push({name: "cartAddress"});
            } else if (res.status === 400 && res.data.status == "out_stock") {
              this.$store.dispatch("getBasketReq").then(() => {
                this.products = this.$store.getters.getBasket;
                this.loading = false;
              });
            }
          });
    },
    getError() {
      for (let i = 0; i < this.products.length; i++) {
        if (
            Number(this.products[i].quantity) > Number(this.products[i].max_limit)
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
  }
};
</script>

<style scoped>
.custom-table-container {
  padding: 1rem;
}

.custom-table {
  border-radius: 10px;
}

.custom-table tbody {
  background-color: #ffffff;
}

.delete-icon {
  width: 25px;
  height: 25px;
  background: #ff4476;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

/* customize loader */
.counter-loader {
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  bottom: 0;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
}

.apply-counter button {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: black;
  color: #fff;
  border: none;
  padding: 0 5px;
  height: 35px;
  margin: auto;
}

.apply-counter .num-counter {
  margin: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/* customize loader */
/* basket flow */
.basket-flow {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 2rem 0;
}

.basket-flow li:not(.line) {
  text-align: center;
  margin: 0 5px;
}

.basket-flow li img {
  width: 50px;
  height: 50px;
}

.basket-flow li.line {
  width: 50px;
  height: 5px;
  background-color: gray;
}

.basket-flow li.line.active {
  background-color: #ff4476;
}

@media screen and (max-width: 600px) {
  .basket-flow li {
    font-size: 11px;
  }

  .basket-flow li img {
    width: 20px;
    height: 20px;
  }

  .basket-flow li.line {
    width: 20px;
    height: 3px;
  }
}

/* basket flow */
.shopping-product {
  border: 1px solid #1c403e8f;
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .shopping-product {
    margin-left: 0 !important;
  }

  .card-price {
    margin-top: 1rem;
  }
}

/* invoice table */
.invoice-table {
  margin: auto;
  background-color: #ffffff;
  border-radius: 10px;
}

.invoice-table td {
  padding: 0.5rem 1rem;
  text-align: center;
}

.invoice-table tr > td:first-child {
  margin: auto;
}
</style>
