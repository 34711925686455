<template>
  <section class="slider-section">
    <VueSlickCarousel v-bind="settings">
      <a v-for="(item, i) in items" :key="i" :href="item.url">
        <img v-lazy="item.image" :title="item.name" :alt="item.name" />
      </a>
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  props: ["items"],
  components: {
    VueSlickCarousel
  },
  data: function() {
    return {
      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: true,

        autoplay: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    };
  }
};
</script>

<style scoped>
.slider-section .slick-slider {
  margin: 0 !important;
}

/* .slider-section .VueCarousel-slide {
  display: flex;
}

.slider-section .VueCarousel-slide a {
  width: 100%;
} */

.slick-slide img {
  width: 100%;
  /* height: auto; */
  /* max-width: 1900px; */
  /* max-height: 500px; */
  /* max-height: 600px; */
  object-fit: cover;
  background-size: cover;
}

@media only screen and (min-width: 1260px) {
  .slick-slider {
    height: 420px !important;
  }
}
@media only screen and (min-width: 1320px) {
  .slick-slider {
    height: 440px !important;
  }
}
@media only screen and (min-width: 1380px) {
  .slick-slider {
    height: 450px !important;
  }
}
@media only screen and (min-width: 1400px) {
  .slick-slider {
    height: 455px !important;
  }
}
@media only screen and (min-width: 1450px) {
  .slick-slider {
    height: 470px !important;
  }
}
@media only screen and (min-width: 1500px) {
  .slick-slider {
    height: 500x !important;
  }
}
@media only screen and (min-width: 1550px) {
  .slick-slider {
    height: 520px !important;
  }
}
@media only screen and (min-width: 1650px) {
  .slick-slider {
    height: 570px !important;
  }
}
@media only screen and (min-width: 1800px) {
  .slick-slider {
    height: 600px !important;
  }
}
@media only screen and (min-width: 1920px) {
  .slick-slider {
    height: 620px !important;
  }
}
@media only screen and (max-width: 1250px) {
  .slick-slider {
    height: 400px !important;
  }
}

@media only screen and (max-width: 1150px) {
  .slick-slider {
    height: 375px !important;
  }
}
@media only screen and (max-width: 1100px) {
  .slick-slider {
    height: 370px !important;
  }
}
@media only screen and (max-width: 1085px) {
  .slick-slider {
    height: 357px !important;
  }
}
@media only screen and (max-width: 1040px) {
  .slick-slider {
    height: 325px !important;
  }
}

@media only screen and (max-width: 950px) {
  .slick-slider {
    height: 300px !important;
  }
}
@media only screen and (max-width: 860px) {
  .slick-slider {
    height: 265px !important;
  }
}
@media only screen and (max-width: 750px) {
  .slick-slider {
    height: 250px !important;
  }
}
@media only screen and (max-width: 660px) {
  .slick-slider {
    height: 240px !important;
  }
}
@media only screen and (max-width: 650px) {
  .slick-slider {
    height: 220px !important;
  }
}
@media only screen and (max-width: 590px) {
  .slick-slider {
    height: 200px !important;
  }
}

@media only screen and (max-width: 470px) {
  .slick-slider {
    height: 165px !important;
  }
}

@media only screen and (max-width: 420px) {
  .slick-slider {
    height: 145px !important;
  }
}

@media only screen and (max-width: 360px) {
  .slick-slider {
    height: 115px !important;
  }
}

/* 
@media only screen and (max-width: 600px) {
.slick-slider {
  height:180px !important;
  }
} */
</style>
