<template>
  <Spinner
    v-if="loading && products && products.length !== 0"
    :circle="true"
  ></Spinner>

  <div v-else-if="products && products.length !== 0" class="mt-5 mb-5">
    <div class="col-lg-11 m-auto">
      <h4 class="text-right" style="margin: 0 3.25rem;">قفسه‌ی شگفت انگیز</h4>
    </div>
    <div class="amazing row align-items-center">
      <div class="col-lg-11 m-auto" align="center">
        <VueSlickCarousel v-bind="settings">
          <OffersProductItem
            v-for="(product, index) in products"
            :key="product[index]"
            :product="product"
          ></OffersProductItem>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import OffersProductItem from "@/components/OffersProductItem.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "bestsell",
  components: {
    VueSlickCarousel,
    OffersProductItem,
    Spinner
  },
  data: function() {
    return {
      loading: true,
      products: [],
      settings: {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          }
        ]
      }
    };
  },
  mounted() {
    //get products per category
    this.$store.dispatch("getOfferProducts").then(result => {
      this.products = result;
      this.loading = false;
    });
  }
};
</script>

<style scoped>
.amazing h4 {
  /* color: #fff; */
}
.amazing {
  padding: 2rem 0.5rem;
  margin: 1rem;
  border-radius: 10px;
  background-color: #001e49;
}
.amazing .main-container {
  background-color: #fff !important;
}
@media only screen and (max-width: 500px) {
  .amazing .title {
    font-size: 11px !important;
  }
}
</style>
