var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"home"},[(_vm.slides && _vm.slides.length)?_c('Slider',{attrs:{"items":_vm.slides}}):_vm._e(),(_vm.settings.categoryProducts)?_c('div',_vm._l((_vm.categoryProducts),function(category,i){return _c('div',{key:'even' + i},[(i % 2 === 0)?_c('ItemsCarousel',{attrs:{"title":category.title,"slug":category.slug,"products":category.products}}):_vm._e()],1)}),0):_vm._e(),(_vm.topBanners && _vm.topBanners.length)?_c('Banners',{attrs:{"items":_vm.topBanners}}):_c('Spinner',{attrs:{"circle":true}}),(_vm.settings.latestProducts)?_c('NewReleases'):_vm._e(),(_vm.loading)?_c('Spinner',{attrs:{"circle":true}}):_c('div',[(
        _vm.settings.favoriteProducts &&
          _vm.homepage.Favorites &&
          _vm.homepage.Favorites.length
      )?_c('FavoriteSlider',{attrs:{"products":_vm.homepage.Favorites}}):_vm._e(),(
        _vm.settings.bestSaleProducts &&
          _vm.homepage.BestSales &&
          _vm.homepage.BestSales.length
      )?_c('BestSell',{attrs:{"products":_vm.homepage.BestSales,"banner":_vm.bestSellBanner}}):_vm._e(),(_vm.settings.categoryProducts)?_c('div',_vm._l((_vm.categoryProducts),function(category,i){return _c('div',{key:'odd' + i},[(i % 2 !== 0)?_c('ItemsCarousel',{attrs:{"title":category.title,"slug":category.slug,"products":category.products}}):_vm._e()],1)}),0):_vm._e(),_c('br'),(_vm.settings.amazingProducts)?_c('AzmazingProducts'):_vm._e(),_c('br'),(
        _vm.settings.selectedProducts &&
          _vm.homepage.SelectiveProducts &&
          _vm.homepage.SelectiveProducts.length
      )?_c('SelectedProducts',{attrs:{"products":_vm.homepage.SelectiveProducts}}):_vm._e(),_c('br'),_c('br')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }