<template>
  <div class="form col-xl-6 col-md-8">
    <div class="card p-3" style="background: #eee;border-radius: 15px">
      <div class="card-title">
        <h5 class="mb-3 text-center">ورود یا ثبت‌نام</h5>
      </div>
      <div class="card-body">
        <Spinner v-if="loading"> </Spinner>

        <div v-else class="w-100">
          <!-- show error  -->
          <p v-if="$store.getters.getErrors" class="text-center" style="color: red">
            {{ $store.getters.getErrors }}
          </p>
          <!-- show error  -->

          <!-- login items  -->
          <div class="row mb-3">
            <div class="d-flex align-items-center">
              <label>ایمیل:</label>
              <span
                  v-if="emailError"
                  class="mr-3 mb-1"
                  style="font-size: 12px; color: red"
              >ایمیل الزامی است</span
              >
            </div>
            <input
                v-model="email"
                ref="email"
                class="input mt-0"
                type="email"
                name="email"
                placeholder="پست الکترونیک"
            />
          </div>
          <div class="row mb-3">
            <div class="d-flex align-items-center">
              <label>رمز عبور:</label>
              <span
                  v-if="passwordError"
                  class="mr-3 mb-1"
                  style="font-size: 12px; color: red"
              >رمزعبور الزامی است</span
              >
            </div>
            <input
                v-model="password"
                ref="password"
                class="input mt-0"
                type="password"
                name="password"
                placeholder="رمز عبور"
            />
          </div>
          <!-- login items -->

          <!-- buttons  -->
          <div class="d-flex">
            <input
                @click="login"
                class="submit-btn w-100 ml-3"
                type="button"
                value="ورود"
            />
            <input
                @click="register"
                class="info-btn w-100"
                type="button"
                value="حساب کاربری ندارم"
            />
          </div>
          <div class="d-flex mt-3">
            <a @click="forgot" style="cursor: pointer">
              رمز عبور خود را فراموش کرده‌اید؟</a
            >
          </div>
          <!-- buttons  -->
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import Spinner from "@/components/Spinner.component";

export default {
  name: "login",
  components: { Spinner },
  data: function() {
    return {
      email: "",
      password: "",
      loading: false
    };
  },
  computed: {
    emailError() {
      return this.email.length < 1 ? true : false;
    },
    passwordError() {
      return this.password.length < 1 ? true : false;
    }
  },
  methods: {
    login() {
      this.$store.commit("setFailedEvent", ""); //clean old error

      if (this.emailError) {
        this.$refs.email.focus();
        return;
      }
      if (this.passwordError) {
        this.$refs.password.focus();
        return;
      }

      //enable loader
      this.loading = true;

      //login user
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password
        })
        .then(result => {
          if (!result) {
            (this.email = ""), (this.password = "");
          } else if (result) {
            //sync basket
            this.$store.dispatch("updateBasketReq").then(res => {
              if (res) {
                this.$store.dispatch("getBasketReq");
              }
            });
            this.$router.push({ name: "ProfileInfo" });
          }

          //disable loader
          this.loading = false;
        });
    },
    register() {
      this.$store.commit("setFailedEvent", ""); //clean old error
      this.$store.commit("setUPAuthRoutineLevel", { status: "REGISTER" });
    },
    forgot() {
      this.$store.commit("setFailedEvent", ""); //clean old error
      this.$store.commit("setUPAuthRoutineLevel", { status: "FORGOT" });
    }
  }
};
</script>

<style scoped></style>
