<template>
  <div class="container">
    <button class="collapsible" ref="collapsible" @click="toggle">
      {{ title }}
    </button>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "status"],
  data: function() {
    return {};
  },
  mounted() {
    if (this.status === "active") {
      this.$refs.collapsible.click();
    }
  },
  methods: {
    toggle() {
      event.currentTarget.classList.toggle("active");
      var content = event.currentTarget.nextElementSibling;
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #f1f1f1;
  /* padding: 1rem; */
  border-radius: 10px;
  margin: 1rem auto;
  padding: 0;
}
.collapsible {
  background-color: transparent;
  color: #000;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: none;
  text-align: right;
  outline: none;
  font-size: 15px;
}
.collapsible.active {
  border-bottom: 1px solid #e7e7e7;
}
.collapsible:after {
  content: "\002B";
  color: #000;
  font-weight: bold;
  float: left;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.content {
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  /* background-color: #f1f1f1; */
}
</style>
