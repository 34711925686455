<template>
  <div class="form col-xl-4 col-md-6">
    <div class="card p-3" style="background: #eee;border-radius: 15px">
      <div class="card-title">
        <h5 class="mb-3 text-center">فراموشی رمزعبور</h5>
      </div>
      <div class="card-body">
        <Spinner v-if="loading"> </Spinner>

        <div v-else class="w-100">
          <!-- show error  -->
          <p v-if="$store.getters.getErrors" class="text-center" style="color: red">
            {{ $store.getters.getErrors }}
          </p>
          <!-- show error  -->

          <!-- login items  -->
          <div class="row mb-3">
            <div class="d-flex align-items-center">
              <label>ایمیل:</label>
              <span
                  v-if="emailError"
                  class="mr-3 mb-1"
                  style="font-size: 12px; color: red"
              >ایمیل الزامی است</span
              >
            </div>
            <input
                v-model="email"
                ref="email"
                class="input mt-0"
                type="email"
                placeholder="پست الکترونیک"
            />
          </div>
          <!-- login items -->

          <!-- buttons  -->
          <div class="group-btns d-flex">
            <input
                @click="forgot"
                class="submit-btn w-100 ml-3 mr-3 mb-3"
                type="button"
                value="دریافت کد تایید"
            />
          </div>
          <hr>
          <div class="group-btns d-flex">
            <input
                @click="login"
                class="info-btn w-100 ml-3 mr-3 mb-3"
                type="button"
                value="ورود"
            />
            <input
                @click="register"
                class="info-btn w-100 mb-3"
                type="button"
                value="حساب کاربری ندارم"
            />
          </div>
          <!-- buttons  -->
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import Spinner from "@/components/Spinner.component";

export default {
  name: "login",
  components: { Spinner },
  data: function() {
    return {
      email: "",
      loading: false
    };
  },
  computed: {
    emailError() {
      return this.email.length < 1 ? true : false;
    }
  },
  methods: {
    forgot() {
      this.$store.commit("setFailedEvent", ""); //clean old error

      if (this.emailError) {
        this.$refs.email.focus();
        return;
      }
      //enable loader
      this.loading = true;

      //send verify code
      this.$store
        .dispatch("forgot", {
          email: this.email
        })
        .then(result => {
          if (result) {
            this.$store.commit("setEmail", this.email);

            //disable loader
            this.loading = false;
          } else {
            this.email = "";

            //disable loader
            this.loading = false;
          }
        });
    },
    login() {
      this.$store.commit("setFailedEvent", ""); //clean old error
      this.$store.commit("setUPAuthRoutineLevel", { status: "LOGIN" });
    },
    register() {
      this.$store.commit("setFailedEvent", ""); //clean old error
      this.$store.commit("setUPAuthRoutineLevel", { status: "REGISTER" });
    }
  }
};
</script>

<style scoped></style>
