import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth.store";
import product from "./modules/product.store";
import basket from "./modules/shopping-card.store";
import profile from "./modules/profile.store";
import information from "./modules/information.store";
import settings from "./modules/settings.store";
import statics from "./modules/statics.store";
import sliders from "./modules/sliders.store";
import category from "./modules/category.store";
import filter from "./modules/filter.store";
import search from "./modules/search.store";
import productsList from "./modules/productsList.store";
import axios from "axios";
import {env} from "../../enums";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Limit_Delivery: []
  },
  actions:{
    getLimitDelivery({commit}) {
        return new Promise(resolve => {
            axios.get(
                env.base_url + "/setting_limit_delivery"
            ).then((res) =>{
                console.log(res.data);
                commit('getLimitDelivery',res.data)
                     return resolve(res.data);
            })

        } )
    }
},
mutations: {
    getLimitDelivery(state,limit){
        state.Limit_Delivery=limit;
    }
},
getters: {
    Limit_Delivery(state) {
        return state.Limit_Delivery
    }
},
  modules: {
    auth,
    product,
    basket,
    profile,
    information,
    settings,
    statics,
    sliders,
    category,
    filter,
    search,
    productsList,
  }
});
