<template>
  <section>
    <Breadcrumb currentPageName="سبد خرید شما"></Breadcrumb>
    <div class="container-fluid body">
      <div class="row">
        <div class="col-xl-12" style="text-align: center">
          <img src="/template/images/success.svg" alt="" />
          <p id="first-p">تبریک</p>
          <p>خرید شما با موفقیت انجام شد</p>
          <ul>
            <li id="first-input" style="padding-left: 23px">
              <router-link
                tag="input"
                class="btn-continue-shoping"
                type="button"
                value="خرید خود را ادامه دهید"
                to="/"
              ></router-link>
            </li>
            <li>
              <router-link
                tag="input"
                class="btn-records"
                type="button"
                value="مشاهده‌ی سفارش‌های من"
                to="/profile/orders"
              ></router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/breadcrumb.component";

export default {
  components: { Breadcrumb },
  data() {
    return {};
  }
};
</script>
<style scoped>
#email-btn {
  width: 100%;
}

.body {
  width: 100%;
  height: auto;
  background-color: #fff;
  padding-top: 45px;
  margin-bottom: 30px;
}

.body ul li {
  display: inline-block;
  list-style: none;
}

.body #first-p {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  margin-top: 27px;
  margin-bottom: 24px;
}

.body p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #000000;
}

.body ul {
  margin-top: 45px;
  margin-bottom: 25px;
}

.body input {
  width: 204px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.26px;
  border-style: none;
}

.btn-continue-shoping {
  color: #fff;
  background-color: #262f56;
}

.btn-records {
  color: #262f56;
  background-color: #fff;
  border: 1px solid #262f56 !important;
}

@media (max-width: 467px) {
  #first-input {
    padding-left: 0px !important;
  }
}
</style>
