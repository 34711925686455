<template>
  <section>
    <Breadcrumb
        :sectionNames="breadcrumbs"
        :currentPageName="product.name"
    ></Breadcrumb>
    <div class="wrapper">
      <Spinner v-if="loading"></Spinner>

      <div v-else class="custom-container w-100 pt-3 pb-3">
        <div class="card" style="background: #eee;border-radius: 25px">
          <div class="card-body">

            <div class="d-flex flex-wrap align-items-center justify-content-around">
              <div class="col-custom">
                <!-- gallery -->
                <div class="image-container d-flex justify-content-center" style="height:auto !important;">
                  <ul class="slides">
                    <li
                        v-for="(image, index) in product.images"
                        :key="'slide' + index"
                    >
                      <img
                          :src="
                      image && image.length !== 0
                        ? image
                       : '/template/images/no-image.png'
                    "
                          :alt="product.title"
                          @click="mainImage = image"
                          @error="image = '/template/images/no-image.png'"
                      />
                    </li>
                  </ul>


                  <zoom-on-hover
                      v-if=" mainImage && mainImage.length !== 0"
                      class="main-img"
                      :src="
                                   mainImage && mainImage.length !== 0
                                     ? mainImage
                                     : '/template/images/no-image.png'
                                 "
                      @error="mainImage = '/template/images/no-image.png'"
                      alt=""
                  ></zoom-on-hover>


                  <div class="text-right">
                    <img style=" box-shadow: 2px 2px 7px rgba(195, 195, 195, 0.79);"
                         class="image-container"
                         :src="
                 mainImage && mainImage.length !== 0
                   ? mainImage
                   : '/template/images/no-image.png'
               "
                         @error="mainImage = '/template/images/no-image.png'"
                         alt=""
                    />
                  </div>

                </div>
                <!-- favorite -->

                <FavoriteAction class="mt-3 text-center" :productId="product.id"></FavoriteAction>

                <!-- favorite -->
                <!--                 <div class="image-container d-flex">-->
                <!--                  <ul class="slides">-->
                <!--                    <li-->
                <!--                      v-for="(image, index) in product.images"-->
                <!--                      :key="'slide' + index"-->
                <!--                    >-->
                <!--                      <img-->
                <!--                        v-lazy="-->
                <!--                          image && image.length !== 0-->
                <!--                            ? image-->
                <!--                            : '/template/images/no-image.png'-->
                <!--                        "-->
                <!--                        alt="one"-->
                <!--                        @click="mainImage = image"-->
                <!--                        @error="image = '/template/images/no-image.png'"-->
                <!--                      />-->
                <!--                    </li>-->
                <!--                  </ul>-->
                <!--                  <img-->
                <!--                    class="main-img"-->
                <!--                    v-lazy="-->
                <!--                      mainImage && mainImage.length !== 0-->
                <!--                        ? mainImage-->
                <!--                        : '/template/images/no-image.png'-->
                <!--                    "-->
                <!--                    @error="mainImage = '/template/images/no-image.png'"-->
                <!--                    alt=""-->
                <!--                  />-->
                <!--                </div> -->
                <!-- gallery -->
              </div>
              <div class="col-custom">

                <!-- details -->
                <div class="details pt-3">
                  <h1 class="text-center mb-3" style="max-width: 500px;font-size: 22px">
                    {{ product.name }}
                  </h1>

                  <p v-if="product.get_brand">
                    ناشر:
                    <router-link
                        :to="{
                    name: 'search',
                    query: {
                      brand: JSON.stringify([
                        {
                          id: product.get_brand.id,
                          name: product.get_brand.name
                        }
                      ])
                    }
                  }"
                    >
                      {{ product.get_brand ? product.get_brand.name : "" }}
                    </router-link>
                  </p>

                  <p v-if="product.author">
                    نویسنده:
                    <router-link
                        :to="{
                    name: 'search',
                    query: {
                      author: JSON.stringify([
                        {
                          id: product.author_id,
                          name: product.author
                        }
                      ])
                    }
                  }"
                    >
                      {{ product.author ? product.author : "" }}
                    </router-link>
                  </p>

                  <p v-if="product.translator">
                    مترجم:
                    <router-link
                        :to="{
                    name: 'search',
                    query: {
                      translator: JSON.stringify([
                        {
                          id: product.translator_id,
                          name: product.translator
                        }
                      ])
                    }
                  }"
                    >
                      {{ product.translator ? product.translator : "" }}
                    </router-link>
                  </p>

                  <p
                      v-if="
                  Number(publish.quantity) > 0 && Number(publish.quantity) < x
                "
                  >
                    وضعیت موجودی:
                    <span style="color: darkred"
                    >تنها {{ publish.quantity }} عدد باقی مانده</span
                    >
                  </p>
                  <p v-else-if="Number(publish.quantity) <= 0">
                    وضعیت موجودی:
                    <span style="color: red">ناموجود</span>
                  </p>
                  <p v-else-if="Number(publish.quantity) >= x">
                    وضعیت موجودی:
                    <span style="color: green">موجود</span>
                  </p>
                  <br/>
                  <div class="row ">
                    <div class="col-md-6 mt-3">
                      <!-- publish -->

                      <label>نوبت چاپ</label>
                      <select style="border-radius: 15px"
                              class="form-control"
                              v-model="publish"
                              ref="publish"
                              @change="calculatePrice()"
                      >
                        <option
                            v-for="(item, index) in product.dynamicAttributes"
                            :key="'publish' + index"
                            :value="item"
                        >
                          {{ item.publish }}
                        </option>
                      </select>

                      <!-- publish -->
                    </div>
                    <div class="col-md-6 mt-3" v-if="Number(publish.quantity) > 0">
                      <!-- counter -->
                      <label v-if="Number(publish.quantity) !== 0">تعداد</label>
                      <counter
                          style="border-radius: 15px"
                          ref="counter"
                          :min="product.min_limit ? product.min_limit : 1"
                          :max="
                    product.max_limit &&
                    Number(product.max_limit) < Number(publish.quantity)
                      ? product.max_limit
                      : publish.quantity
                  "
                          :value="product.min_limit ? product.min_limit : 1"
                          @input="count = $event"
                      ></counter>
                      <!-- counter -->
                    </div>
                  </div>


                  <div class="mt-4 text-center">
                    <!-- special price -->
                    <div v-if="publish.special_price && publish.special_price !== 0">
                      <div class="d-flex">
                        <p class="old-price">
                          {{
                            new Intl.NumberFormat().format(publish.back_cover_price)
                          }}
                          ریال
                        </p>
                        <p class="discount-percent mr-2">
                          <!-- {{
                            publish.special_price > 100
                              ? new Intl.NumberFormat().format(publish.special_price)
                              : publish.special_price + "%"
                          }} -->
                          {{
                            new Intl.NumberFormat().format(
                                (
                                    ((Number(publish.back_cover_price) -
                                            Number(publish.special_price)) /
                                        Number(publish.back_cover_price)) *
                                    100
                                ).toFixed(0)
                            ) + "%"
                          }}
                        </p>
                      </div>
                      <p class="new-price">
                        <!-- {{
                          publish.special_price > 100
                            ? new Intl.NumberFormat().format(
                                Number(publish.back_cover_price) -
                                  Number(publish.special_price)
                              )
                            : new Intl.NumberFormat().format(
                                (
                                  Number(publish.back_cover_price) -
                                  (Number(publish.back_cover_price) *
                                    Number(publish.special_price)) /
                                    100
                                ).toFixed(0)
                              )
                        }} -->
                        {{ new Intl.NumberFormat().format(publish.special_price) }}
                        ریال
                      </p>
                    </div>
                    <!-- special price -->

                    <!-- price -->
                    <p v-else class="new-price">
                      {{ new Intl.NumberFormat().format(publish.back_cover_price) }}
                      ریال
                    </p>
                    <!-- price -->
                  </div>


                  <div class="d-flex justify-content-center" v-if="product.intro_sunde">
                    <audio style="border-radius: 25px;margin: 15px 0;width:100%;" preload="auto"
                           controls>مرورگر شما از Player ساپورت نمی کند.
                      <source :src="product.intro_sunde" type="audio/mpeg">
                    </audio>
                  </div>

                  <input
                      v-if="Number(publish.quantity) === 0"
                      @click="registerOrder()"
                      class="info-btn"
                      type="button"
                      value="سفارش کالا"
                  />
                  <input
                      v-else
                      @click="addToCard()"
                      class="submit-btn"
                      type="button"
                      value="افزودن به سبد خرید"
                  />
                  <!-- details -->
                </div>
                <!-- details -->
              </div>
            </div>
            <ProductContent
                :product="product"
                :publish="publish"
                :category="breadcrumbs[3] ? breadcrumbs[3] : ''"
            ></ProductContent>
            <br/>

            <div>
              <p v-if="this.$store.getters.getProfileInfo.id">
                <a @click="getRes" class="submit-btn my-2 text-light" data-toggle="collapse" href="#collapseExample"
                   role="button"
                   aria-expanded="false" aria-controls="collapseExample">
                  مرجوع کالا
                </a>
              </p>
              <p v-else>
                <a class="submit-btn my-2 text-light"   @click="
                    $router.push({ path: '/auth' }).catch(() => {});
                    showProfileQuickAccess = false;
                  ">
                  مرجوع کالا
                </a>
              </p>
              <div class="collapse" id="collapseExample">
                <div class="card card-body my-3" style="border-radius: 15px">
                  <div class="alert alert-info text-center">
                    برای مرجوع کالا فرم زیر را تکمیل نمایید
                  </div>

                  <form class="text-right">


                    <div class="form-row">
                      <div class="form-group col-md-3">
                        <label for="inputState">علت مرجوع</label>
                        {{formReject.reject}}
                        <select id="inputState" v-model="formReject.reject" class="form-control" required>
                          <option
                              v-for=" item in rejects"
                              :key="item.id"
                              :value="item.title"
                          >
                            {{ item.title }}
                          </option>
                          <!--                          <option v-for="item in reasons">{{item.title}}</option>-->
                        </select>
                        <span class="text-danger" v-if="formRejectValidate.reject">اطلاعات بخش مورد نظر را وارد نمایید</span>

                      </div>
                      <div class="form-group col-md-3 text-right">
                        <label for="inputEmail4" dir="ltr">تعداد کتاب برای مرجوعی </label>
                        <input type="number" v-model="formReject.book_count" class="form-control" required>
                        <span class="text-danger" v-if="formRejectValidate.book_count">تعداد صحیح را وارد نمایید</span>
                      </div>

                      <div class="form-group col-md-3 text-right">
                        <!-- publish -->

                        <label>نوبت چاپ</label>
                        <select style="border-radius: 15px"
                                class="form-control"
                                v-model="formReject.published"
                                ref="publish"
                                @change="calculatePrice()"
                        >
                          <option
                              v-for="(item, index) in product.dynamicAttributes"
                              :key="'publish' + index"
                              :value="item"
                          >
                            {{ item.publish }}
                          </option>
                        </select>
                        <span class="text-danger" v-if="formRejectValidate.published">اطلاعات بخش مورد نظر را وارد نمایید</span>
                        <!-- publish -->
                      </div>

                      <div class="form-group col-md-3 text-right">
                        <label for="inputEmail4" dir="ltr">عنوان کتاب </label>
                        <input type="text" v-model="product.name" class="form-control" disabled>
                      </div>
                    </div>

                    <button type="button" v-on:click="setReject" class="submit-btn my-2 text-light">ثبت</button>
                  </form>

                </div>
              </div>

            </div>
            <div v-if="showRelative">
              <RelativeProduct :productid="product.id"></RelativeProduct>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { env } from "../../../enums";
import ProductContent from "@/components/ProductContent.component";
import FavoriteAction from "@/components/FavoriteAction.component";
import Breadcrumb from "@/components/breadcrumb.component";
import counter from "@/components/counter.component";
import RelativeProduct from "@/components/slider/RelativeProduct.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "ProductPage",
  components: {
    Breadcrumb,
    ProductContent,
    FavoriteAction,
    counter,
    RelativeProduct,
    Spinner
  },
  data() {
    return {
      rejects:[],
      formReject: {
        call_number: '',
        user_name: '',
        address: '',
        reject: '',
        book: '',
        book_count: 1,
        published:'',
        post_code:''
      },
      formRejectValidate: {
        valid:'',
        call_number: '',
        user_name: '',
        address: '',
        reject: '',
        book: '',
        book_count: '',
        published:'',
        post_code:''
      },
      reasons:[],
      loading: true,
      breadcrumbs: [],
      productInfo: {},
      mainImage: "",
      selected: {},
      product: {},
      publish: {
        id: "",
        price: ""
      },
      count: 0,
      price: 0,
      x: "",
      showRelative: false
    };
  },
  beforeCreate() {
    this.$store.dispatch("getProfileX").then(() => {
      this.infoLoading = false;
    });
  },
  watch: {

    count(newVal) {
      if (this.$refs.counter) {
        this.$refs.counter.count = newVal;
      }
    }
  },
  created() {

   axios.get(env.base_url +'/reasons').then((response) => {
     this.rejects=response.data
      console.log(response.data)
    })
    //get product
    this.$store
        .dispatch("getProduct", {slug: this.$route.params.slug})
        .then(result => {
          this.product = result.items;
          this.mainImage = this.product.intro_image;
          if (
              this.product.dynamicAttributes &&
              this.product.dynamicAttributes.length > 0
          ) {
            this.publish = this.product.dynamicAttributes[0];
            this.count = this.product.min_limit ? this.product.min_limit : 1;
          }
          this.x = result.show_qty;
          this.breadcrumbs = result.breadcrumb;
          this.showRelative = true;
          //this.product.qty = 0;//for test
          // this.calculatePrice();
          this.loading = false;
        });
  },
  computed: {},
  methods: {
 setReject(){
if (this.formReject.user_name.length<3) {this.formRejectValidate.user_name=true ;this.formRejectValidate.valid=false}else {this.formRejectValidate.user_name=false; this.formRejectValidate.valid=true}
if (this.formReject.call_number.length<3) {this.formRejectValidate.call_number=true; this.formRejectValidate.valid=false}else {this.formRejectValidate.call_number=false; this.formRejectValidate.valid=true}
if (this.formReject.post_code.length<3) {this.formRejectValidate.post_code=true;this.formRejectValidate.valid=false}else {this.formRejectValidate.post_code=false; this.formRejectValidate.valid=true}
if (this.formReject.address.length<3) {this.formRejectValidate.address=true ;this.formRejectValidate.valid=false}else {this.formRejectValidate.address=false; this.formRejectValidate.valid=true}
if (this.formReject.reject.length<3) {this.formRejectValidate.reject=true; this.formRejectValidate.valid=false}else {this.formRejectValidate.reject=false; this.formRejectValidate.valid=true}
if (this.formReject.book_count<1) {this.formRejectValidate.book_count=true;this.formRejectValidate.valid=false}else {this.formRejectValidate.book_count=false; this.formRejectValidate.valid=true}
if (this.formReject.published.length<1) {this.formRejectValidate.published=true ;this.formRejectValidate.valid=false}else {this.formRejectValidate.published=false; this.formRejectValidate.valid=true}
if (this.formRejectValidate.valid){
  axios
      .post(env.base_url +"/reject", {
        user_id:this.$store.getters.getProfileInfo.id,
        reject: this.formReject.reject,
        book_name: this.product.name,
        book_count:this.formReject.book_count,
        published:this.formReject.published.publish	,
        system_code:this.product.system_code,
      },{
        headers:{
          "Access-Control-Allow-Origin":"*",
          "Access-Control-Expose-Headers":"*",
          'content-type': 'text/json'
        }
      })
      .then(response => {
        console.log(response.data)
        this.$swal.fire({
          position: "center",
          icon: "success",
          title: "درخواست مرجوع با موفقیت ثبت گردید ",
          showConfirmButton: false,
          timer: 3000
        });
        this.formReject.user_name=''
        this.formReject.call_number=''
        this.formReject.post_code=''
        this.formReject.address=''
        this.formReject.reject=''
        this.formReject.book_count=''
        this.formReject.published=''
        this.formRejectValidate.valid=''
      })
      .catch(function (error) {
        console.error(error);
      });
}


},
    calculatePrice() {
      this.updateImages();
      this.count = this.product.min_limit ? this.product.min_limit : 1;
      //reset count
      return (
          Number(this.count) *
          Number(
              this.publish.special_price && this.publish.special_price !== 0
                  ? this.publish.special_price
                  : this.publish.back_cover_price
          )
      );
    },
    updateImages() {
      if (this.publish) {
        this.$store
            .dispatch("getProductImages", {
              main_code: this.publish.main_code
            })
            .then(res => {
              console.log(res);
              this.mainImage = res[0];
              this.product.images = res;
            });
      }
    },
    addToCard() {
      if (!this.publish || !this.publish.id || this.count <= 0) {
        this.$refs.publish.focus();
        return;
      }

      //save in localstorage and update basket
      let selected = {
        image: this.product.intro_image,
        productId: this.product.id,
        name: this.product.name,
        min_limit: this.product.min_limit,
        max_limit: this.product.max_limit,
        quantity: this.count,
        publish: this.publish, //pass all publish
        options: this.publish.id,
        // publish: this.publish,
        publish_quantity: this.publish.quantity,
        price: this.publish.back_cover_price,
        special_price: this.publish.special_price
      };

      this.$store.dispatch("addToBasket", {
        product: selected
      });
      // .then(product => {
      //if user is login save in db
      if (localStorage.getItem("token")) {
        //enable loader
        // this.basketLoading = true;

        this.$store
            .dispatch("addToBasketReq", {product: selected})
            .then(res => {
              if (!res) {
                this.$swal
                    .fire({
                      position: "center",
                      icon: "warning",
                      title: "خطا در اضافه کردن محصول",
                      showConfirmButton: false,
                      timer: 1500
                    })
                    .then(() => {
                      //failed then remove from basket
                      this.$store.dispatch("removeFromBasket", {
                        product: selected
                      });
                    });
              }
              //disable loader
              // this.basketLoading = false;
            });
      }
      // });
    },
    registerOrder() {
      let selected = {
        productId: this.product.id,
        attrId: this.publish.id,
        quantity: this.count
      };

      //enable loader
      this.loading = true;

      this.$store
          .dispatch("registerOrder", {
            options: selected
          })
          .then(result => {
            if (result === 401) {
              this.$swal
                  .fire({
                    position: "center",
                    icon: "warning",
                    title: "لطفا وارد حساب کاربری خود شوید !",
                    showConfirmButton: false,
                    timer: 1500
                  })
                  .then(() => {
                    this.$router.push({name: "auth"});
                    return;
                  });
            } else if (result === 200) {
              this.$swal.fire({
                position: "center",
                icon: "success",
                title: "سفارش شما با موفقیت ثبت شد",
                showConfirmButton: false,
                timer: 1500
              });
            } else {
              this.$swal.fire({
                position: "center",
                icon: "error",
                title: "ثبت درخواست با مشکل مواجه شد",
                showConfirmButton: false,
                timer: 1500
              });
            }

            //disable loader
            this.loading = false;
          });
    },
    async getRes() {
      await axios.get('https://server.cheshmehdis.com/api/v1/reasons')
          .then(function (res) {
            console.info(res)


          })
          .catch(function (error) {
            // handle error
            console.info(error);
          })
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.loading = true;

    //get product
    this.$store
        .dispatch("getProduct", {slug: to.params.slug})
        .then(result => {
          this.product = result.items;
          this.mainImage = this.product.intro_image;
          if (
              this.product.dynamicAttributes &&
              this.product.dynamicAttributes.length > 0
          ) {
            this.publish = this.product.dynamicAttributes[0];
            this.count = this.product.min_limit ? this.product.min_limit : 1;
          }
          this.x = result.show_qty;
          this.breadcrumbs = result.breadcrumb;
          this.showRelative = true;
          //this.product.qty = 0;//for test
          // this.calculatePrice();
          this.loading = false;
        });
    next();
  }
};
</script>
<style scoped>
audio::-internal-media-controls-download-button {
  display:none;
}
audio::-webkit-media-controls-enclosure {
  overflow:hidden;
}
audio::-webkit-media-controls-panel {
  width: calc(100% + 33px);
}

.main-img .normal {
  height: 500px !important;
}

.details {
  margin-top: 0.5rem;
  display: flex;
  flex-flow: column;
  text-align: right;
  line-height: 1;
  height: 100%;
  min-width: 400px;
  justify-content: space-between;
}


.image-container {
  /* max-height: 400px; */
  flex-flow: column-reverse;
  width: 400px;
  border-radius: 15px;
}

.image-container .slides {
  display: flex;
  margin-top: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: center;
}

.image-container ul {
  direction: ltr;
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0 !important;
}

.image-container ul li {
  padding-right: 5px;
}

.image-container ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.image-container ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.image-container ul::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.image-container .main-img {
  max-width: 400px;
  margin: auto 1rem;
  /* max-height: 400px; */
}

.image-container ul li img {
  width: 70px;
  border-radius: 15px;
  box-shadow: 2px 2px 7px rgba(195, 195, 195, 0.79);
  margin: 0 auto 0.5rem auto;
  cursor: pointer;
}

/* price css */
.old-price {
  font-size: 24px !important;
}

.new-price {
  font-size: 24px !important;
}

/* price css */

@media screen and (max-width: 550px) {
  .image-container-div {
    width: 100%;
  }

  .image-container {
    flex-flow: column-reverse;

    border-radius: 15px;
    width: 100%;
  }

  .image-container .slides {
    display: flex;
    margin-top: 1rem;
    overflow-x: auto;
    overflow-y: hidden;
    justify-content: center;
  }

  .image-container ul li {
    display: flex;
  }

  .col-custom {
    width: 100%;
  }

  .details {
    min-width: 100%;
  }

  .image-container .main-img {
    /* width: 90% !important; */
    width: 100%;
    margin: 0 auto;
  }
}
</style>
