<template>
  <div v-if="products.length === 0" class="no-result">نتیجه‌ای یافت نشد!</div>
  <div v-else>
    <ul v-if="type === 'grid'" class="grid-items">
      <li v-for="(product, index) in products" :key="product[index]">
        <ProductItem :product="product"></ProductItem>
      </li>
    </ul>
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem.component";
export default {
  props: ["products", "type"],
  components: {
    ProductItem
  }
};
</script>

<style scoped>
.no-result {
  color: #6c757d;
  text-align: center;
  margin: 2rem auto;
}
.grid-items {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem auto;
}

.grid-items li {
  margin: auto 0.5rem;
}
</style>
