<template>
  <div class="selected-product">
    <h4 class="text-right ml-5 mr-5  mb-3">پیشنهادها</h4>
    <VueSlickCarousel v-bind="settings">
      <ProductItem
        v-for="(product, index) in products"
        :key="product[index]"
        :product="product"
      ></ProductItem>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductItem from "@/components/ProductItem.component";

export default {
  name: "selectedProducts",
  props: ["products"],
  data: function() {
    return {
      loading: true,
      settings: {
        infinite: true,
        slidesToShow: 5,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  created() {
    /*
    this.$store.dispatch("getFavoriteSlide").then((result) => {
      this.products = result;
      this.loading = false;
    });
    */
  },
  components: {
    VueSlickCarousel,
    ProductItem
  }
};
</script>

<style scoped>
@media only screen and (max-width: 500px) {
  .selected-product .slick-slider {
    margin: 0 2rem !important;
  }
}
</style>
