<template>
  <header id="section-header">
    <div
      v-if="$store.getters.showBasketStatus === true"
      class="shoping-cart"
    ></div>
    <SideBasket></SideBasket>

    <div class="header-navbar">
      <router-link to="/publishers">ناشرهای همکار</router-link>
      <!-- <span>|</span>
      <router-link to="/faq">سؤال‌های متداول</router-link> -->
      <span>|</span>
      <router-link to="/contact-us">تماس با ما</router-link>
      <span>|</span>
      <router-link to="/about">درباره‌ی ما</router-link>
    </div>

    <div class="custom-navbar mt-3">
      <div class="logo">
        <router-link to="/">
          <img src="../../../public/img/logo.png" alt="logo" width="70px" />
        </router-link>
      </div>
      <div class="d-flex w-100">
        <i class="d-lg-none fa fa-bars" @click="openSidebar"></i>
        <search
          placeholder="جست‌وجو"
          :more-res="true"
          style="width: 100%; margin: 0 2rem"
        ></search>
      </div>
      <div class="icons pl-0">
        <ul class="d-flex align-items-center justify-content-center mb-0">
          <li class="li-style">
            <!-- profile -->
            <div title="حساب کاربری" class="profile pb-2">
              <a @click="showQuickAccessProfile()" href="#">
                <img src="/template/images/User.svg" alt="profile" />
              </a>

              <div class="sign-in">
                <input
                  v-if="$store.getters.getToken !== null"
                  class="submit-btn"
                  @click="
                    $router.push('/profile');
                    showProfileQuickAccess = false;
                  "
                  type="button"
                  value="مشاهده‌ی حساب کاربری"
                />
                <input
                  v-if="$store.getters.getToken !== null"
                  @click="logoutProfile()"
                  type="button"
                  class="info-btn mt-3"
                  value="خروج از حساب کاربری"
                />
                <input
                  v-else
                  @click="
                    $router.push({ path: '/auth' }).catch(() => {});
                    showProfileQuickAccess = false;
                  "
                  type="button"
                  class="submit-btn"
                  value="ورود یا ثبت‌نام"
                />
              </div>
            </div>
            <!-- profile -->
          </li>
          <li class="li-style">
            <div title="علاقه‌مندی‌ها" class="favorites pb-2">
              <router-link tag="a" to="/profile/favorite">
                <img
                  src="/template/images/heart.svg"
                  alt="favorites"
                  width="25px"
                />
              </router-link>
            </div>
          </li>
          <li class="li-style">
            <div title="سبد خرید" class="shoping pb-2">
              <a @click="basketChangeStatus()" id="shoping" href="#">
                <img src="/template/images/Bag.svg" alt="shoping" />
              </a>
              <div class="circle">
                <p class="text-center">{{ productCounter }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <MegaMenu :items="menuItems"></MegaMenu>

    <Sidebar ref="sidebar" :items="menuItems"></Sidebar>
  </header>
</template>

<script>
import SideBasket from "@/components/SideBasket.component.vue";
import Sidebar from "@/components/Sidebar.component.vue";
import Search from "@/components/Search.component.vue";
import MegaMenu from "@/components/MegaMenu.component.vue";

export default {
  name: "Header",
  data: function() {
    return {
      menuItems: [],
      showProfileQuickAccess: false
    };
  },
  components: {
    SideBasket,
    Sidebar,
    Search,
    MegaMenu
  },
  computed: {
    productCounter() {
      return this.$store.getters.getBasket.length;
    }
  },
  created() {
    this.$store.dispatch("getMenu").then(result => {
      this.menuItems = result;
    });
  },
  methods: {
    basketChangeStatus: function() {
      this.$store.dispatch("setBasketStatus", {
        status: !this.$store.getters.showBasketStatus
      });
    },
    showQuickAccessProfile: function() {
      this.showProfileQuickAccess = !this.showProfileQuickAccess;
    },
    logoutProfile() {
      this.$swal
        .fire({
          position: "center",
          icon: "warning",
          title: "آیا از خروج خود اطمینان دارید؟",
          showCancelButton: true,
          confirmButtonText: `تایید`,
          cancelButtonText: `انصراف`
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch("logout").then(() => {
              window.location.href = "/auth";
            });
          }
        });
    },
    openSidebar() {
      this.$refs.sidebar.open();
    }
  },
  mounted() {}
};
</script>

<style>

</style>
