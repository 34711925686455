<template>
  <section class="product-section">
    <div class="custom-container">
      <div class="carousel-header mt-4 mb-4">
        <h4>{{ title }}</h4>
        <router-link :to="'/category/' + slug" class="show-all">
          مشاهده‌ی همه
          <i class="fa fa-angle-left mr-1 ml-1"></i>
        </router-link>
      </div>
      <VueSlickCarousel v-bind="settings">
        <ProductItem
          v-for="(product, index) in products"
          :key="product[index]"
          :product="product"
        ></ProductItem>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductItem from "@/components/ProductItem.component";

export default {
  props: ["title", "slug", "products"],
  components: {
    VueSlickCarousel,
    ProductItem
  },
  data() {
    return {
      settings: {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  }
};
</script>

<style scoped>
.product-section {
  padding: 2rem 0;
}
.product-section .slid {
  padding: 2rem 0;
}

.carousel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 800px) {
  h4 {
    font-size: 1rem !important;
  }
}
</style>
