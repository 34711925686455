<template>
  <div class="favorite-items mt-5 mb-5">
    <h4 class="text-right" style="margin: 0 2rem">محبوب‌ترین‌ها</h4>
    <br />
    <VueSlickCarousel v-bind="settings">
      <ProductItem
        v-for="(product, index) in products"
        :key="product[index]"
        :product="product"
      ></ProductItem>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductItem from "@/components/ProductItem.component";

export default {
  name: "favorite",
  props: ["products"],
  components: {
    VueSlickCarousel,
    ProductItem
  },
  data: function() {
    return {
      loading: true,
      settings: {
        dots: false,
        infinite: false,
        slidesToShow:5,
        speed: 500,
        slidesPerRow: 1,
        responsive: [
          // {
          //   breakpoint: 1366,
          //   settings: {
          //     slidesToShow: 5,
          //     slidesToScroll: 5,
          //     infinite: true,
          //     dots: true,
          //   },
          // },
          // {
          //   breakpoint: 1024,
          //   settings: {
          //     slidesToShow: 4,
          //     slidesToScroll: 4,
          //     infinite: true,
          //     dots: true,
          //   },
          // },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  computed: {},
  mounted() {},
  created() {
    /*
    this.$store.dispatch("getFavoriteSlide").then((result) => {
      this.products = result;
      this.loading = false;
    });
    */
  }
};
</script>
<style scoped>
.favorite-items .main-container-link {
  flex-flow: row !important;
}
@media only screen and (max-width: 500px) {
  .favorite-items .slick-slider {
    margin: 0 2rem !important;
  }
}
</style>
