import axios from "axios";
import { env } from "../../../enums";

export default {
  state: {
    auth: {
      routineLevel: "LOGIN",
      token: null
    },
    email: "",
    error: "",
    success: ""
  },
  getters: {
    authRoutineLevel(state) {
      return state.auth.routineLevel;
    },
    logged() {
      return localStorage.getItem("token") !== null;
    },
    getToken(state) {
      return state.auth.token || localStorage.getItem("token");
    },
    getErrors(state) {
      return state.error;
    },
    getSuccess(state) {
      return state.success;
    },
    getEmail(state) {
      return state.email;
    }
  },
  mutations: {
    setUPAuthRoutineLevel(state, data) {
      state.auth.routineLevel = data.status || "";
      if (data.token) {
        state.auth.token = data.token;
        localStorage.setItem("token", data.token);
      }
    },
    logoutAction(state) {
      localStorage.clear();
      state.auth.token = null;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setFailedEvent(state, message) {
      state.error = message;
    },
    setSuccessEvent(state, message) {
      state.success = message;
    }
  },
  actions: {
    logout(context) {
      context.commit("logoutAction");
      return new Promise(function(resolve) {
        resolve();
      });
    },
    register(context, { phone, fullname, email, password, store_name }) {
      console.log("2");

      return new Promise(function(resolve) {
        axios
          .post(env.base_url + "/auth/register", {
            phone,
            fullname,
            email,
            password,
            store_name
          })
          .then(result => {
            if (result.data.token) {
              context.commit("setUPAuthRoutineLevel", {
                status: "WELCOME",
                token: result.data.token
              });
              resolve(true);
            } else if (result.data.message) {
              context.commit("setFailedEvent", result.data.message);
              resolve(false);
            } else {
              context.commit(
                "setFailedEvent",
                "مشکلی در ثبت‌نام شما رخ داده است"
              );
              resolve(false);
            }
          })
          .catch(error => {
            if (!error.response.data.success && error.response.data.message) {
              context.commit("setFailedEvent", error.response.data.message);
              resolve(false);
            } else {
              context.commit(
                "setFailedEvent",
                "مشکلی در ثبت‌نام شما رخ داده است"
              );
              resolve(false);
            }
          });
      });
    },
    login(context, { email, password }) {
      return new Promise(function(resolve) {
        axios
          .post(env.base_url + "/auth/login", {
            email,
            password
          })
          .then(result => {
            if (result.data.token) {
              context.commit("setUPAuthRoutineLevel", {
                token: result.data.token
              });
              context.commit("setSuccessEvent", true);
              resolve(true);
            } else if (result.data.message) {
              context.commit("setFailedEvent", result.data.message);
              resolve(false);
            } else {
              context.commit("setFailedEvent", "مشکلی در ورود شما رخ داده است");
              resolve(false);
            }
          })
          .catch(error => {
            if (!error.response.data.success && error.response.data.message) {
              context.commit("setFailedEvent", error.response.data.message);
              resolve(false);
            } else {
              context.commit("setFailedEvent", "مشکلی در ورود شما رخ داده است");
              resolve(false);
            }
          });
      });
    },
    forgot(context, { email }) {
      return new Promise(function(resolve) {
        axios
          .patch(env.base_url + "/auth/password/forget", {
            email
          })
          .then(result => {
            if (result.data.success) {
              context.commit("setEmail", email);
              context.commit("setUPAuthRoutineLevel", {
                status: "VERIFY"
              });
              resolve(true);
            } else {
              context.commit(
                "setFailedEvent",
                "مشکلی در ارسال کد یکبار مصرف رخ داده است"
              );
              resolve(false);
            }
          })
          .catch(error => {
            if (!error.response.data.success && error.response.data.message) {
              context.commit("setFailedEvent", error.response.data.message);
            } else {
              context.commit(
                "setFailedEvent",
                "مشکلی در ارسال کد یکبار مصرف رخ داده است"
              );
            }
            resolve(false);
          });
      });
    },
    checkVerifyCode(context, { email, code }) {
      return new Promise(resolve => {
        axios
          .post(env.base_url + "/auth/verify", {
            email,
            code
          })
          .then(result => {
            if (result.status === 200) {
              context.commit("setUPAuthRoutineLevel", {
                token: result.data.token
              });
              context.commit("setSuccessEvent", true);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            context.commit("setFailedEvent", "کد وارد شده اشتباه است!");
            resolve(false);
          });
      });
    }
  }
};
