<template>
  <section>
    <breadcrumb current-page-name="ورود یا ثبت‌نام"></breadcrumb>
    <div class="wrapper">
      <REGISTER
        v-if="$store.getters.authRoutineLevel === 'REGISTER'"
      ></REGISTER>
      <LOGIN v-if="$store.getters.authRoutineLevel === 'LOGIN'"></LOGIN>
      <FORGOT v-if="$store.getters.authRoutineLevel === 'FORGOT'"></FORGOT>
      <VERIFY v-if="$store.getters.authRoutineLevel === 'VERIFY'"></VERIFY>
      <WELCOME v-if="$store.getters.authRoutineLevel === 'WELCOME'"></WELCOME>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "../../../components/breadcrumb.component";
import REGISTER from "@/components/auth-up/register.component";
import LOGIN from "@/components/auth-up/login.component";
import FORGOT from "@/components/auth-up/forgot.component";
import VERIFY from "@/components/auth-up/verify.component";
import WELCOME from "@/components/auth-up/welcome.component";

export default {
  data: function() {
    return {
      activeSection: "LOGIN"
    };
  },
  components: {
    Breadcrumb,
    REGISTER,
    LOGIN,
    FORGOT,
    VERIFY,
    WELCOME
  },
  methods: {}
};
</script>
