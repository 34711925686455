<template>
  <div ref="sideNav" class="sidenav">
    <a href="javascript:void(0)" class="closebtn" @click="close()">&times;</a>
    <ul class="treeMenu">
      <li v-for="(item, i) in items" :key="i">
        <a class="   px-3 text-center" v-if="item.children.length === 0" :href="item.url">
          {{ item.title }}
        </a>
        <div v-else>
          <span
              class="navparent px-3"
              @click="openMenuItem($event, 'navchild' + item.id + i)"
          >
            <a class="  " :href="item.url">{{ item.title }}</a>
          </span>

          <div class="navchild" :id="'navchild' + item.id + i">
            <ul v-for="(parent, j) in item.children" :key="j">
              <li v-if="parent.children.length === 0">
                <a class="  " :href="parent.url">
                  {{ parent.title }}
                </a>
              </li>

              <li v-else>
                <a v-if="parent.children.length===0" class="  " :href="parent.url">
                  {{ parent.title }}
                </a>
               <div v-else class="">
                 <span class="caret px-2">
                  <a  class="  " :href="parent.url">
                    {{ parent.title }}
                  </a>
                </span>
                 <ul class="nested">
                   <li v-for="(child, k) in parent.children" :key="k">
                     <a class="  " :href="child.url">
                       {{ child.title }}
                     </a>
                   </li>
                 </ul>
               </div>

              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["items"],
  data: function() {
    return {};
  },
  methods: {
    close() {
      this.$refs.sideNav.style.width = "0px";
    },
    open() {
      this.$refs.sideNav.style.width = "300px";
      this.$emit("open");
    },
    openMenuItem(evt, itemId) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("navchild");
      for (i = 0; i < tabcontent.length; i++) {
        if (tabcontent[i].id === itemId) {
          tabcontent[i].classList.toggle("active");
        } else {
          tabcontent[i].className = tabcontent[i].className.replace(
            " active",
            ""
          );
        }
      }
      tablinks = document.getElementsByClassName("navparent");
      for (i = 0; i < tablinks.length; i++) {
        if (tablinks[i] === evt.currentTarget) {
          tablinks[i].classList.toggle("active");
        } else {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
      }
      // if(document.getElementById(itemId).classList.contains("active")){
      //   console.lo
      //   document.getElementById(itemId).classList.remove("active");
      // }else{
      //   document.getElementById(itemId).classList.add("active");
      // }
    }
  },
  updated() {
    var toggler = document.getElementsByClassName("caret");
    var i;

    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function() {
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
      });
    }
  }
};
</script>

<style scoped>
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  background-color: #eee;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  box-shadow: 2px 2px 7px  #eee;
}

.sidenav .closebtn {
  padding: 2px 2px 2px 32px;
  text-decoration: none;
  color: #818181;
  display: block;
  transition: 0.3s;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 16px;
    color: #fff;
  }
}

/******** tree menu *******/
ul.treeMenu {
  list-style-type: none;
  text-align: right;
  direction: ltr;
  line-height: 3;
}

.treeMenu ul {
  list-style-type: none;
  text-align: right;
  direction: ltr;
  line-height: 2;
}
.treeMenu {
  margin: 0;
  padding: 2rem;
}

.treeMenu .caret,
.navparent {
  cursor: pointer;
  border-radius: 50px;
  font-size: 16px;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.treeMenu .caret::before,
.navparent::before {
  font-family: "FontAwesome";

  content: "\f104";
  color: #fff;
  display: inline-block;
  padding-right: 6px;
  font-size: 16px;
}

.treeMenu .caret-down::before,
.navparent.active::before {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari */
  transform: rotate(90deg);
}

.treeMenu li a {
  text-decoration: none !important;
  color: #fff;
}
.treeMenu li a:hover {
  color: #f75454 !important;
}

.treeMenu .nested {
  display: none;
}

.treeMenu .active {
  display: block;
  margin-right: 1rem;
}
.treeMenu .navparent.active {
  color: #f75454;
}
.treeMenu .navchild {
  margin-right: 1rem;
  display: none;
}
.treeMenu .navchild.active {
  display: block !important;
}
</style>
