import axios from "axios";
import { env } from "../../../enums";
export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDetails(context, { slug }) {
      return new Promise(function(resolve, reject) {
        axios.get(env.base_url + `/information/${slug}`).then(result => {
          if (result.status === 200) {
            resolve(result.data.item);
          }
          reject("");
        });
      });
    }
  }
};
