<template>
  <div>
    <h4 class="text-right ml-5 mr-5 mb-3">پُرفروش‌ها</h4>
    <div class="bestsell row align-items-center">
      <div class="col-md-3 col-sm-12" align="center">
        <a class="bestsell_banner_body" :href="banner.url">
          <div class="bestsell_banner_image">
            <img
              class=""
              v-lazy="banner.image"
              alt="پُرفروش‌ها"
              @error="
                '/template/images/no-image.png';


              "
            />
          </div>
        </a>
      </div>
      <div class="col-md-9 col-sm-12" align="center">
        <VueSlickCarousel v-bind="settings">
          <ProductItem
            v-for="(product, index) in products"
            :key="product[index]"
            :product="product"
          ></ProductItem>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductItem from "@/components/ProductItem.component";
export default {
  name: "bestsell",
  props: ["products", "banner"],
  components: {
    VueSlickCarousel,
    ProductItem
  },
  data: function() {
    return {
      loading: true,
      firstItem: [],
      settings: {
        dots: false,
        infinite: false,
        slidesToShow: 5,
        speed: 500,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true
            }
          },
          {
            breakpoint: 1250,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true
            }
          },
          {
            breakpoint: 1080,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 750,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  created() {
    /*
    this.$store.dispatch("getbestsellProducts").then((result) => {
      this.products = result;
      this.firstItem = result[0];
      this.loading = false;
    });
    */
  }
};
</script>

<style scoped>
.bestsell h4 {
  /* color: #fff; */
}
.bestsell {
  padding: 1rem 0.5rem;
  margin: 0 1rem;
  border-radius: 10px;
  background-color: #fff6f6;
}
.bestsell .main-container {
  background-color: #fff !important;
}
.bestsell_banner_image img {
  width: auto !important;
  height: 300px !important;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 800px) {
  .product-main-container .product-name {
    width: 120px;
  }
  .bestsell_banner_image img {
    margin-bottom: 1rem;
  }
}
</style>
