<template>
  <section>
    <footer id="section-footer" class="text-center">
      <div class="custom-container d-flex flex-column align-items-center">
        <div class="w-100 d-flex justify-content-around flex-wrap">
          <!-- address -->
          <div class="right flex-1">
            <router-link class="d-flex justify-content-center" to="/">
              <img
                src="../../../public/img/logo.png"
                class="logo"
                width="80px"
              />
            </router-link>
            <p class="mt-2">
              <i class="fa fa-map-marker"></i>
              نشانی: تهران، خیابان دماوند (غرب به شرق)، بعد از سه‌راه تهرانپارس،
              خیابان اتحاد، خیابان ۱۱ غربی، پلاک ۸
            </p>
          </div>
          <!-- address -->

          <!-- quick links -->
          <ul class="flex-1">
            <li>
              <b>لینک‌های سریع </b>
            </li>
            <li>
              <router-link to="/">خانه</router-link>
            </li>
            <li>
              <router-link to="/search">جست‌وجوی پیشرفته</router-link>
            </li>
            <li>
              <router-link to="/publishers">ناشرهای همکار</router-link>
            </li>
          </ul>
          <!-- quick links -->

          <!-- about us -->
          <ul class="flex-1">
            <li>
              <b> ارتباط با ما </b>
            </li>
            <li>
              <router-link to="/about">درباره‌ی ما</router-link>
            </li>
            <li>
              <router-link to="/contact-us">تماس با ما</router-link>
            </li>
            <li>
              <router-link to="/faq">سؤال‌های متداول</router-link>
            </li>
            <li>
              <router-link to="/terms-and-condition"
                >قوانین و مقررات</router-link
              >
            </li>
          </ul>
          <!-- about us -->

          <!-- newsletter -->
          <div class="newsletter d-flex flex-column mt-5 flex-1">
            <h5>عضویت در خبرنامه</h5>
            <p>با اشتراک در خبرنامه از آخرین اخبار و تخفیف‌ها با خبر شوید.</p>
            <div class="form-group d-flex justify-content-center">
              <input
                class="input"
                type="email"
                placeholder="ایمیل خود را وارد نمایید"
                name="user-email"
                v-model="email"
              />
              <button class="submit-btn" @click="subscribe()">ثبت</button>
            </div>

            <label :style="messageColor" v-if="newsletterMessage != ''">
              {{ newsletterMessage }}
            </label>
          </div>
          <!-- newsletter -->
        </div>

        <div class="bottom-info">
          <p>
            <i class="fa fa-address-card ml-1" aria-hidden="true"></i>
            کدپستی: ۱۶۵۸۷۵۷۶۱۱
          </p>
          <p>
            <i class="fa fa-phone" aria-hidden="true"></i>
            تماس:
            <a href="tel:02177144808">۷۷۱۴۴۸۰۸</a>
            -
            <a href="tel:02177144821">۷۷۱۴۴۸۲۱</a>
            -
            <a href="tel:02177788502">۷۷۷۸۸۵۰۲</a>
            -
            <a href="tel:02176794257">۷۶۷۹۴۲۵۷</a>
          </p>
          <p>
            <i class="fa fa-envelope"></i>
            ایمیل:
            <a href="mailto:Cheshmehdis@gmail.com">Cheshmehdis@gmail.com</a>
          </p>
        </div>
      </div>

      <!-- copyright -->
      <div class="d-flex bottom" style="font-size: 14px;background: rgba(139,140,157,0.65);border:none;">
        <!-- <a href="http://rabinet.net/" class="m-auto"
          >© کلیه حقوق برای مجموعه رابینت محفوظ می باشد.</a
        > -->
        <p class="m-auto">
          طراحی و توسعه‌ی سایت توسط
          <a
            href="https://rabinet.net/"
            class="copy_right"
            style="color: #000 !important; font-weight: bold"
          >
            رابینت
          </a>
        </p>
      </div>
      <!-- copyright -->
    </footer>
  </section>
</template>

<script>
export default {
  // name: "footer",
  data() {
    return {
      information: [],
      email: "",
      newsletterMessage: "",
      messageColor: "color:red"
    };
  },
  created() {
    // this.$store
    //   .dispatch("getInformationList", { slug: this.$route.params.slug })
    //   .then((result) => {
    //     if (result) {
    //       this.information = result;
    //     }
    //   });
  },
  methods: {
    subscribe() {
      if (this.email) {
        this.$store
          .dispatch("subscribe_newsletter", { email: this.email })
          .then(result => {
            if (result) {
              this.newsletterMessage = "با موفقیت ثبت گردید !";
              this.messageColor = "color:green";
              setTimeout(() => {
                this.newsletterMessage = "";
              }, 3000);
            } else {
              this.newsletterMessage = "شما قبلا ثبت‌نام کرده‌اید !";
              this.messageColor = "color:green";
              setTimeout(() => {
                this.newsletterMessage = "";
              }, 3000);
            }
          });
      } else {
        this.newsletterMessage = "ایمیل نمی تواند خالی باشد !";
        setTimeout(() => {
          this.newsletterMessage = "";
        }, 3000);
      }
    }
  }
};
</script>

<style >
footer{
  padding-top: 25px;
  background: #eee;
  box-shadow: -2px -2px 7px rgba(223, 214, 214, 0.63)
}
.bottom-info {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  padding: 1rem 0;
}
.bottom-info p {
  margin: 0 !important;
  border-right: 2px solid #e7e7e7;
  border-left: 2px solid #e7e7e7;
  padding: 0.5rem 1rem;
  flex: 1;
}
@media only screen and (max-width: 700px) {
  footer ul {
    margin-top: 1rem !important;
  }
  .bottom-info {
    flex-flow: column;
  }
  .bottom-info p {
    border: none;
  }
}
</style>
