<template>
  <section>
    <Breadcrumb
      current-page-name="لیست درخواست‌ها"
      section-name="داشبورد کاربر"
      section-addr="/profile"
    ></Breadcrumb>
    <div class="custom-container pb-3 pt-3">
      <div class="row">
        <SideMenu></SideMenu>

        <div class="col-lg-8 customer-panel-box">
          <Spinner v-if="loading"></Spinner>
          <div v-else class="custom-table-container mt-3">
            <table class="custom-table">
              <thead>
                <tr>
                  <th>تصویر</th>
                  <th>عنوان</th>
                  <th>ناشر</th>
                  <th>نوبت چاپ</th>
                  <!-- <th>تعداد سفارش</th> -->
                  <th>قیمت</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in products" :key="index">
                  <td>
                    <img
                      width="50px"
                      class="product-img"
                      style="cursor: pointer"
                      @click="
                        $router.push('/product/' + product.get_product.slug)
                      "
                      v-lazy="product.image"
                      alt="img-product"
                    />
                  </td>
                  <td
                    style="cursor: pointer"
                    @click="
                      $router.push('/product/' + product.get_product.slug)
                    "
                  >
                    {{ product.get_product.name }}
                  </td>
                  <td>
                    {{
                      product.get_product.get_brand
                        ? product.get_product.get_brand.name
                        : ""
                    }}
                  </td>
                  <td>
                    {{ product.get_attr ? product.get_attr.publish : "" }}
                  </td>
                  <!-- <td>{{ product.quantity  }}</td> -->
                  <td>
                    <!-- special price -->
                    <div
                      v-if="
                        product.get_attr &&
                          product.get_attr.special_price &&
                          product.get_attr.special_price !== 0
                      "
                    >
                      <div class="d-flex">
                        <p class="old-price">
                          {{
                            new Intl.NumberFormat().format(
                              product.get_attr.back_cover_price
                            )
                          }}
                          ریال
                        </p>
                        <p class="discount-percent mr-2">
                          {{
                            new Intl.NumberFormat().format(
                              (
                                ((Number(product.get_attr.back_cover_price) -
                                  Number(product.get_attr.special_price)) /
                                  Number(product.get_attr.back_cover_price)) *
                                100
                              ).toFixed(0)
                            ) + "%"
                          }}
                        </p>
                      </div>
                      <p class="new-price">
                        {{
                          new Intl.NumberFormat().format(
                            product.get_attr.special_price
                          )
                        }}
                        ریال
                      </p>
                    </div>
                    <!-- special price -->

                    <!-- price -->
                    <p v-else-if="product.get_attr" class="new-price">
                      {{
                        new Intl.NumberFormat().format(
                          product.get_attr.back_cover_price
                        )
                      }}
                      ریال
                    </p>
                    <!-- price -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- pagination -->
          <div v-if="products.length !== 0" class="pagination-container">
            <ul class="pagination mr-2">
              <!-- first page -->
              <li
                class="page-item"
                v-bind:class="{ disabled: current_page === 1 }"
              >
                <a class="page-link" aria-label="Next" @click="goToPage(1)">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <!-- first page -->

              <!-- prev -->
              <li
                class="page-item"
                v-bind:class="{ disabled: current_page === 1 }"
              >
                <a class="page-link" aria-label="Previous" @click="goToPrev()">
                  <span aria-hidden="true">&lsaquo;</span>
                </a>
              </li>
              <!-- prev -->

              <!-- pages -->
              <li class="page-item" v-if="current_page - 1 > 0">
                <a class="page-link" @click="goToPage(current_page - 1)">{{
                  current_page - 1
                }}</a>
              </li>

              <li class="page-item active">
                <a class="page-link" @click="goToPage(current_page)">{{
                  current_page
                }}</a>
              </li>

              <li class="page-item" v-if="current_page + 1 < pages">
                <a class="page-link" @click="goToPage(current_page + 1)">{{
                  current_page + 1
                }}</a>
              </li>
              <!-- pages -->

              <!-- next -->
              <li
                class="page-item"
                v-bind:class="{ disabled: current_page === pages }"
              >
                <a class="page-link" aria-label="Next" @click="goToNext()">
                  <span aria-hidden="true">&rsaquo;</span>
                </a>
              </li>
              <!-- next -->

              <!-- last page -->
              <li
                class="page-item"
                v-bind:class="{ disabled: current_page === pages }"
              >
                <a class="page-link" aria-label="Next" @click="goToPage(pages)">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
              <!-- last page -->
            </ul>
            <label class="mt-1">تعداد کل صفحات: {{ pages }}</label>
          </div>
          <!-- pagination -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/breadcrumb.component";
import SideMenu from "@/components/DashboardSideMenu.compoment";
import Spinner from "@/components/Spinner.component";

export default {
  components: { Breadcrumb, SideMenu, Spinner },
  data() {
    return {
      products: [],
      pages: 1,
      current_page: 1
    };
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.$store
        .dispatch("getProductRequests", {
          page: this.current_page
        })
        .then(result => {
          this.products = result.items.data;
        });
    },
    goToPrev() {
      if (this.current_page > 1) {
        this.current_page--;
        this.getProducts();
      }
    },
    goToNext() {
      if (this.current_page < this.pages) {
        this.current_page++;
        this.getProducts();
      }
    },
    goToPage(page) {
      this.current_page = page;
      this.getProducts();
    }
  }
};
</script>
<style scoped>
.favorite-table {
  width: 100%;
}

.favorite-table thead {
  background-color: #f5f5f5;
}

.favorite-table thead th {
  padding: 5px;
  text-align: center;
}

.favorite-table td {
  padding: 5px;
  text-align: center;
}

.remove-btn {
  background-color: red;
  border-radius: 50%;
  padding: 5px;
}
@media screen and (max-width: 600px) {
  .favorite-table-container {
    width: 600px;
    overflow-x: auto !important;
  }
}
</style>
