<template>
  <section>
    <Breadcrumb currentPageName="سبد خرید شما"></Breadcrumb>
    <Spinner v-if="loading"> </Spinner>
    <div v-else>
      <!-- basket flow -->
      <div class="row">
        <ul class="basket-flow">
          <li>
            <img src="/template/images/shoping-icon-top.svg" alt="shoping" />
            <p>سبد خرید</p>
          </li>
          <li class="line active"></li>
          <li class="line"></li>
          <li>
            <img src="/template/images/address-icon.svg" alt="address" />
            <p>اطلاعات ارسال</p>
          </li>
          <li class="line"></li>
          <li class="line"></li>
          <li>
            <img src="/template/images/cart-shoping.svg" alt="cart" />
            <p>ثبت نهایی</p>
          </li>
        </ul>
      </div>
      <!-- basket flow -->

      <!-- Address details -->
      <div class="custom-container mb-3">
        <div class="custom-table-container">
          <Spinner v-if="loading"></Spinner>

          <table v-else class="custom-table">
            <thead>
              <tr class="h">
                <th>نام و نام خانوادگی</th>
                <th>کد ملی</th>
                <th>آدرس</th>
                <th>کد پستی</th>
                <th>شماره همراه</th>
                <th>حذف</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in addresses" :key="item[index]">
                <td>
                  {{ item.fullname }}
                </td>
                <td>
                  {{ item.melicode }}
                </td>
                <td>{{ item.address }}</td>
                <td>{{ item.postalcode }}</td>
                <td>{{ item.phone }}</td>
                <td>
                  <i
                    class="delete-icon m-auto fa fa-remove"
                    @click="deleteAddress(item.id)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center">
          <router-link
            v-if="addresses.length < 1"
            tag="input"
            class="submit-btn mt-3 ml-1"
            type="button"
            value="افزودن آدرس جدید"
            :to="{
              name: 'AddAddress',
              query: {
                isCart: true
              }
            }"
          >
          </router-link>

          <button
            tag="input"
            class="submit-btn mt-3 mr-1"
            type="button"
            @click="gotoCheckout"
          >
            تکمیل خرید
          </button>
        </div>
      </div>
      <!-- Address details -->
    </div>
  </section>
</template>
<script>
import Breadcrumb from "@/components/breadcrumb.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "Shopping-Cart",
  components: { Breadcrumb, Spinner },
  data() {
    return {
      loading: true,
      addresses: []
    };
  },
  mounted() {
    this.getAddress();
  },
  methods: {
    gotoCheckout() {
      if (this.addresses.length > 0) {
        //enable loader
        this.loading = true;

        let products = this.$store.getters.getBasket;
        this.$store
          .dispatch("reqRegisterOrder", {
            products: products
          })
          .then(res => {
            if (res.status === 200) {
              this.$router.push(res.path);
            } else if (res.status === 401) {
              this.$swal
                .fire({
                  position: "center",
                  icon: "warning",
                  title: "لطفا وارد حساب کاربری خود شوید !",
                  showConfirmButton: false,
                  timer: 1500
                })
                .then(() => {
                  this.$router.push({ name: "auth" });
                  return;
                });
            } else if (
              res.status === 400 &&
              res.data.status == "account_not_accept"
            ) {
              this.$swal.fire({
                position: "center",
                icon: "warning",
                title: "حساب کاربری شما تایید نشده است",
                showConfirmButton: false,
                timer: 1500
              });
              //disable loader
              this.loading = false;
            } else if (
              res.status === 400 &&
              res.data.status == "address_not_exist"
            ) {
              this.$router.push({ name: "cartAddress" });
            } else if (res.status === 400 && res.data.status == "out_stock") {
              this.$store.dispatch("getBasketReq").then(() => {
                this.products = this.$store.getters.getBasket;
                this.$router.push({ name: "Cart" });
              });
            }
          });
      } else {
        this.$router.push({ name: "AddAddress", query: { isCart: true } });
      }
    },
    getAddress() {
      this.$store.dispatch("getAddresses").then(addresses => {
        this.addresses = addresses;
        this.loading = false;
      });
    },
    deleteAddress(id) {
      this.loading = true;

      this.$store.dispatch("deleteAddress", { id }).then(result => {
        if (result.success) {
          this.$store.dispatch("getAddresses").then(addresses => {
            this.addresses = addresses;
          });
          this.$swal
            .fire({
              position: "center",
              icon: "success",
              title: "حذف آدرس انجام شد !",
              showConfirmButton: false,
              timer: 1500
            })
            .then(() => {
              this.loading = false;
              this.getAddress();
            });
        } else {
          this.$swal
            .fire({
              position: "center",
              icon: "error",
              title: "مشکلی در حذف آدرس رخ داده است !",
              showConfirmButton: false,
              timer: 1500
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
<style scoped>
/* basket flow */
.basket-flow {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 2rem 0;
}
.basket-flow li:not(.line) {
  text-align: center;
  margin: 0 5px;
}
.basket-flow li img {
  width: 50px;
  height: 50px;
}
.basket-flow li.line {
  width: 50px;
  height: 5px;
  background-color: gray;
}
.basket-flow li.line.active {
  background-color: #ff4476;
}

@media screen and (max-width: 600px) {
  .basket-flow li {
    font-size: 11px;
  }
  .basket-flow li img {
    width: 20px;
    height: 20px;
  }
  .basket-flow li.line {
    width: 20px;
    height: 3px;
  }
}
/* basket flow */
</style>
