var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading && _vm.products && _vm.products.length !== 0)?_c('Spinner',{attrs:{"circle":true}}):(_vm.products && _vm.products.length !== 0)?_c('div',{staticClass:"container mt-5 mb-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8 newrelease"},[_c('h4',{staticClass:"text-right",staticStyle:{"margin":"0 2rem"}},[_vm._v("تازه‌ها")]),_c('br'),_c('div',{staticClass:"d-flex flex-wrap justify-content-between"},[_c('ul',{staticClass:"newrelease-tablist"},[(_vm.result.first_edition_books.length !== 0)?_c('li',{class:{ active: _vm.products.type == 'first_edition_books' },on:{"click":_vm.firstEditionBooks}},[_vm._v(" چاپ اول ")]):_vm._e(),(_vm.result.not_first_edition_books.length !== 0)?_c('li',{class:{
              active: _vm.products.type == 'not_first_edition_books'
            },on:{"click":_vm.notFirstEditionBooks}},[_vm._v(" تجدید چاپ ")]):_vm._e(),(_vm.result.journals.length !== 0)?_c('li',{class:{ active: _vm.products.type == 'journals' },on:{"click":_vm.journals}},[_vm._v(" نشریات ")]):_vm._e(),(_vm.result.not_books.length !== 0)?_c('li',{class:{ active: _vm.products.type == 'not_books' },on:{"click":_vm.notBooks}},[_vm._v(" غیرکتاب ")]):_vm._e()]),_c('router-link',{staticClass:"show-more d-flex ml-4 mr-4",staticStyle:{"cursor":"pointer"},attrs:{"tag":"p","to":{
            name: 'search',
            query: {
              sort: 'created'
            }
          }}},[_vm._v(" نمایش بیشتر "),_c('i',{staticClass:"fa fa-angle-left mr-2",staticStyle:{"font-size":"22px"}})])],1),_c('div',{staticClass:"newrelease-container"},[(_vm.loadData)?_c('Spinner',{attrs:{"leanier":true}}):_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.products.items),function(product,index){return _c('ProductItem',{key:product[index],attrs:{"product":product}})}),1)],1)]),_c('div',{staticClass:"col-sm-4 latest-banner",staticStyle:{"margin-bottom":"8px"}},[_c('a',{staticClass:"banner__body",attrs:{"href":_vm.result.banner.url}},[_c('div',{staticClass:"banner__image"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.result.banner.image),expression:"result.banner.image"}],attrs:{"alt":"تازه‌ها"},on:{"error":function($event){'/template/images/no-image.png';}}})])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }