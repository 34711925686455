<template>
  <section class="orders">
    <Breadcrumb
        current-page-name="مرجوعی"
        section-name="داشبورد کاربر"
        section-addr="/profile"
    ></Breadcrumb>

    <div class="custom-container pb-3 pt-3">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>
        <div class="col-lg-8 customer-panel-box">
          <div class="d-flex flex-column">
            <ul class="tablist">
              <li
                  @click="
                  reject = true;
                  pending = false;
                  lost = false;
                  accepted = false;
                "
                  class=""
              >
                مرجوع (
                <span class="num-in-process">
                  {{ rejects.count }} </span
                >)
              </li>
              <li
                  @click="
                  reject = false;
                  pending = true;
                  lost = false;
                  accepted = false;
                "
                  class=""
              >
                در حال برسی (<span class="">{{
                  returned_pending.count
                }}</span
              >)
              </li>
              <li
                  @click="
                   reject = false;
                  pending = false;
                  lost = false;
                  accepted = true;
                "
                  class=""
              >
                تأیید شده (<span class="num-done">{{
                  returned_accepted.count
                }}</span
              >)
              </li>
              <li
                  @click="
                  reject = false;
                  pending = false;
                  lost = true;
                  accepted = false;
                "
                  class=""
              >
                لغو شده (<span class="">{{ returned_lost.count }}</span
              >)
              </li>
            </ul>

            <div v-if="reject">
              <Spinner v-if="loading"></Spinner>
              <div v-else class="mt-3">

                <div class="card" v-if="rejects.count>0">
                  <div class="card-body">
                    <table

                        class="custom-table my-3">
                      <tbody>
                      <tr class="">

                        <td class="">
                          <p>عنوان کتاب</p>
                        </td>
                        <td class="">
                          <p>تعداد</p>
                        </td>

                        <td class="">
                          <p>نوبت چاپ</p>
                        </td>
                        <td class="">
                          <p>کد سیستمی</p>
                        </td>
                        <td class="">
                          <p>علت مرجوع</p>
                        </td>
                        <td class="">
                          <p>وضعیت</p>
                        </td>
                        <td class="">

                        </td>
                      </tr>
                      <tr v-for="item in rejects.items" v-bind:key="item.id">
                        <td>
                          {{ item.book_name }}
                        </td>
                        <td>
                          {{ item.book_count }}
                        </td>
                        <td>
                          {{ item.published }}
                        </td>
                        <td>
                          {{ item.system_code }}
                        </td>
                        <td>
                          {{ item.reject }}
                        </td>
                        <td>
                          <p v-if="item.state=='pending'">در حال برسی</p>
                          <p v-if="item.state=='accepted'">تایید شد</p>
                          <p v-if="item.state=='lost'">رد شد</p>

                        </td>
                        <td>
                          <button type="button" @click="delReject(item.id)" class="submit-btn text-light" href="">حذف
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card-footer">

                    <button type="button" @click="setReject" class="submit-btn text-light" href="">ثبت لیست مرجوع
                    </button>
                  </div>

                </div>


                <p v-else
                   class="d-flex align-items-center justify-content-center mt-5"
                >
                  <i class="fa fa-ban ml-3" style="font-size: 20px"></i>
                  سفارشی مرجوع وجود ندارد
                </p>
              </div>
            </div>

            <div v-if="pending" class="custom-table-container">
              <Spinner v-if="loading"></Spinner>
              <div v-else class="mt-3">
                <div class="card" v-if="returned_pending.count>0">
                  <div class="card-body">
                    <div class="" v-for="item in returned_pending.items" v-bind:key="item.id">
                      <div class="row">
                        <p class="m-3">درخواست مرجوع به شماره : {{ item.id }}</p>
                      </div>
                      <p>

                        <a class="" data-toggle="collapse" :href="'#collapseExample'+item.id" role="button"
                           aria-expanded="false"
                           :aria-controls="'collapseExample'+item.id">
                          مشاهده جزئیات
                        </a>
                      </p>
                      <div class="collapse" :id="'collapseExample'+item.id">
                        <div class="card card-body">
                          <table

                              class="custom-table my-3">
                            <tbody>
                            <tr class="">

                              <td class="">
                                <p>عنوان کتاب</p>
                              </td>
                              <td class="">
                                <p>تعداد</p>
                              </td>

                              <td class="">
                                <p>نوبت چاپ</p>
                              </td>
                              <td class="">
                                <p>کد سیستمی</p>
                              </td>
                              <td class="">
                                <p>علت مرجوع</p>
                              </td>
                              <td class="">
                                <p>وضعیت</p>
                              </td>
                            </tr>
                            <tr v-for="item in item.rejects" v-bind:key="item.id">
                              <td>
                                {{ item.book_name }}
                              </td>
                              <td>
                                {{ item.book_count }}
                              </td>
                              <td>
                                {{ item.published }}
                              </td>
                              <td>
                                {{ item.system_code }}
                              </td>
                              <td>
                                {{ item.reject }}
                              </td>
                              <td>
                                <p v-if="item.state=='pending'">در حال برسی</p>
                                <p v-if="item.state=='accepted'">تایید شد</p>
                                <p v-if="item.state=='lost'">رد شد</p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p v-else
                   class="d-flex align-items-center justify-content-center mt-5"
                >
                  <i class="fa fa-ban ml-3" style="font-size: 20px"></i>
                  سفارشی مرجوع وجود ندارد
                </p>
              </div>
            </div>
            <div v-if="accepted" class="custom-table-container">

              <Spinner v-if="loading"></Spinner>
              <div v-else class="mt-3">
                <div class="card" v-if="returned_accepted.count>0">
                  <div class="card-body">
                    <div class="" v-for="item in returned_accepted.items" v-bind:key="item.id">
                      <div class="row">
                        <p class="m-3">درخواست مرجوع به شماره : {{ item.id }}</p>
                      </div>
                      <p>

                        <a class="" data-toggle="collapse" :href="'#collapseExample'+item.id" role="button"
                           aria-expanded="false"
                           :aria-controls="'collapseExample'+item.id">
                          مشاهده جزئیات
                        </a>
                      </p>
                      <div class="collapse" :id="'collapseExample'+item.id">
                        <div class="card card-body">
                          <table

                              class="custom-table my-3">
                            <tbody>
                            <tr class="">

                              <td class="">
                                <p>عنوان کتاب</p>
                              </td>
                              <td class="">
                                <p>تعداد</p>
                              </td>

                              <td class="">
                                <p>نوبت چاپ</p>
                              </td>
                              <td class="">
                                <p>کد سیستمی</p>
                              </td>
                              <td class="">
                                <p>علت مرجوع</p>
                              </td>
                              <td class="">
                                <p>وضعیت</p>
                              </td>
                            </tr>
                            <tr v-for="item in item.rejects" v-bind:key="item.id">
                              <td>
                                {{ item.book_name }}
                              </td>
                              <td>
                                {{ item.book_count }}
                              </td>
                              <td>
                                {{ item.published }}
                              </td>
                              <td>
                                {{ item.system_code }}
                              </td>
                              <td>
                                {{ item.reject }}
                              </td>
                              <td>
                                <p v-if="item.state=='pending'">در حال برسی</p>
                                <p v-if="item.state=='accepted'">تایید شد</p>
                                <p v-if="item.state=='lost'">رد شد</p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p v-else
                   class="d-flex align-items-center justify-content-center mt-5"
                >
                  <i class="fa fa-ban ml-3" style="font-size: 20px"></i>
                  سفارشی مرجوع وجود ندارد
                </p>
              </div>
            </div>
            <div v-if="lost">

              <Spinner v-if="loading"></Spinner>
              <div v-else class="mt-3">
                <div class="card" v-if="returned_lost.count>0">
                  <div class="card-body">
                    <div class="" v-for="item in returned_lost.items" v-bind:key="item.id">
                      <div class="row">
                        <p class="m-3">درخواست مرجوع به شماره : {{ item.id }}</p>
                      </div>
                      <p>

                        <a class="" data-toggle="collapse" :href="'#collapseExample'+item.id" role="button"
                           aria-expanded="false"
                           :aria-controls="'collapseExample'+item.id">
                          مشاهده جزئیات
                        </a>
                      </p>
                      <div class="collapse" :id="'collapseExample'+item.id">
                        <div class="card card-body">
                          <table

                              class="custom-table my-3">
                            <tbody>
                            <tr class="">

                              <td class="">
                                <p>عنوان کتاب</p>
                              </td>
                              <td class="">
                                <p>تعداد</p>
                              </td>

                              <td class="">
                                <p>نوبت چاپ</p>
                              </td>
                              <td class="">
                                <p>کد سیستمی</p>
                              </td>
                              <td class="">
                                <p>علت مرجوع</p>
                              </td>
                              <td class="">
                                <p>وضعیت</p>
                              </td>
                            </tr>
                            <tr v-for="item in item.rejects" v-bind:key="item.id">
                              <td>
                                {{ item.book_name }}
                              </td>
                              <td>
                                {{ item.book_count }}
                              </td>
                              <td>
                                {{ item.published }}
                              </td>
                              <td>
                                {{ item.system_code }}
                              </td>
                              <td>
                                {{ item.reject }}
                              </td>
                              <td>
                                <p v-if="item.state=='pending'">در حال برسی</p>
                                <p v-if="item.state=='accepted'">تایید شد</p>
                                <p v-if="item.state=='lost'">رد شد</p>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <p v-else
                   class="d-flex align-items-center justify-content-center mt-5"
                >
                  <i class="fa fa-ban ml-3" style="font-size: 20px"></i>
                  سفارشی مرجوع وجود ندارد
                </p>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.component";
import SideMenu from "@/components/DashboardSideMenu.compoment";
import Spinner from "@/components/Spinner.component";
import axios from 'axios'

export default {
  data() {
    return {
      loading: true,
      pending: false,
      reject: true,
      lost: false,
      accepted: false,
      rejects: '',
      returned_pending: '',
      returned_accepted: '',
      returned_lost: '',
    };
  },
  beforeCreate() {
    this.$store.dispatch("getProfileX").then(() => {
      this.infoLoading = false;
    });

  },
  components: {SideMenu, Breadcrumb, Spinner},
  created() {
    this.getreject();
    this.getReturneds();
  },
  methods: {
    getReturneds() {
      this.loading = true;
      axios.get('https://server.cheshmehdis.com/api/v1/get_returned', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }).then((res) => {
        this.loading - false
        this.returned_pending = res.data.returned_pending
        this.returned_accepted = res.data.returned_accepted
        this.returned_lost = res.data.returned_lost
        console.info(res.data)
      })
    },
    getreject() {
      this.loading = true;
      axios.get('https://server.cheshmehdis.com/api/v1/rejects', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }).then((res) => {
        this.rejects = res.data
        this.loading = false
        console.info(this.rejects)
      })
    },
    setReject() {
      axios.get('https://server.cheshmehdis.com/api/v1/set_returned', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }).then(() => {
        this.$swal.fire({
          position: "center",
          icon: "success",
          title: "لیست مرجوع  با موفقیت ثبت گردید ",
          showConfirmButton: false,
          timer: 3000
        });
        this.getreject();
        this.getReturneds();
      })


    },
    delReject(id) {
      this.loading = true;
      axios.get('https://server.cheshmehdis.com/api/v1/del/reject/'+id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }).then(() => {
        this.$swal.fire({
          position: "center",
          icon: "warning",
          title: "مرجوع کالا با موفقیت حذف گردید",
          showConfirmButton: false,
          timer: 3000
        });
        this.getreject();
        this.getReturneds()
      })

    }
  }
};
</script>
<style scoped>
.tablist {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  margin-bottom: 0;
  margin-top: 1rem;
}

.tablist li {
  width: 170px;
  background-color: #424242;
  color: #fff;
  border-left: 1px solid #fff;
  padding: 0.5rem 1rem;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
}

.tablist li:first-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tablist li:last-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.orders .custom-table-container {
  border-top: 1px solid #e7e7e7;
}
</style>
