<template>
  <section class="about">
    <Breadcrumb :currentPageName="title"></Breadcrumb>
    <div class="wrapper">
      <Spinner v-if="loading"> </Spinner>
      <div v-else class="col-xl-8 mt-5 mb-5">
        <h5 class="text-center">
          {{ title }}
        </h5>
        <div class="mt-5">
          <p v-html="body"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "../../components/breadcrumb.component";
import Spinner from "@/components/Spinner.component";

export default {
  components: { Breadcrumb, Spinner },
  data() {
    return {
      title: "",
      loading: false,
      body: ""
    };
  },
  created() {
    this.loading = true;

    //get details from backend
    this.$store.dispatch("getDetails", { slug: "publishers" }).then(result => {
      if (result) {
        this.title = result.title;
        this.body = result.body;
      }
      this.loading = false;
    });
  }
};
</script>
<style scoped>
.about p {
  line-height: 2.5;
  text-align: justify;
}
.about p > br {
  margin-bottom: 1rem;
}
</style>
