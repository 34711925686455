// import Auth from "../../views/pages/Auth-OPT/Auth.vue"; // otp
import Auth from "../../views/pages/Auth-UP/Auth.vue"; // user password

export default [
  {
    path: "/auth",
    name: "auth",
    component: Auth,
    meta: { isAuthRoute: true }
  }
];
