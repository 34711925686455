<template>
  <section class="terms">
    <Breadcrumb :currentPageName="title"></Breadcrumb>
    <div class="wrapper">
      <Spinner v-if="loading"> </Spinner>

      <div v-else class="col-xl-8 mt-5 mb-5">
        <h5 class="text-center">
          {{ title }}
        </h5>
        <div class="mt-5" v-html="body">
          <p v-html="body"></p>
          <!-- <ul class="details">
                                  <li> کلیه کتابهای ارائه شده در سایت نشر چشمه با ضمانت اصالت ارائه می شود.
                                  </li>
                                  <li> بازگشت کالا منوط به احراز شرایط مرجوعی می باشد که شامل نقص چاپ، عدم تطابق با سفارش، نقص
                                      ظاهری،
                                      چاپ قدیمی می باشد.
                                  </li>

                                  <li> ارسال کلیه سفارشات بنا به درخواست مشتریان به طرق مختلف انجام میشود لذا در انتخاب روش ارسال
                                      دقت
                                      فرمایید.
                                  </li>

                                  <li> مسئولیت ارسال به صورت پیک موتوری و حضوری به عهده نشر چشمه می باشد.
                                  </li>
                                  <li> مسئولیت کلیه مرسوله های پستی پس از تحویل به پست بر عهده آن اداره می باشد زیرا کلیه بسته های
                                      پستی
                                      بیمه هستند و پس از آن نشر چشمه می تواند کد رهگیری پست را در اختیار مشتریان قرار دهد.
                                  </li>
                                  <li> نشر چشمه قیمت کتاب ها را برحسب نرخ اعلام شده توسط ناشر (قیمت پشت جلد) بروزرسانی می کند و در
                                      صورت
                                      کاهش یا افزایش قیمت مراتب متعاقبا به مشتری اعلام می گردد.
                                  </li>

                                  <li> ((اطلاعات مشتریان ( آدرس، شماره تلفن و...) صرفا در راستای ارائه خدمات هرچه بهتر نشر چشمه می
                                      باشد
                                      و به هیچ عنوان در اختیار شخص سوم قرار نخواهد گرفت.
                                  </li>

                                  <li> کد پستی بایستی دقیق و کامل درج گردد و در صورت اشتباه بودن مسئولیت آن بر عهده مشتری است و
                                      ارسال
                                      دوباره شامل پرداخت مجدد هزینه ارسال می باشد.
                                  </li>
                              </ul> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "../../components/breadcrumb.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "terms",
  components: { Breadcrumb, Spinner },
  data() {
    return {
      title: "",
      body: "",
      loading: false
    };
  },
  created() {
    this.loading = true;

    //get details from backend
    this.$store
      .dispatch("getDetails", { slug: "terms-and-condition" })
      .then(result => {
        if (result) {
          this.title = result.title;
          this.body = result.body;
        }
        this.loading = false;
      });
  }
};
</script>
