<template>
  <section class="faq">
    <Breadcrumb :currentPageName="title"></Breadcrumb>
    <div class="wrapper">
      <Spinner v-if="loading"></Spinner>

      <div v-else class="col-xl-8 mt-5 mb-5">
        <h5 class="text-center">
          {{ title }}
        </h5>
        <div class="mt-5" v-html="body">
          <!-- <ul class="details">
            <li>
            برای انتشار کتاب در نشرچشمه چه مراحلی را باید طی کرد؟
            <p>
            کسانی که تمایل به همکاری با نشرچشمه دارند، باید با دفترنشر تماس
            بگیرند و با مسئول بخش پذیرش کتاب هماهنگ کنند. برای تحویل کتاب به
            صورت حضوری باید وقت بگیرند و در تاریخ مشخص شده به دفتر نشرچشمه
            مراجعه کنند و فرم مربوطه را پر کنند. در غیر این صورت می‌توانند
            غیرحضوری پرینت کتاب‌ را برای بررسی بفرستند.
            </p>
            </li>

            <li>
            زمان بررسی آثار چقدر است؟
            <p>بررسی آثار حداکثر دوماه زمان می‌گیرد</p>
            </li>
            <li>
            آيا نشرچشمه روی کتاب‌های ارسال‌شده برای بررسی، نظر کارشناسی‌ اعلام
            می‌کند یا خیر؟
            <p>
            نشرچشمه کتاب‌ها را تنها برای چاپ کردن یا نکردن بررسی می‌کند و
            دفتر نشر نظر کارشناس‌ها را فقط به صورت «رد» یا «تایید» به اطلاع
            صاحبان اثر می‌رساند و بررس‌ها ملزم به ارائه‌ی دلایل رد کردن کتاب
            نیستند.
            </p>
            </li>
            <li>
            مخاطبان چطور می‌توانند با نویسندگان و مترجمان نشرچشمه ارتباط
            برقرار کنند؟
            <p>
            برای ارتباط با مترجمان یا نویسندگان می‌توانید به صفحه‌ی آن شخص
            مراجعه کنید، اگر آدرس ایمیل‌شان را در صفحه‌ی بیوگرافی‌شان درج
            نکرده بودند، می‌توانید با دفتر نشرچشمه تماس بگیرید تا پس از
            هماهنگی با نویسنده یا مترجم ارتباط شما را با شخص مورد نظرتان
            برقرار کنند.
            </p>
            </li>
            </ul> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "../../components/breadcrumb.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "information",
  components: { Breadcrumb, Spinner },
  data() {
    return {
      title: "",
      body: "",
      loading: false
    };
  },
  created() {
    this.loading = true;

    //get details from backend
    this.$store.dispatch("getDetails", { slug: "faq" }).then(result => {
      if (result) {
        this.title = result.title;
        this.body = result.body;
      }
      this.loading = false;
    });
  }
};
</script>
