<template>
  <section>
    <Breadcrumb currentPageName="ثبت نهایی"></Breadcrumb>
    <Spinner v-if="loading"> </Spinner>
    <div v-else>
      <!-- basket flow -->
      <div class="row">

            <ul class="basket-flow">
              <li>
                <img src="/template/images/shoping-icon-top.svg" alt="shoping" />
                <p>سبد خرید</p>
              </li>
              <li class="line active"></li>
              <li class="line active"></li>
              <li>
                <img src="/template/images/Pin2.svg" alt="address" />
                <p>اطلاعات ارسال</p>
              </li>
              <li class="line active"></li>
              <li class="line active"></li>
              <li>
                <img src="/template/images/cart2.svg" alt="cart" />
                <p>ثبت نهایی</p>
              </li>
            </ul>
      </div>
      <!-- basket flow -->

      <div class="custom-container">
        <div class="card mb-3" style="border:7px solid #eee;border-radius: 25px;">
          <div class="card-body">
        <div class="row text-right">

              <div class="col-xl-6">
                <!-- addressess -->
                <div class="section-header">
                  <img src="/template/images/Subtract-pin.svg" alt="" />
                  آدرس
                </div>
                <div>
                  <ul
                      v-for="(item, index) in addresses"
                      :key="item[index]"
                      class="d-flex flex-wrap spaceing-ul"
                  >
                    <!--<li style="padding-left: 15px">-->
                    <!--<input-->
                    <!--v-model="addressId"-->
                    <!--name="address"-->
                    <!--:value="item.id"-->
                    <!--type="radio"-->
                    <!--class="circle-b circle-one"-->
                    <!--/>-->
                    <!--</li>-->
                    <li>
                      {{ item.fullname }}
                    </li>
                    <li>
                      <p>{{ item.address }}</p>
                    </li>
                    <li>
                      {{ item.phone }}
                    </li>
                  </ul>
                  <label v-if="errors.address" style="color: red"
                  >انتخاب آدرس ارسال الزامی میباشد !</label
                  >
                </div>
                <!-- addressess -->

                <!-- shippping -->
                <div class="section-header">
                  <img src="/template/images/Subtract-pin.svg" alt="" />
                  روش ارسال
                </div>
                <div>
                  <ul
                      v-for="(item, index) in shippings"
                      :key="item[index]"
                      class="d-flex flex-wrap"
                  >
                    <li>
                      <input
                          v-model="shipping"
                          name="shipping"
                          :value="item"
                          type="radio"
                          disabled
                          class="circle-b circle-one"
                      />
                    </li>
                    <li>
                      {{ item.name }}
                    </li>
                    <li>
                      {{
                        item.price && Number(item.price !== 0)
                            ? new Intl.NumberFormat().format(item.price) + "ریال "
                            : ""
                      }}
                    </li>
                  </ul>
                  <label v-if="errors.shipping" style="color: red"
                  >انتخاب روش ارسال الزامی است !</label
                  >
                </div>
                <!-- shippping -->

                <!-- description -->
                <div class="section-header">
                  <img src="/template/images/Subtract-pin.svg" alt="" />
                  توضیحات سفارش

                </div>
                <div>
              <textarea style="background: #eee;border-radius:15px;border: rgba(128,128,128,0.50) 1px solid"
                  class="input"
                  v-model="description"
                  placeholder="محل ورود توضیحات سفارش"
              ></textarea>
                </div>
                <!-- description -->
              </div>
              <div class="col-xl-6">
                <!-- discount -->
                <div class="section-header">
                  <img src="/template/images/gift.svg" alt="" />
                  کد تخفیف
                </div>
                <ul class="discount">
                  <li>
                    <input
                        class="input"
                        type="text"
                        name=""
                        :disabled="discountInput"
                        v-model="discountCode"
                        :placeholder="discountInputText"
                    />
                  </li>
                  <li>
                    <button
                        @click="applyDiscount()"
                        class="submit-btn"
                        type="button"
                        :disabled="discountInput"
                    >
                      ثبت کد تخفیف
                    </button>
                    <label v-if="errors.discount" style="color: red">{{
                        errors.discount
                      }}</label>
                  </li>
                </ul>
                <!-- discount -->

                <!-- details -->
                <div class="section-header">
                  <img src="/template/images/cart-info.svg" alt="" />

                  خلاصه‌ی سفارش شما
                </div>
                <div class="details">
                  <!-- <ul class="l2">
                <li style="padding-right: 33px">تخفیفات</li>
                <li class="position-left">۵۰،۰۰۰ریال</li>
              </ul> -->
                  <ul class="d-flex spaceing-ul">
                    <li>جمع</li>
                    <li>
                      {{ new Intl.NumberFormat().format(order.total_price) }} ریال
                    </li>
                  </ul>
                  <ul class="d-flex spaceing-ul" v-if="shipping">
                    <li>هزینه‌ی ارسال</li>
                    <li>
                      {{ new Intl.NumberFormat().format(shipping.price) }} ریال
                    </li>
                  </ul>
                  <ul v-if="discountAmount > 0" class="d-flex">
                    <li class="pl-3 pr-3">تخفیف کپن</li>
                    <li class="pl-3 pr-3">{{ discountAmount }} ریال</li>
                  </ul>
                  <hr class="hr-line" />
                  <ul class="d-flex spaceing-ul">
                    <li>مبلغ قابل پرداخت</li>
                    <li>{{ getPrice }} ریال</li>
                  </ul>
                  <ul class="d-flex spaceing-ul alert alert-success" v-if="paymentWay=='wallet'">
                    <li>مانده کیف پول</li>
                    <li>{{ new Intl.NumberFormat().format(wallet.amount-(shipping.price + order.total_price)) }} ریال</li>
                  </ul>
                  <ul>
                    <li>
                      <input
                          class="submit-btn"
                          type="button"
                          @click="applyCheckout()"
                          value="ثبت سفارش"
                      />
                      <router-link class="submit-btn text-light mx-1" to="/cart">بازگشت به سبد خرید</router-link>


                    </li>
                  </ul>
                </div>
                <!-- details -->
              </div>

        </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "Checkout",
  components: { Breadcrumb, Spinner },
  data() {
    return {
      addresses: [],
      addressId: null,
      shippings: [],
      shipping: null,
      loading: true,
      paymentWay: "",
      description: "",
      discountCode: null,
      order: {},
      errors: {
        // paymentWay: false,
        shipping: false,
        address: false,
        discount: null
      },
      discountInput: false,
      discountInputText: "در صورت داشتن کد تخفیف آن را وارد کنید.",
      discountAmount: 0 ,
      base: null,
      middle: '',
      prepayment_tow: '',
      prepayment_three: ''
    };
  },
  computed: {
    getPrice() {
      return new Intl.NumberFormat().format(
        this.order.total_price +
          (this.shipping && this.shipping.price ? this.shipping.price : 0)
      );
    }
  },
  created() {
              this.$store.dispatch("getLimitDelivery").then((res) => {
                this.limit_delivery = res.limit
                this.options = res.options
                this.base = res.installment.base
                this.middle = res.installment.middle
                this.prepayment_tow = res.installment.prepayment_tow
                this.prepayment_three = res.installment.prepayment_three
               
            });
    this.$store.dispatch("getAddresses").then(result => {
      this.addresses = result;
      this.addressId = result[0];
    });

    this.$store.dispatch("getShipping").then(result => {
      this.shippings = result;
      this.shipping = result[0];
    });

    if (this.$store.getters.getOrder) {
      this.order = this.$store.getters.getOrder;
      if (this.order.total_price == null) {
        this.$router.push("/cart");
      }
      //disable loader
      this.loading = false;
    } else {
      this.$store
        .dispatch("getOrder", {
          orderId: this.$route.params.orderId
        })
        .then(order => {
          this.order = order.order;
          if (this.order.total_price == null) {
            this.$router.push("/cart");
          }
          //disable loader
          this.loading = false;
        });
    }
  },
  methods: {
    applyDiscount() {
      if (this.discountCode != null) {
        //enable loader
        this.loading = true;
        this.$store
          .dispatch("applyDiscount", {
            orderId: this.$route.params.orderId,
            code: this.discountCode
          })
          .then(result => {
            if (result.success === true) {
              this.$swal.fire({
                position: "center",
                icon: "success",
                title: result.message,
                showConfirmButton: false,
                timer: 1500
              });
              this.discountInput = true;
              this.discountInputText = this.discountCode;
              this.discountAmount = result.amount;
              this.$store
                .dispatch("getOrder", {
                  orderId: this.$route.params.orderId
                })
                .then(order => {
                  this.order = order.order;
                });
            } else {
              this.$swal.fire({
                position: "center",
                icon: "warning",
                title: result.message,
                showConfirmButton: false,
                timer: 1500
              });
            }
            //disable loader
            this.loading = false;
          });
      } else {
        this.$swal.fire({
          position: "top-end",
          icon: "warning",
          title: "جهت استفاده از تخفیف کد مورد نیاز است !",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    applyCheckout: function() {
      // this.$swal.fire({
      //   position: 'top-end',
      //   icon: 'success',
      //   title: 'درصدی از مبلغ خرید در کیف پول شما شارژ شد',
      //   showConfirmButton: false,
      //   timer: 5000
      // })
      if (!this.addressId) {
        this.errors.address = true;
        setTimeout(() => {
          this.errors.address = false;
        }, 5000);
        return;
      }

      if (!this.shipping || !this.shipping.id) {
        this.errors.shipping = true;
        setTimeout(() => {
          this.errors.shipping = false;
        }, 5000);
        return;
      }

      //enable loader
      this.loading = true;
      // this.$store.dispatch("getProfile").then(result => {
      //   if (result === 401) {
      //     //show unauthorized user
      //     this.$swal
      //       .fire({
      //         position: "center",
      //         icon: "warning",
      //         title: "لطفا وارد حساب کاربری خود شوید !",
      //         showConfirmButton: false,
      //         timer: 1500
      //       })
      //       .then(() => {
      //         this.$router.push({ name: "auth" });
      //         return;
      //       });

      //     //disable loader
      //     this.loading = false;
      //   } else if (!result.accept) {
      //     this.$swal.fire({
      //       position: "center",
      //       icon: "warning",
      //       title: "حساب کاربری شما تایید نشده است",
      //       showConfirmButton: false,
      //       timer: 1500
      //     });

      //     //disable loader
      //     this.loading = false;
      //   } else if (result.accept) {
      //     if (this.$store.getters.getBasket.length > 0) {

      this.$store
        .dispatch("applyCheckout", {
          orderId: this.$route.params.orderId,
          addressId: this.addressId,
          shippingId: this.shipping.id,
          // paymentWay: this.paymentWay,
          description: this.description
        })
        .then(result => {
          if (result == 200) {
            //success
            localStorage.setItem("basket", "");
            this.$swal
              .fire({
                position: "center",
                icon: "success",
                title: "سفارش شما با موفقیت ثبت شد",
                showConfirmButton: false,
                timer: 1500
              })
              .then(() => {
                location.href = "/profile/orders";
                return;
              });
          } else if (!result) {
            //account not accepted
            let vm = this;
            if (
              vm.$store.getters.getShoppingError &&
              vm.$store.getters.getShoppingError == "accountNotAccept"
            ) {
              this.$swal
                .fire({
                  position: "center",
                  icon: "error",
                  title: vm.$store.getters.getShoppingMessage,
                  showConfirmButton: false,
                  timer: 1500
                })
                .then(() => {
                  vm.$router.push({ name: "Home" });
                  vm.loading = false;
                });
            } else if (
              vm.$store.getters.getShoppingError &&
              vm.$store.getters.getShoppingError == "quantityLimited"
            ) {
              //out of product

              this.$swal
                .fire({
                  position: "center",
                  icon: "error",
                  title: vm.$store.getters.getShoppingMessage,
                  showConfirmButton: false,
                  timer: 1500
                })
                .then(() => {
                  vm.$router.push("/cart");
                  vm.loading = false;
                });
            } else {
              //other
              this.$swal
                .fire({
                  position: "center",
                  icon: "error",
                  title: vm.$store.getters.getShoppingMessage,
                  showConfirmButton: false,
                  timer: 1500
                })
                .then(() => {
                  vm.$router.push({ name: "Home" });
                  vm.loading = false;
                });
            }
          }
          //disable loader
          this.loading = false;
        });

      // } else {
      //   this.$router.push({ name: "Home" });
      // }
      // }
      // });
      //   }
      // });
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
}
.spaceing-ul li {
  margin: 0 1rem;
  min-width: 100px;
  text-align: center;
}
.section-header {
  background-color: #e7e7e7;
  padding: 5px;
  border-radius: 5px;
  margin: 1rem auto;
}
.details {
  width: 300px;
  text-align: center;
  margin-right: auto;
}
@media screen and (max-width: 450px) {
  .details {
    width: 100%;
    margin: auto;
  }
}
/* discount */
.discount {
  display: flex;
}
.discount input {
  height: 40px;
  margin: 0;
  min-width: 250px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.discount input::placeholder {
  font-size: 12px;
}
.discount button {
  font-size: 14px;
  height: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
@media screen and (max-width: 450px) {
  .discount {
    justify-content: center;
  }
  .discount input {
    min-width: 200px;
  }
  .discount button {
    font-size: 10px;
  }
}
/* discount */
/* basket flow */
.basket-flow {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 2rem 0;
}
.basket-flow li:not(.line) {
  text-align: center;
  margin: 0 5px;
}
.basket-flow li img {
  width: 50px;
  height: 50px;
}
.basket-flow li.line {
  width: 50px;
  height: 5px;
  background-color: gray;
}
.basket-flow li.line.active {
  background-color: #ff4476;
}

@media screen and (max-width: 600px) {
  .basket-flow li {
    font-size: 11px;
  }
  .basket-flow li img {
    width: 20px;
    height: 20px;
  }
  .basket-flow li.line {
    width: 20px;
    height: 3px;
  }
}
/* basket flow */
</style>
