<template>
  <div>
    <div
        class="custom-modal-backdrop p-3"
        id="custom-backdrop"
        onclick="console.log('close');"
        style="display: none;"
    ></div>
    <div
        style="display: none;"
        class="modal fade"
        id="quickview_modal"
        tabindex="-1"
        data-backdrop="false"
        aria-labelledby="quickview_modal"
        aria-modal="true"
        role="dialog"
    >
      <div :key="Math.random()" class="modal-dialog modal-dialog-product modal-dialog-scrollable" role="document">
        <div class="modal-content" style="border-radius: 15px;background: #eee">
          <div class="modal-header" style="border: none">
            <h5 class="modal-title text-danger" id="exampleModalLabel">
              مشاهده‌ی سریع محصول
            </h5>
            <button
                type="button"
                class="close p-3"
                aria-label="Close"
                @click="closeModal()"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body" style="margin-top:-50px ">
            <div class="container-fluid">
              <Spinner v-if="loading"></Spinner>

              <div v-else>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-custom col-md-4 mt-3">
                      <!-- gallery -->
                      <div class="image-container d-flex">
                        <img
                            class="main-img"
                            :src="
                        mainImage && mainImage.length !== 0
                          ? mainImage
                          : '/template/images/no-image.png'
                      "
                            @error="mainImage = '/template/images/no-image.png'"
                            alt=""
                        />
                      </div>
                      <div class="d-flex justify-content-center">
                        <!-- favorite -->
                        <FavoriteAction
                            class="add-to-wishlist mt-3"
                            :productId="product.id"
                        ></FavoriteAction>
                        <!-- favorite -->
<!--                       Sunde Player-->

                      </div>
                      <div class="d-flex justify-content-center" v-if="product.intro_sunde">
                        <audio style="border-radius: 25px;margin: 15px 0" preload="none" :src="product.intro_sunde" type="audio/mpeg"
                               controls="controls">مرورگر شما از Player ساپورت نمی کند.</audio>

                      </div>

                      <!-- gallery -->
                    </div>
                    <div class="col-custom col-md-8 mt-3">
                      <!-- details -->
                      <div class="details">
                        <h5 style="max-width: 500px">
                          {{ product.name }}
                        </h5>
                        <br/>
                        <div class="">
                          <!-- <label v-if="product.get_brand">
                            ناشر:
                            <router-link
                              :to="{
                                name: 'search',
                                query: {
                                  brand: JSON.stringify([
                                    {
                                      id: product.get_brand.id,
                                      name: product.get_brand.name
                                    }
                                  ])
                                }
                              }"
                            >
                              {{ product.get_brand ? product.get_brand.name : "" }}
                            </router-link>
                          </label> -->

                <div class="row">

                  <div class="col-md-6">
                    <p style="margin: -5px" v-if="product.author">
                      نویسنده:
                      <router-link
                          :to="{
                            name: 'search',
                            query: {
                              author: JSON.stringify([
                                {
                                  id: product.author_id,
                                  name: product.author
                                }
                              ])
                            }
                          }"
                      >
                        {{ product.author ? product.author : "" }}
                      </router-link>
                    </p>
                  </div>
                  <div class="col-md-6">
                    <p style="margin: -5px" v-if="product.translator">
                      مترجم:
                      <router-link
                          :to="{
                            name: 'search',
                            query: {
                              translator: JSON.stringify([
                                {
                                  id: product.translator_id,
                                  name: product.translator
                                }
                              ])
                            }
                          }"
                      >
                        {{ product.translator ? product.translator : "" }}
                      </router-link>
                    </p>
                  </div>

                  <div class="col-md-6">
                    <p style="margin: -5px" v-if="publish.main_code"
                    >کداصلی: {{ publish.main_code }}</p
                    >
                  </div>
                  <div class="col-md-6">
                    <p style="margin: -5px" v-if="product.brand_name"
                    >ناشر:
                      <router-link
                          :to="{
                            name: 'search',
                            query: {
                              brand: JSON.stringify([
                                {
                                  id: product.brand_id,
                                  name: product.brand_name
                                }
                              ])
                            }
                          }"
                      >
                        {{ product.brand_name ? product.brand_name : "" }}
                      </router-link>
                    </p>
                  </div>



                  <div class="col-md-6">
                    <p style="margin: -5px" v-if="product.main_topic"
                    >موضوع: {{ product.main_topic }}</p
                    >
                  </div>
                  <div class="col-md-6">
                    <p style="margin: -5px" v-if="publish.cover_type"
                    >نوع جلد: {{ publish.cover_type }}</p
                    >
                  </div>


                  <div class="col-md-6">
                    <p style="margin: -5px" v-if="publish.book_size"
                    >قطع: {{ publish.book_size }}</p
                    >
                  </div>
                  <div class="col-md-6">
                    <p style="margin: -5px" v-if="publish.publish_year"
                    >سال چاپ: {{ publish.publish_year }}</p
                    >
                  </div>


                  <div style="margin: -5px" class="col-md-6" >
                    <p style="margin: -5px" v-if="publish.weight">
                      وزن: {{ publish.weight }}
                    </p>

                  </div>
                  <div class="col-md-6" >
                    <p style="margin: -5px" v-if="product.page_count">
                      صفحه: {{ product.page_count }}
                    </p>

                  </div>


                  <div  class="col-md-6" >
                    <p style="margin: -5px" v-if="publish.isbn">
                      شابک: {{ publish.isbn }}
                    </p>

                  </div>
                  <div class="col-md-6">
                    <p style="margin: -5px" v-if="publish.created_at"
                    >تاریخ ورود به انبار:
                      {{
                        publish.created_at | moment("jDD-jMM-jYYYY")
                      }}</p
                    >
                  </div>
                </div>







                        </div>
                        <label
                            v-if="
                        Number(publish.quantity) > 0 &&
                          Number(publish.quantity) < x
                      "
                        >
                          وضعیت موجودی:
                          <span style="color: darkred"
                          >تنها {{ Number(publish.quantity) }} عدد باقی
                        مانده</span
                          >
                        </label>
                        <label v-else-if="Number(publish.quantity) <= 0">
                          وضعیت موجودی:
                          <span style="color: #e3572e">ناموجود</span>
                        </label>
                        <label v-else-if="Number(publish.quantity) >= x">
                          وضعیت موجودی:
                          <span style="color: green">موجود</span>
                        </label>

                        <div class="form-row">
                          <!-- publish -->
                          <div class="col">
                            <label>نوبت چاپ :</label>
                            <select style="border-radius: 15px"
                                    class="form-control"
                                    v-model="publish"
                                    ref="publish"
                                    @change="calculatePrice()"
                            >
                              <option
                                  v-for="(item, index) in product.dynamicAttributes"
                                  :key="'publish' + index"
                                  :value="item"
                              >
                                <span v-if="item.publish">{{ item.publish }}</span>
                              </option>
                            </select>
                          </div>

                          <!-- publish -->

                          <!-- counter -->
                          <div class="col">
                            <label>تعداد :</label>
                            <counter style="border-radius: 15px"
                                     ref="counter"
                                     v-if="Number(publish.quantity) > 0"
                                     :min="product.min_limit ? product.min_limit : 1"
                                     :max="
                          product.max_limit &&
                          Number(product.max_limit) < Number(publish.quantity)
                            ? product.max_limit
                            : publish.quantity
                        "
                                     :value="product.min_limit || 1"
                                     @input="count = $event"
                            ></counter>
                          </div>

                          <!-- counter -->
                        </div>
                        <hr>
                        <!-- special price -->
                        <div
                            v-if="
                        publish.special_price && publish.special_price !== 0
                      "
                        >
                          <div class="d-flex">
                            <span class="old-price">
                              {{
                                new Intl.NumberFormat().format(
                                    publish.back_cover_price
                                )
                              }}
                              ریال
                            </span>
                            <span class="discount-percent mr-2">
                              <!-- {{
                              publish.special_price > 100
                                ? new Intl.NumberFormat().format(publish.special_price)
                                : publish.special_price + "%"
                            }} -->
                              {{
                                new Intl.NumberFormat().format(
                                    (
                                        ((Number(publish.back_cover_price) -
                                                Number(publish.special_price)) /
                                            Number(publish.back_cover_price)) *
                                        100
                                    ).toFixed(0)
                                ) + "%"
                              }}
                            </span>
                          </div>
                          <span class="new-price text-center">
                            <!-- {{
                            publish.special_price > 100
                              ? new Intl.NumberFormat().format(
                                  Number(publish.back_cover_price) -
                                    Number(publish.special_price)
                                )
                              : new Intl.NumberFormat().format(
                                  (
                                    Number(publish.back_cover_price) -
                                    (Number(publish.back_cover_price) *
                                      Number(publish.special_price)) /
                                      100
                                  ).toFixed(0)
                                )
                          }} -->
                            {{
                              new Intl.NumberFormat().format(publish.special_price)
                            }}
                            ریال
                          </span>
                        </div>
                        <!-- special price -->

                        <!-- price -->
                        <span v-else class="new-price text-center" v-show="publish">
                          {{
                            new Intl.NumberFormat().format(publish.back_cover_price)
                          }}
                          ریال
                        </span>
                        <!-- price -->

                        <div>
                          <Spinner v-if="basketLoading" :circle="true"></Spinner>

                          <input
                              v-else-if="publish.quantity > 0"
                              @click="addToCard()"
                              class="submit-btn btn-margin"
                              type="button"
                              style=""
                              value="افزودن به سبد خرید"
                          />

                          <!-- request order -->
                          <input
                              v-if="Number(publish.quantity) === 0"
                              @click="registerOrder()"
                              class="info-btn"
                              type="button"
                              value="سفارش کالا"
                          />
                          <!-- request order  -->

                          <!-- <input
                          v-else
                          @click="registerOrder()"
                          class="info-btn btn-margin"
                          type="button"
                          value="سفارش کالا"
                        /> -->
                        </div>
                        <!-- details -->
                      </div>
                      <!-- details -->
                    </div>
                  </div>

                </div>
                <!--
                <ProductContent
                    :product="product"
                    :publish="publish"
                ></ProductContent>
                -->
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div
        class="modal-backdrop fade show"
        id="backdrop"
        onclick="console.log('close');"
        @click="closeModal()"
        style="display: none;"
    ></div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.component";
import FavoriteAction from "@/components/FavoriteAction.component";
import counter from "@/components/counter.component";

export default {
  name: "quickview",
  components: {Spinner, FavoriteAction, counter},
  data() {
    return {
      loader: true,
      loading: true,
      basketLoading: false,
      mainImage: "",
      selected: {},
      product: {},
      publish: {
        id: "",
        price: ""
      },
      count: 0,
      price: 0,
      x: 10
    };
  },
  created() {
    this.$root.$refs.quickview = this;
  },
  methods: {
    registerOrder() {
      let selected = {
        productId: this.product.id,
        attrId: this.publish.id,
        quantity: this.count
      };

      //enable loader
      this.loading = true;

      this.$store
          .dispatch("registerOrder", {
            options: selected
          })
          .then(result => {
            if (result === 401) {
              this.$swal
                  .fire({
                    position: "center",
                    icon: "warning",
                    title: "لطفا وارد حساب کاربری خود شوید !",
                    showConfirmButton: false,
                    timer: 1500
                  })
                  .then(() => {
                    this.$router.push({name: "auth"});
                    return;
                  });
            } else if (result === 200) {
              this.$swal.fire({
                position: "center",
                icon: "success",
                title: "سفارش شما با موفقیت ثبت شد",
                showConfirmButton: false,
                timer: 1500
              });
            } else {
              this.$swal.fire({
                position: "center",
                icon: "error",
                title: "ثبت درخواست با مشکل مواجه شد",
                showConfirmButton: false,
                timer: 1500
              });
            }

            //disable loader
            this.loading = false;
          });
    },
    closeModal() {
      document.getElementById("backdrop").style.display = "none";
      document.getElementById("quickview_modal").style.display = "none";
      document.getElementById("quickview_modal").classList.remove("show");
    },
    setProductInfo(checkproduct) {
      this.loading = true;

      this.product = checkproduct;
      if (checkproduct.attributes) {
        this.product.dynamicAttributes = checkproduct.attributes;
      }
      this.mainImage = this.product.intro_image;
      if (
          this.product.dynamicAttributes &&
          this.product.dynamicAttributes.length > 0
      ) {
        this.publish = this.product.dynamicAttributes[0];
        this.count = this.product.min_limit ? this.product.min_limit : 1;
      }
      this.loading = false;
    },
    calculatePrice() {
      this.count = this.product.min_limit ? this.product.min_limit : 1;
      //reset count
      return (
          Number(this.count) *
          Number(
              this.publish.special_price && this.publish.special_price !== 0
                  ? this.publish.special_price
                  : this.publish.back_cover_price
          )
      );
    },
    addToCard() {
      if (!this.publish || !this.publish.id || this.count <= 0) {
        this.$refs.publish.focus();
        return;
      }

      //save in localstorage and update basket
      let selected = {
        image: this.product.intro_image,
        productId: this.product.id,
        name: this.product.name,
        price: this.product.price,
        special_price: this.product.special_price ?? 0,
        min_limit: this.product.min_limit,
        max_limit: this.product.max_limit,
        quantity: this.count,
        // publish: this.publish.publish, //pass all publish
        options: this.publish.id,
        publish_quantity: this.product.quantity,
        slug: this.product.slug
      };

      this.$store.dispatch("addToBasket", {
        product: selected
      });
      if (localStorage.getItem("token")) {
        //enable loader
        this.basketLoading = true;

        this.$store
            .dispatch("addToBasketReq", {product: selected})
            .then(res => {
              if (!res) {
                this.$swal
                    .fire({
                      position: "center",
                      icon: "warning",
                      title: "خطا در اضافه کردن محصول",
                      showConfirmButton: false,
                      timer: 1500
                    })
                    .then(() => {
                      //failed then remove from basket
                      this.$store.dispatch("removeFromBasket", {
                        product: selected
                      });
                    });
              }
              //disable loader
              this.basketLoading = false;

              this.closeModal();
            });
      }
      // this.$store
      //     .dispatch("addToBasket", {
      //       product: selected
      //     })
      //     .then(product => {
      //       //if user is login save in db
      //       if (localStorage.getItem("token")) {
      //         //enable loader
      //         this.basketLoading = true;

      //         this.$store
      //             .dispatch("addToBasketReq", {product: product})
      //             .then(res => {
      //               if (!res) {
      //                 this.$swal
      //                     .fire({
      //                       position: "center",
      //                       icon: "warning",
      //                       title: "خطا در اضافه کردن محصول",
      //                       showConfirmButton: false,
      //                       timer: 1500
      //                     })
      //                     .then(() => {
      //                       //failed then remove from basket
      //                       this.$store.dispatch("removeFromBasket", {
      //                         product: product
      //                       });
      //                     });
      //               }
      //               //disable loader
      //               this.basketLoading = false;
      //             });
      //       }
      //       this.closeModal();
      //     });
    }
  }
};
</script>

<style scoped>
audio::-webkit-media-controls-panel {
  background-color: #56AEFF;
}

audio::-webkit-media-controls-current-time-display {
  color: #fff;
}


.audio-player{
  background: #fff!important;
  border-radius: 25px;
}
.add-to-wishlist {
  border: none !important;
  color: #1c403e !important;
  margin-right: auto;
  margin-left: auto;
  font-size: 13px;
}

.details {
  margin-top: 0.5rem;
  display: flex;
  flex-flow: column;
  text-align: right;
  line-height: 2;
  height: 100%;
  min-width: 400px;
  justify-content: space-between;
}

.image-container {
  max-height: 500px;
}

.image-container ul {
  direction: ltr;
  list-style: none;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0 !important;
}

.image-container ul li {
  padding-right: 5px;
}

.image-container ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.image-container ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.image-container ul::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.image-container .main-img {
  border-radius: 10px;
  box-shadow: 2px 2px 7px rgba(76, 73, 73, 0.38);
  max-width: 200px;
  margin: auto;
  transition: 0.5s;
}

.main-img:hover {
  box-shadow: 3px 3px 15px rgba(39, 37, 37, 0.62);
}

.btn-margin {
  margin: 1rem;
  width: 100%;
  background: rgba(139, 140, 157, 0.95);
  border-radius: 15px;
  box-shadow: 2px 2px 7px rgba(100, 95, 95, 0.42);
  transition: 0.3s;
}

.btn-margin:hover {
  box-shadow: 3px 3px 10px rgba(100, 95, 95, 0.75);
}

.image-container ul li img {
  width: 50px;
  margin: 0 auto 0.5rem auto;
  cursor: pointer;
}

.old-price {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #9b9b9b;
  text-decoration-line: line-through;
}

.select-mr {
  margin-left: 1rem;
  border-radius: 15px;
  flex: 0.5 1 auto !important;
}

.new-price {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}

.discount-percent {
  height: 25px;
  width: 45px;
  border-radius: 29px;
  background: rgba(255, 68, 118, 1);
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 550px) {
  .image-container-div {
    width: 100%;
  }

  .image-container {
    flex-flow: column-reverse;
    max-height: 100%;
  }

  .image-container .slides {
    display: flex;
    margin-top: 1rem;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .image-container ul li {
    display: flex;
  }

  .col-custom {
    width: 100%;
  }

  .details {
    min-width: 100%;
  }
}

@media (min-width: 576px) {
  .modal-dialog-product {
    max-width: 90vw !important;
  }

  #quickview_modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90vw !important;
    height: unset;
  }

}

@media (min-width: 1024px) {
  .modal-dialog-product {
    margin-top: 30vh;
    max-width: 60vw !important;
  }

  #quickview_modal {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 60vw !important;
    height: unset;
  }

}

.close {
  float: left;
  font-weight: bold;
  margin: unset !important;
  display: inline;
  padding: 10px !important;
}

.custom-modal-backdrop {
  position: fixed;
  background-color: black;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1040;
}


.book_info {
  line-height: 10px;
  font-size: 14px;
}

.book_info > * {
  display: block;
}
</style>
