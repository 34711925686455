import axios from "axios";
import { env } from "../../../enums";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getCategoryProducts(
      context,
      {
        slug,
        q,
        page,
        count,
        isExist,
        sort,
        brand,
        author,
        translator,
        range_start,
        range_end,
        book_size,
        cover_type,
        main_topic,
        page_start,
        page_end,
        weight_start,
        weight_end,
        published_start,
        published_end,
        publish_year_start,
        publish_year_end,
        created_at,
        created_at_start,
        created_at_end
      }
    ) {
      return new Promise(function(resolve) {
        axios
          .get(
            env.base_url +
              `/category/${slug}
              ${page ? "?page=" + page : ""}${q ? "&q=" + q : ""}
              ${count ? "&count=" + count : ""}
              ${isExist ? "&isExist=" + isExist : ""}
              ${sort ? "&sort=" + sort : ""}
              ${brand ? "&brand=" + brand : ""}
              ${author ? "&author=" + author : ""}
              ${translator ? "&translator=" + translator : ""}
              ${range_start ? "&range_start=" + range_start : ""}
              ${
                range_end && range_end < 100000000
                  ? "&range_end=" + range_end
                  : ""
              }
              ${book_size ? "&book_size=" + book_size : ""}
              ${cover_type ? "&cover_type=" + cover_type : ""}
              ${main_topic ? "&main_topic=" + main_topic : ""}
              ${page_start ? "&page_start=" + page_start : ""}
              ${page_end ? "&page_end=" + page_end : ""}
              ${weight_start ? "&weight_start=" + weight_start : ""}
              ${weight_end ? "&weight_end=" + weight_end : ""}
              ${published_start ? "&published_start=" + published_start : ""}
              ${published_end ? "&published_end=" + published_end : ""}
              ${
                publish_year_start
                  ? "&publish_year_start=" + publish_year_start
                  : ""
              }
              ${publish_year_end ? "&publish_year_end=" + publish_year_end : ""}
              ${created_at ? "&created_at=" + created_at : ""}
              ${created_at_start ? "&created_at_start=" + created_at_start : ""}
              ${created_at_end ? "&created_at_end=" + created_at_end : ""}`
          )
          .then(result => {
            if (result.status === 200) {
              resolve(result.data);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    }
  }
};
