<template>
  <Spinner v-if="loading && vertical" :circle="true"></Spinner>
  <Spinner
      v-else-if="loading"
      :circle="true"
      style="margin-top: 2rem"
  ></Spinner>

  <div style="border:none;"
       v-else
       class="product-main-container"
       v-bind:class="{ vertical: vertical }"
  >
    <router-link
        tag="a"
        value="مشاهده‌ی جزئیات محصول"
        type="button"
        :to="'/product/' + product.slug"
    >
      <div>
        <img class="img-container"
             :src="
            product.intro_image && product.intro_image.length !== 0
              ? product.intro_image
              : '/template/images/no-image.png'
          "
             @error="product.intro_image = '/template/images/no-image.png'"
             :alt="product.intro_image"
        />
      </div>
      <div class="product-details-container">
        <div>
          <b class="product-name hoverItem">
            {{ product.name.substring(0,29) }}
          </b>
          <span class="product-name" v-if="product.author">{{
              product.author.substring(0,29)+".."
            }}</span>
        </div>
        <div style="direction: rtl">
          <span class="price mi-txt hoverItem" v-if="product.quantity !== 0">
            <!-- special price -->
            <div v-if="product.special_price && product.special_price !== 0">
              <div class="d-flex align-items-center justify-content-center">
                <p class="old-price mb-0">
                  {{ new Intl.NumberFormat().format(product.price) }}
                  ریال
                </p>
                <p class="discount-percent mr-2 mb-0">
                  {{
                    new Intl.NumberFormat().format(
                        (
                            ((Number(product.price) -
                                    Number(product.special_price)) /
                                Number(product.price)) *
                            100
                        ).toFixed(0)
                    ) + "%"
                  }}
                </p>
              </div>
              <p class="new-price m-0">
                {{ new Intl.NumberFormat().format(product.special_price) }}
                ریال
              </p>
            </div>
            <!-- special price -->

            <!-- price -->
            <p v-else class="new-price">
              {{ new Intl.NumberFormat().format(product.price) }}
              ریال
            </p>
            <!-- price -->
          </span>
          <span v-else style="color: red">ناموجود</span>
        </div>
        <!-- <router-link
              class="info-btn mb-2 mt-2"
              tag="a"
              value="مشاهده‌ی جزئیات محصول"
              type="button"
              :to="'/product/' + product.slug"
            >
              مشاهده
            </router-link> -->
      </div>
    </router-link>

    <a
        v-if="Number(product.quantity) === 0"
        @click="registerOrder(product)"
        class="submit-btn my-2"
    >سفارش کالا</a
    >
    <a v-else class="submit-btn my-2" @click="addToCard(product)">
      افزودن به سبد خرید
    </a>

    <a @click="quickview(product)" class="quickview">
      <i class="fa fa-eye"></i>
    </a>
    <table
        v-if="!loading"
        class="extra_info"
        v-on:click="GoProductPage(product.slug)"
    >
      <li style="text-align: center" v-if="product.main_code">کداصلی: {{ product.main_code }}</li>
      <li v-if="product.brand_name">ناشر: {{ product.brand_name }}</li>
      <li v-if="product.main_topic">موضوع: {{ product.main_topic }}</li>
      <li v-if="product.translator">مترجم: {{ product.translator }}</li>
    </table>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.component";

export default {
  props: ["product", "vertical"],
  components: {Spinner},
  data() {
    return {
      loading: false
    };
  },
  methods: {
    GoProductPage(slug) {
      this.$router.push("/product/" + slug);
    },
    quickview(product) {
      document.getElementById("backdrop").style.display = "block";
      document.getElementById("quickview_modal").style.display = "block";
      document.getElementById("quickview_modal").classList.add("show");

      this.$root.$refs.quickview.setProductInfo(product);
    },
    addToCard(product) {
      //save in localstorage and update basket
      let selected = {
        image: product.intro_image,
        productId: product.id,
        name: product.name,
        slug: product.slug,
        price: product.price,
        min_limit: product.min_limit,
        max_limit: product.max_limit,
        quantity: 1,
        // publish: this.publish,
        publish_quantity: product.quantity,
        options: product.publish_id,
        special_price: product.special_price
      };

      //add to localstorage
      this.$store.dispatch("addToBasket", {
        product: selected
      });
      if (localStorage.getItem("token")) {
        //enable basket loader
        // this.$store.commit("setBasketLoading", true);
        this.$store
            .dispatch("addToBasketReq", {product: selected})
            .then(res => {
              if (!res) {
                this.$swal
                    .fire({
                      position: "center",
                      icon: "warning",
                      title: "خطا در اضافه کردن محصول",
                      showConfirmButton: false,
                      timer: 1500
                    })
                    .then(() => {
                      //failed then remove from basket
                      this.$store.dispatch("removeFromBasket", {
                        product: selected
                      });
                    });
              }
              //disable basket loader
              // this.$store.commit("setBasketLoading", false);
            });
      }
    },
    registerOrder(product) {
      let selected = {
        productId: product.id,
        attrId: product.publish_id,
        quantity: 1
      };

      //enable loader
      this.loading = true;
      this.$store
          .dispatch("registerOrder", {
            options: selected
          })
          .then(result => {
            if (result === 401) {
              this.$swal
                  .fire({
                    position: "center",
                    icon: "warning",
                    title: "لطفا وارد حساب کاربری خود شوید !",
                    showConfirmButton: false,
                    timer: 1500
                  })
                  .then(() => {
                    this.$router.push({name: "auth"});
                    return;
                  });
            } else if (result === 200) {
              this.$swal.fire({
                position: "center",
                icon: "success",
                title: "سفارش شما با موفقیت ثبت شد",
                showConfirmButton: false,
                timer: 1500
              });
            } else {
              this.$swal.fire({
                position: "center",
                icon: "error",
                title: "ثبت درخواست با مشکل مواجه شد",
                showConfirmButton: false,
                timer: 1500
              });
            }

            //disable loader
            this.loading = false;
          });
    }
  }
};
</script>

<style scoped>
.product-main-container {
  position: relative;
  cursor: pointer;
  width: 200px;
  height: 300px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  transition: 0.3s;
}

.product-main-container:hover {
  border: 1px solid rgba(76, 73, 73, 0.38);
  border-radius: 20px;
  box-shadow: 2px 2px 7px rgba(76, 73, 73, 0.38);
}

.product-main-container a:first-child {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  padding-bottom: 50px;
}

.product-main-container .info-btn,
.product-main-container .submit-btn {
  background: rgba(139, 140, 157, 0.95);
  border-radius: 15px;
  position: absolute;
  z-index: 100;
  bottom: 1px;
  height: 35px;
  right: 0;
  font-size: 14px;
  align-items: center;
  display: flex;
  left: 0;
  margin: 0 1rem;
  justify-content: center;
}

.product-main-container .submit-btn {
  color: #fff !important;
}

.product-main-container img {
  width: 80px;
  max-height: 140px;
  margin: 1rem auto;
  object-fit: contain;
}

.product-main-container .product-name {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  text-align: center;
  margin: 5px auto;
  max-height: 25px;
  color: rgba(230, 7, 7, 0.53);
  font-size: 14px;
}

.product-main-container .img-container {
  border-radius: 10px;
  box-shadow: 3px 3px 12px #9b8d8d;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  transition: 0.5ms;
}

.img-container:hover {
  box-shadow: 3px 3px 12px #9b8d8d;
}

.product-main-container .show-product {
  margin-top: 1rem;
}

.product-main-container .product-details-container {
  /* background-color: #fff; */
  width: 100%;
  text-align: center;
}

.product-main-container .product-details-container span {
  color: #000 !important;
}

/* vertical */
.product-main-container.vertical a:first-child {
  flex-flow: row;
}

.product-main-container.vertical {
  height: 230px !important;
}

.product-main-container.vertical img {
  width: 100px;
}

.product-main-container.vertical .info-btn,
.product-main-container.vertical .submit-btn {
  margin: 0 3rem;
}

@media only screen and (max-width: 800px) {
  .product-main-container .product-name {
    width: 120px;
  }

  .product-main-container.vertical img {
    width: 80px;
  }

  .product-main-container .info-btn,
  .product-main-container .submit-btn {
    font-size: 8px;
  }
}

@media only screen and (max-width: 500px) {
  .product-main-container.vertical .product-name {
    width: 100%;
  }

  .product-main-container.vertical .product-details-container {
    width: 130px;
    margin-left: 1rem;
  }

  .product-main-container .img-container {
    margin: auto 0rem;
  }

  .product-main-container.vertical .product-details-container {
    margin-left: 0 !important;
  }
}

.quickview {
  color: rgba(35, 32, 32, 0.37) !important;
  padding: 10px;
  position: relative;
  float: right;
  z-index: 1000;
  cursor: pointer;
  width: 55px;
  height: 55px;
}

.product-main-container {
  z-index: 0;
}

.extra_info {
  position: absolute;
  background: rgba(102, 51, 153,1);
  width: 80%;
  height: 60%;
  left: 50%;
  top: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: #ecebeb;
  /*text-align: center;*/
  font-size: 12px;
  border-radius: 10px;
  padding: 20px !important;
  line-height: 2.2;
  text-align: right !important;
  list-style-type: none;
  transition: 0.5s;
}

.product-main-container:hover .extra_info {
  opacity: 1;
}

.extra_info li {
  padding-top: 5px;
  margin: auto;
  text-align: center;
  font-size: 14px;
}
</style>
