<template>
  <Spinner
    v-if="loading && products && products.length !== 0"
    :circle="true"
  ></Spinner>

  <!-- latest in home page -->
  <div
    v-else-if="products && products.length !== 0"
    class="container mt-5 mb-5"
  >
    <div class="row">
      <!-- latest products in ul form -->
      <div class="col-sm-8 newrelease" style="">
        <h4 class="text-right" style="margin: 0 2rem">تازه‌ها</h4>

        <br />

        <!-- tablist -->
        <div class="d-flex flex-wrap justify-content-between">
          <ul class="newrelease-tablist">
            <li
              v-if="result.first_edition_books.length !== 0"
              @click="firstEditionBooks"
              v-bind:class="{ active: products.type == 'first_edition_books' }"
            >
              چاپ اول
            </li>

            <li
              v-if="result.not_first_edition_books.length !== 0"
              @click="notFirstEditionBooks"
              v-bind:class="{
                active: products.type == 'not_first_edition_books'
              }"
            >
              تجدید چاپ
            </li>
            <li
                v-if="result.journals.length !== 0"
                @click="journals"
                v-bind:class="{ active: products.type == 'journals' }"
            >
              نشریات
            </li>
            <li
              v-if="result.not_books.length !== 0"
              @click="notBooks"
              v-bind:class="{ active: products.type == 'not_books' }"
            >
              غیرکتاب
            </li>
          </ul>

          <router-link
            class="show-more d-flex ml-4 mr-4"
            style="cursor: pointer"
            tag="p"
            :to="{
              name: 'search',
              query: {
                sort: 'created'
              }
            }"
          >
            نمایش بیشتر
            <i class="fa fa-angle-left mr-2" style="font-size: 22px"></i>
          </router-link>
        </div>

        <div class="newrelease-container">
          <Spinner v-if="loadData" :leanier="true"></Spinner>
          <VueSlickCarousel v-else v-bind="settings">
            <ProductItem
              v-for="(product, index) in products.items"
              :key="product[index]"
              :product="product"
            ></ProductItem>
          </VueSlickCarousel>
        </div>
        <!-- tablist -->
      </div>
      <!-- latest products in ul form -->

      <div class="col-sm-4 latest-banner" style="margin-bottom: 8px">
        <a class="banner__body" :href="result.banner.url">
          <div class="banner__image">
            <img
              class=""
              v-lazy="result.banner.image"
              alt="تازه‌ها"
              @error="
                '/template/images/no-image.png';


              "
            />
          </div>
          <!-- <div class="banner__text">
            <h5>
              تا <span style="color: red">25%</span> تخفیف بیشتر
              <br />
              برای سفارش های
              <br />
              <b>بالای 1 ملیون تومان</b>
            </h5>
          </div> -->
          <!-- <div class="banner-more-btn">
            <a href="" class="info-btn"> مشاهده‌ی بیشتر </a>
          </div> -->
        </a>
      </div>
    </div>
  </div>
  <!-- latest in home page -->
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductItem from "@/components/ProductItem.component";
import Spinner from "@/components/Spinner.component";

export default {
  props: ["type"],
  components: {
    ProductItem,
    VueSlickCarousel,
    Spinner
  },
  data() {
    return {
      loading: true,
      loadData: false,
      settings: {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        speed: 500,
        rows: 2,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              rows: 1,
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 800,
            settings: {
              rows: 1,
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 570,
            settings: {
              row: 1,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      result: {
        journals: [],
        not_books: [],
        first_edition_books: [],
        not_first_edition_books: [],
        banner: {}
      },
      products: {
        type: "",
        items: []
      }
    };
  },
  mounted() {
    //get products per category
    this.$store.dispatch("getLatestProducts").then(result => {
      this.result = result;
      this.products = {
        type: "first_edition_books",
        items: this.result.first_edition_books
      };
      this.loading = false;
    });
  },
  methods: {
    firstEditionBooks() {
      this.loadData = true;
      this.products = {
        type: "first_edition_books",
        items: this.result.first_edition_books
      };
      let vm = this;
      setTimeout(function() {
        vm.loadData = false;
      }, 1000);
    },
    notFirstEditionBooks() {
      this.loadData = true;
      this.products = {
        type: "not_first_edition_books",
        items: this.result.not_first_edition_books
      };
      let vm = this;
      setTimeout(function() {
        vm.loadData = false;
      }, 1000);
    },
    journals() {
      this.loadData = true;

      this.products = {
        type: "journals",
        items: this.result.journals
      };
      let vm = this;
      setTimeout(function() {
        vm.loadData = false;
      }, 1000);
    },
    notBooks() {
      this.loadData = true;

      this.products = {
        type: "not_books",
        items: this.result.not_books
      };
      let vm = this;
      setTimeout(function() {
        vm.loadData = false;
      }, 1000);
    }
  }
};
</script>
<style >
.main-container {
  margin: 0 !important;
}

/*latest in home page*/

.latest-banner {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #fff6f6;
}
.banner__text {
  text-align: center;
  line-height: 2;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.banner__image img {
  width: 100%;
  height: auto;
  margin: 1rem auto;
}
.banner__image {
  margin-right: auto;
  margin-left: auto;
  display: table;
}
.banner-more-btn {
  text-align: center;
}
.banner-more-btn a {
  cursor: pointer;
  background-color: #f75454;
  color: #000;
  padding: 0.5rem 1rem;
  border: 1px solid #f75454;
  border-radius: 5px;
}
 /*latest in home page*/
@media only screen and (max-width: 500px) {
  .latest-banner {
    margin: 0 2rem !important;
  }
}

/*//tablist*/
.newrelease-tablist {
  list-style: none;
  display: flex;
  padding-right: 1rem;
}
.newrelease-tablist li {
  margin: 0 0.5rem;
  cursor: pointer;
  padding-bottom: 3px;
}
.newrelease-tablist li:hover,
.newrelease-tablist li.active {
  border-bottom: 1px solid #000;
}

@media only screen and (max-width: 570px) {
  .newrelease-container {
    min-height: 200px;
    position: relative;
  }
}
@media only screen and (max-width: 400px) {
  .newrelease-tablist {
    font-size: 14px;
  }
}
</style>
