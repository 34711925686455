<template>
  <div class="">
    <p v-html="product_id"></p>
    {{ product }}
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Returned",
  data() {
    return {
      product_id: this.$route.params.id,
      product: null
    }
  },
  mounted() {
    axios
        .get('https://api.coindesk.com/v1/bpi/currentprice.json')
        .then(response => (this.product = response))

  }


}
</script>

<style scoped>

</style>