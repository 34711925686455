import Dashboard from "../../views/pages/Profile/Profile.Dashboard.vue";
import Edit from "../../views/pages/Profile/Profile.Edit.vue";
import Address from "../../views/pages/Profile/Profile.Address.vue";
// import AddAddress from "../../views/pages/Profile/Profile.Add.Address";
import Addresses from "../../views/pages/Profile/Profile.Dashboard.Address.vue";
import Order from "../../views/pages/Profile/Profile.Order.vue";
import OrderShow from "../../views/pages/Profile/Profile.Order.Show.vue";
import Invoice from "../../views/pages/Profile/Profile.invoice.vue";
import Comment from "../../views/pages/Profile/Profile.Comment.vue";
import Favorite from "../../views/pages/Profile/Profile.Favorite.vue";
// import Auth from "../../views/pages/Auth/Auth.vue";
import ChangePassword from "../../views/pages/Profile/Profile.ChangePassword.vue";
import Requests from "../../views/pages/Profile/Profile.ProductRequests.vue";
import Returned from "../../views/pages/Profile/Profile.Returned.vue";
// import Profile from "../../views/pages/Profile.vue";
export default [
  // {
  //   path: "/profile/auth",
  //   name: "auth",
  //   component: Auth
  // },
  {
    path: "/profile",
    name: "ProfileInfo",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/edit",
    name: "ProfileEdit",
    component: Edit,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/address",
    name: "addresses",
    component: Addresses,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/orders",
    name: "MyOrders",
    component: Order,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/returned",
    name: "Returned",
    component: Returned,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/orders/order/:id",
    name: "orderShow",
    component: OrderShow,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/orders/invoice/:id",
    name: "invoice",
    component: Invoice,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/comments",
    name: "MyComment",
    component: Comment,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/favorite",
    name: "FavoriteList",
    component: Favorite,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/address/add",
    name: "AddAddress",
    component: Address,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/change/password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: { requiresAuth: true }
  },
  {
    path: "/profile/requests",
    name: "ProductRequests",
    component: Requests,
    meta: { requiresAuth: true }
  }
];
