<template>
  <div
    @click="actionLike(productId)"
    class="info-btn w-max"
    v-bind:class="{ active: like === true }"
  >
    افزودن به علاقه‌مندی‌ها
    <img
      v-show="like === false"
      src="/template/images/heart.svg"
      alt="heart"
      width="30px"
    />
    <img
      v-show="like === true"
      src="/template/images/heart(filled).svg"
      alt="heart"
      width="30px"
      style="w"
    />
  </div>
</template>

<script>
export default {
  name: "FavoriteAction",
  data: function() {
    return {
      like: false
    };
  },
  computed: {},
  props: ["productId"],
  methods: {
    actionLike(productId) {
      if (localStorage.getItem("token") === null) {
        this.$swal.fire({
          position: "center",
          icon: "warning",
          title: "لطفا وارد حساب کاربری خود شوید !",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        if (productId) {
          this.$store.dispatch("likeAction", { productId }).then(() => {
            this.like = !this.like;
          });
        }
      }
    }
  },
  created() {
    setTimeout(() => {
      this.$store
        .dispatch("checkLikeStatus", { productId: this.$props["productId"] })
        .then(result => {
          if (result) {
            this.like = true;
          } else {
            this.like = false;
          }
        });
    }, 2000);
  },
  beforeRouteUpdate() {
    setTimeout(() => {
      this.$store
        .dispatch("checkLikeStatus", { productId: this.$props["productId"] })
        .then(result => {
          if (result) {
            this.like = true;
          } else {
            this.like = false;
          }
        });
    }, 2000);
  }
};
</script>
<style scoped></style>
