<template>
  <section>
    <Breadcrumb
      current-page-name="تغییر رمز عبور"
      section-name="داشبورد کاربر"
      section-addr="/profile"
    ></Breadcrumb>

    <div class="custom-container">
      <div class="row justify-content-center">
        <SideMenu></SideMenu>
        <Spinner v-if="loading"> </Spinner>

        <div v-else class="col-lg-8 customer-panel-box text-right">
          <div class="input-group">
            رمز عبور:
            <input
              class="input"
              v-model="password"
              ref="password"
              type="password"
              name=""
              placeholder="رمز عبور"
            />
          </div>
          <div class="input-group">
            تکرار رمز عبور:
            <input
              class="input"
              v-model="password_c"
              ref="password_c"
              type="password"
              name=""
              placeholder="تکرار رمز عبور"
            />
          </div>
          <input
            @click="saveData()"
            type="button"
            class="submit-btn"
            value="تغییر رمز عبور و بازگشت"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>

<script>
import SideMenu from "@/components/DashboardSideMenu.compoment";
import Breadcrumb from "@/components/breadcrumb.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "profile-edit",
  components: { Breadcrumb, SideMenu, Spinner },
  data: function() {
    return {
      password: "",
      password_c: "",
      loading: false
    };
  },
  computed: {},
  methods: {
    saveData() {
      // TODO Validation
      if (this.password.length === 0) {
        this.$refs.password.focus();
        return;
      }
      if (this.password_c.length === 0 || this.password !== this.password_c) {
        this.$refs.password_c.focus();
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("changePassword", {
          password: this.password
        })
        .then(res => {
          if (res) {
            this.$swal.fire({
              position: "center",
              icon: "success",
              title: "تغییرات شما با موفقیت لحاظ گردید.",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            this.$swal.fire({
              position: "center",
              icon: "error",
              title: "خطا در ویرایش رمزعبور!",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.loading = false;
        });
    }
  },
  mounted() {},
  created() {
    //this.$store.dispatch("getProfile");
  }
};
</script>
