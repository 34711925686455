<template>
  <div class="">
    <ul class="breadcrumb d-flex align-items-center w-100" >
      <li>
        <router-link class="" to="/">خانه</router-link>
        <i
            v-if="sectionName || (sectionNames && sectionNames.length !== 0)"
            class="fa fa-angle-left ml-3 mr-3 flex-shrink-0"
        ></i>
      </li>
      <li v-if="sectionName">
        <router-link class=" " :to="sectionAddr">{{ sectionName }}</router-link>
        <!-- <i class="fa fa-angle-left ml-3 mr-3 flex-shrink-0"></i> -->
      </li>
      <li v-for="(item, i) in sectionNames" :key="i">
        <router-link class=" " :to="'/category/' + item.slug">{{ item.name }}</router-link>
        <i
            v-if="i !== sectionNames.length - 1"
            class="fa fa-angle-left ml-3 mr-3 flex-shrink-0"
        ></i>
      </li>
      <li v-if="currentPageName">
        <i
            v-if="currentPageName"
            class="fa fa-angle-left ml-3 mr-3 flex-shrink-0"
        ></i>
        {{ currentPageName }}
      </li>
    </ul>
  </div>

</template>
<script>
export default {
  name: "breadcrumb",
  props: ["currentPageName", "sectionName", "sectionAddr", "sectionNames"]
};
</script>
<style scoped>
.breadcrumb {
  text-align: right;
  height: 55px;
color: #000;
}
.breadcrumb li {
  display: flex;
  align-items: center;
  color: #000;
}
.breadcrumb li > a {
  direction: initial;
}
.breadcrumb i {
  font-size: 20px;
  color: #000;
}
</style>
