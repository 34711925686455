<template>
  <Spinner v-if="loading"> </Spinner>

  <div v-else class="mb-5">
    <div v-if="products.length > 0">
      <h5 class="text-right ml-5 mr-5">محصولات مرتبط</h5>
      <VueSlickCarousel v-bind="settings">
        <ProductItem
          v-for="(product, index) in products"
          :key="product[index]"
          :product="product"
        ></ProductItem>
      </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ProductItem from "@/components/ProductItem.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "relativeproduct",
  props: ["productid"],
  components: {
    VueSlickCarousel,
    ProductItem,
    Spinner
  },
  data: function() {
    return {
      products: [],
      loading: true,
      settings: {
        dots: true,
        infinite: false,
        slidesToShow: 5,
        speed: 500,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  watch: {
    productid: function(newVal) {
      this.loading = true;

      this.$store.dispatch("getRelativeProduct", newVal).then(result => {
        this.products = result;
        this.loading = false;
      });
    }
  },
  created() {
    this.$store.dispatch("getRelativeProduct", this.productid).then(result => {
      this.products = result;
      this.loading = false;
    });
  }
};
</script>
