<template>
  <section class="contact">
    <Breadcrumb :currentPageName="title"></Breadcrumb>
    <div class="wrapper">
      <Spinner v-if="loading"> </Spinner>

      <div v-else class="col-xl-8 mt-5 mb-5">
        <div v-html="body">
          <!-- <div class="address-container">
          <ul>
          <li>
          <span class="bolder-span">
          <i class="fa fa-phone"></i>
          اطلاعات تماس :
          </span>
          <ul class="address-details">
          <li>تلفن تماس دفتر نشرچشمه: 88333588(021)</li>
          <li>تلفن تماس دفتر مرکزی: 88333600 (021)</li>
          <li>کتاب‌فروشی شعبه کارگر : 88333583-88333584(021)</li>
          <li>کتاب‌فروشی شعبه‌ی آرن: 75935455(021)</li>
          <li>کتاب‌فروشی شعبه‌ی کوروش: 44971990-44971989(021)</li>
          <li>کتاب‌فروشی شعبه‌ی بابل: 01132476571</li>
          <li>کتاب‌فروشی شعبه‌ی مشهد: 05138678587</li>
          <li>کتاب‌فروشی شعبه‌ی البرز: 026-35777501-5</li>
          <li>کتاب‌فروشی شعبه‌ی رشت: 09021498489</li>
          </ul>
          <hr />
          </li>
          <li>
          <span class="bolder-span">
          <i class="fa fa-envelope"></i>
          اطلاعات ایمیل :
          </span>
          <ul class="address-details">
          <li>دفتر نشر: cheshmeh.pr@gmail.com</li>
          <li>دفتر فروش: cheshmehfr@gmail.com</li>
          <li>منابع انسانی: hr@cheshmeh.ir</li>
          </ul>
          <hr />
          </li>
          <li>
          <span class="bolder-span">
          <i class="fa fa-map-marker"></i>
          نشانی:
          </span>
          <ul class="address-details">
          <li>
          تهران، خیابان کارگر شمالی، تقاطع بزرگراه شهید گمنام، خیابان
          چهارم، نبش خیابان صالحی، پلاک ۲
          </li>
          </ul>
          <hr />
          </li>
          <li class="d-flex align-items-center justify-content-center">
          <span class="bolder-span">
          شبکه های اجتماعی :
          </span>
          <ul class="social-media">
          <li>
          <a href="https://twitter.com/cheshmehpub/">
          توییتر
          </a>
          </li>
          <li>
          <a href="https://telegram.me/cheshmehpublication">
          تلگرام
          </a>
          </li>
          <li>
          <a href="https://www.instagram.com/cheshmehpublication/">
          اینستاگرام
          </a>
          </li>
          </ul>
          </li>
          </ul>
          </div> -->
        </div>
        <div class="border mt-5">
          <h5 class="text-center mt-3">ثبت نظر</h5>
          <div class="d-flex flex-wrap">
            <div class="col-md-6 col-sm-6">
              <input
                class="input"
                type="text"
                placeholder="نام"
                v-model="name"
              />
            </div>
            <div class="col-md-6 col-sm-6">
              <input
                class="input"
                type="email"
                placeholder="ایمیل"
                v-model="email"
              />
            </div>
          </div>
          <div class="col-xl-12">
            <input
              class="input"
              type="number"
              placeholder="شماره تماس"
              v-model="phone"
            />
          </div>
          <div class="col-xl-12">
            <textarea
              class="input"
              placeholder="پیام شما"
              v-model="message"
            ></textarea>
          </div>
          <div class="col-xl-12">
            <button class="submit-btn mb-3" type="submit" @click="sendMessage">
              ارسال
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Breadcrumb from "../../components/breadcrumb.component";
import Spinner from "@/components/Spinner.component";

export default {
  data() {
    return {
      name: null,
      email: null,
      message: null,
      phone: null,
      title: "",
      body: "",
      loading: false
    };
  },
  components: { Breadcrumb, Spinner },
  methods: {
    sendMessage: function() {
      if (
        this.name == null ||
        this.email == null ||
        this.message == null ||
        this.phone == null
      ) {
        this.$swal.fire({
          position: "top-end",
          icon: "error",
          title: "لطفا تمامی فیلد ها را پر نمایید !",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        this.$store
          .dispatch("saveContact", {
            data: {
              fullname: this.name,
              email: this.email,
              description: this.message,
              phone: this.phone
            }
          })
          .then(result => {
            if (result.success) {
              this.name = "";
              this.email = "";
              this.message = "";
              this.phone = "";
              this.$swal.fire({
                position: "top-end",
                icon: "success",
                title: "با موفقیت ثبت گردید !",
                showConfirmButton: false,
                timer: 1500
              });
            } else {
              this.$swal.fire({
                position: "top-end",
                icon: "error",
                title:
                  "مشکلی در ثبت پیغام شما رخ داده است لطفا دوباره تلاش نمایید !",
                showConfirmButton: false,
                timer: 1500
              });
            }
          });
      }
    }
  },
  created() {
    this.loading = true;

    //get details from backend
    this.$store.dispatch("getDetails", { slug: "contact-us" }).then(result => {
      if (result) {
        this.title = result.title;
        this.body = result.body;
      }
      this.loading = false;
    });
  }
};
</script>
<style scoped>
textarea.input {
  min-height: 100px;
}
.address-container ul {
  list-style: none;
}
.address-container {
  width: 100%;
  background: #f2f2f2;
  border-radius: 5px;
  padding: 1rem;
  text-align: right;
  line-height: 2;
  height: 100%;
  margin: auto;
}
.address-details {
  list-style-type: square !important;
  margin-right: 2rem;
}
.address-details li {
  display: flex;
  font-size: 15px;
  margin-bottom: 0.5rem;
}
.address-details li::before {
  content: "\f0c8";
  font-family: FontAwesome;
  margin-left: 0.5rem;
}
.social-media {
  display: flex;
}
.social-media li {
  font-size: 25px;
  margin: auto 1rem;
}
</style>
