<template>
  <div class="category-banner">
    <VueSlickCarousel v-bind="settings">
      <a v-for="(item, i) in items" :key="i" class="a-link" :href="item.url">
        <img
          class="img-responsive"
          style="max-width: 64px"
          v-lazy="item.image"
          @error="
            '/template/images/no-image.png';


          "
          alt=""
        />

        <b class="category_title"> {{ item.name }} </b>
      </a>
    </VueSlickCarousel>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "Banners",
  props: ["items"],
  components: {
    VueSlickCarousel
  },
  data: function() {
    return {
      settings: {
        dots: true,
        infinite: false,
        slidesToShow: 4,
        speed: 500,
        slidesPerRow: 1,
        responsive: [
          // {
          //   breakpoint: 1366,
          //   settings: {
          //     slidesToShow: 5,
          //     slidesToScroll: 5,
          //     infinite: true,
          //     dots: true,
          //   },
          // },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  computed: {},
  mounted() {},
  created() {
    /*
    this.$store.dispatch("getproductcategories").then((result) => {
      this.categories = result;
      this.loading = false;
    });
    */
  }
};
</script>

<style scoped>
.category-banner .a-link {
  position: relative;
  display: flex !important;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}
.category-banner img {
  object-fit: cover;
  width: 300px;
  height: 200px;
  margin: 0 2rem;
  border-radius: 10px;
}
.category-banner .category_title {
  display: block;
  text-align: center;
  margin: 5px;
  font-size: 14px;
}
@media only screen and (max-width: 670px) {
  .category-banner .slick-slider {
    margin: 0 2rem !important;
  }
  .category-banner img {
    width: 230px;
    height: 150px;
    margin: 0 1rem;
  }
}
</style>
