<template>
  <section class="about">
    <Breadcrumb :currentPageName="title"></Breadcrumb>
    <div class="wrapper">
      <Spinner v-if="loading"> </Spinner>
      <div v-else class="col-xl-8 mt-5 mb-5">
        <h5 class="text-center">
          {{ title }}
          <!-- درباره‌ی ما -->
        </h5>
        <div class="mt-5">
          <p v-html="body"></p>
          <!-- <p>
            نشرچشمه فعالیتش را از سال 1364 آغاز کرد. اوایل تعداد کتاب‌های
            چاپ‌شده‌مان بسیار اندک بود؛ چون ما از آن ناشرانی هستیم که از
            کتاب‌فروشی به انتشاراتی رسیدیم و سال‌های اولیه، هنوز روی رونق
            کتاب‌فروشی‌مان متمرکز بودیم. رفته‌رفته روند چاپ کتاب شکلی صعودی به
            خود گرفت، تا این‌که سال ۱۳۸۸ توانستیم بیش از ۲۷۰ عنوان کتاب در
            حوزه‌های مختلف منتشر کنیم.
            <br />
            نشرچشمه را ناشری ادبی می‌دانند. کتاب‌های مربوط به ادبیات در این نشر
            سهم زیادی را به خود اختصاص داده‌اند. نام بسیاری از بزرگان معاصر ادب
            ایران در فهرست اهالی قلم نشرچشمه به چشم می‌خورد که به کار کردن با
            آن‌ها افتخار می‌کنیم؛ بزرگانی چون احمد شاملو، محمود دولت‌آبادی،
            فریدون مشیری، علی‌اشرف درویشیان، صادق هدایت، هوشنگ ابتهاج (سایه)،
            ایرج افشار، احمدرضا احمدی و... . در کنار این‌ها، از همکاری با
            نویسندگان و مترجمان نوپا و بااستعداد غافل نبوده‌ایم. کسب بیش از
            ده‌ها جایزه‌ در سال 1388 نتیجه‌ی اعتماد به جوان‌ترها بود.
            <br />
            البته کتاب‌های نشرچشمه فقط در کتاب‌های ادبی‌ خلاصه نمی‌شوند. ما در
            حوزه‌های مختلف نظیر فلسفه، سیاست، اقتصاد، کودک و نوجوان، موسیقی،
            سینما، تئاتر، تاریخ و اسطوره و... کتاب منتشر کرده‌ایم. در چند سال
            اخیر هم آثارمان را دسته‌بندی کرده‌ایم تا مخاطب بهتر بتواند با آن‌ها
            ارتباط برقرار کند. هر مجموعه استاندارد و کیفیت خاص خود را دارد و این
            موضوع انتخاب را ساده‌تر می‌کند.
            <br />
            هدف کلی ما این بوده است که کارِ خوب ارائه دهیم؛ تا جایی که
            می‌توانیم، نظرات مختلف را منعکس کنیم و ضمن حفظ اصول‌مان، از
            جهت‌گیری‌های بی‌مورد و غیرضروری بپرهیزیم. امیدواریم روزی بیاید که در
            تمام خانه‌های ایران، در هر شهر و هر منطقه‌، کتاب‌های نشرچشمه باشد و
            خوانده شود، کل کارهامان به زبان‌های مختلف دنیا ترجمه شوند و ناشری
            جهانی شویم. بی‌شک تمام سعی‌مان را خواهیم کرد.
          </p> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumb from "../../components/breadcrumb.component";
import Spinner from "@/components/Spinner.component";

export default {
  name: "about",
  components: { Breadcrumb, Spinner },
  data() {
    return {
      title: "",
      loading: false,
      body: ""
    };
  },
  created() {
    this.loading = true;

    //get details from backend
    this.$store.dispatch("getDetails", { slug: "about" }).then(result => {
      if (result) {
        this.title = result.title;
        this.body = result.body;
      }
      this.loading = false;
    });
  }
};
</script>
<style scoped>
.about p {
  line-height: 2.5;
  text-align: justify;
}
.about p > br {
  margin-bottom: 1rem;
}
</style>
