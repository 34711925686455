<template>
  <div id="app">
    <!--loader-->
    <div
      v-if="$store.getters.getLoader"
      class="app-spinner spinner-border"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <!--loader-->

    <div
      id="loader-container"
      v-bind:class="{ hasLoader: $store.getters.getLoader }"
    >
      <Header></Header>
      <transition enter-active-class="animated fadeIn">
        <router-view></router-view>
      </transition>
      <quickview></quickview>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import Quickview from "@/components/quickview.component";

export default {
  name: "App",
  components: {
    Footer,
    Quickview,
    Header
  }
};
</script>
<style>
</style>
