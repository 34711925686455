<template>
  <div class="form col-xl-4 col-md-6">
    <div class="card p-3" style="background: #eee;border-radius: 15px">
      <div class="card-title">
        <img src="/template/images/User 24px.svg" alt="" />
        <h5>به سامانه نشر چشمه خوش آمدید</h5>
      </div>
      <div class="card-body">
        <div class="w-100" v-if="isNew">
          <div class="row mb-3">
            <label>نام و نام خانوادگی:</label>
            <input
                v-model="fullname"
                ref="fullname"
                type="text"
                class="input mt-0"
                placeholder="نام و نام خانوادگی"
            />
          </div>
          <div class="row mb-3">
            <label>ایمیل:</label>
            <input
                v-model="email"
                ref="email"
                class="input mt-0"
                type="email"
                placeholder="پست الکترونیک"
            />
          </div>
          <div class="row">
            <label>عنوان فروشگاه:</label>
            <input
                v-model="store_name"
                ref="storeName"
                type="text"
                class="input mt-0"
                placeholder="عنوان فروشگاه"
            />
          </div>
          <div class="row">
            <button class="submit-btn m-auto" @click="updateProfile">
              تکمیل اطلاعات
            </button>
          </div>
        </div>
        <div v-else>
          <router-link
              tag="input"
              type="button"
              class="info-btn w-100 mb-3"
              value="تکمیل حساب کاربری"
              to="/profile/edit"
          ></router-link>
          <router-link
              tag="input"
              class="submit-btn w-100"
              type="button"
              value="پروفایل"
              to="/profile"
          ></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "verify",
  data: function() {
    return {
      isNew: false,
      fullname: "",
      email: "",
      store_name: "",
      phone: ""
    };
  },
  mounted() {
    //get isNew
    this.isNew = this.$store.getters.getIsNew;
  },
  methods: {
    updateProfile() {
      //check validation
      if (this.fullname.length === 0) {
        this.$refs.fullname.focus();
        return;
      }
      if (this.email.length === 0) {
        this.$refs.email.focus();
        return;
      }
      if (this.store_name.length === 0) {
        this.$refs.storeName.focus();
        return;
      }

      //saveProfileInfo
      this.$store.dispatch("saveProfileInfo", {
        fullname: this.fullname,
        email: this.email,
        // phone: this.phone,
        store_name: this.store_name
      });

      if (this.$store.getters.getErrors) {
        this.$swal.fire({
          position: "center",
          icon: "warning",
          title: "مشکلی در ثبت رخ داده است ",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        this.$swal
          .fire({
            position: "center",
            icon: "success",
            title: "با موفقیت ثبت گردید !",
            showConfirmButton: false,
            timer: 1500
          })
          .then(() => {
            // this.$router.push("/profile");
          });
      }
    }
  }
};
</script>

<style scoped></style>
