<template>
  <div class="num-counter-container">
    <div class="num-counter">
      <div @click="decrease" class="in">-</div>
      <input
        type="number"
        ref="count"
        pattern="[0-9]{2}"
        :min="min"
        :max="max"
        v-model="count"
        @change="change"
      />
      <div @click="increase" class="dc">+</div>
    </div>
    <span style="fontsize">
      {{ message }}
    </span>
  </div>
</template>
<script>
export default {
  props: ["min", "max", "value"],
  data() {
    return {
      message: "",
      count: this.value
    };
  },
  watch: {
    count(newVal, oldVal) {
      if (Number(newVal) > Number(this.max)) {
        this.count = oldVal;
        this.message = "موجودی کمتر از درخواست شماست";
      } else if (Number(newVal) < Number(this.min)) {
        this.count = oldVal;
        this.message = "حداقل تعداد خرید";
      } else if (
        Number(oldVal) <= Number(this.max) &&
        Number(oldVal) >= Number(this.min)
      ) {
        this.message = "";
      }
      this.$emit("input", this.count);
    }
  },
  updated() {},
  methods: {
    increase() {
      if (Number(this.count) <= Number(this.max) + 1) {
        this.count++;
      }
    },
    decrease() {
      if (Number(this.count) >= Number(this.min) - 1) {
        this.count--;
      }
    },
    change() {
      this.message = "";
      // let vm = this;
      // setTimeout(function () {
      //   vm.$refs.count.blur();
      //   vm.$emit("change");
      // }, 1000);
    }
  }
};
</script>
<style scoped>
.num-counter-container {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.num-counter-container span {
  font-size: 11px;
  bottom: -20px;
  position: absolute;
  width: 100%;
  text-align: center;
  color: darkred;
}
.num-counter-container label {
  margin: 0 !important;
}
.num-counter {
  width: max-content;
  min-width: 80px;
  list-style: none;
  height: 38px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 15px;
}

.num-counter input {
  border: none;
  width: max-content;
  min-width: 50px;
  text-align: center;
}

.num-counter .dc,
.num-counter .in {
  width: 20px;
  cursor: pointer;
  text-align: center;
}
</style>
