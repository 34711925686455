<template>
  <Spinner v-if="loading" :circle="true"></Spinner>
  <div v-else class="offer-main-container">
    <router-link
      tag="a"
      value="مشاهده‌ی جزئیات محصول"
      type="button"
      :to="'/product/' + product.slug"
    >
      <div class="img-container">
        <img
          v-lazy="
            product.intro_image && product.intro_image.length !== 0
              ? product.intro_image
              : '/template/images/no-image.png'
          "
          @error="product.intro_image = '/template/images/no-image.png'"
          alt=""
        />
      </div>
      <div class="offer-details-container">
        <span style="color: #f75454">
          <!-- {{
            product.translator
          }} -->
          {{ product.brand }}
        </span>
        <b>
          {{ product.name }}
        </b>
        <span v-if="product.author" style="color: #7c6e65">
          {{ product.author }}
        </span>
        <!-- price -->
        <div
          class="d-flex flex-column align-items-start"
          style="direction: rtl"
        >
          <span class="price mi-txt hoverItem" v-if="product.quantity !== 0">
            <!-- special price -->
            <div v-if="product.special_price && product.special_price !== 0">
              <div class="d-flex align-items-center justify-content-center">
                <p class="old-price mb-0">
                  {{ new Intl.NumberFormat().format(product.price) }}
                  ریال
                </p>
                <p class="discount-percent mr-2 mb-0">
                  {{
                    new Intl.NumberFormat().format(
                      (
                        ((Number(product.price) -
                          Number(product.special_price)) /
                          Number(product.price)) *
                        100
                      ).toFixed(0)
                    ) + "%"
                  }}
                </p>
              </div>
              <p class="new-price m-0">
                {{ new Intl.NumberFormat().format(product.special_price) }}
                ریال
              </p>
            </div>
            <!-- special price -->

            <!-- price -->
            <p v-else class="new-price">
              {{ new Intl.NumberFormat().format(product.price) }}
              ریال
            </p>
            <!-- price -->
          </span>
          <span v-else style="color: red">ناموجود</span>
        </div>
        <!-- price -->
        <br />
        <h5>!عجله کنید</h5>

        <!-- time lefts -->
        <ul class="countdown">
          <li>
            <span>{{ dateTime.days }}</span
            >روز
          </li>
          <li>
            <span>{{ dateTime.hours }}</span
            >ساعت
          </li>
          <li>
            <span>{{ dateTime.minutes }}</span
            >دقیقه
          </li>
          <li>
            <span>{{ dateTime.seconds }}</span
            >ثانیه
          </li>
        </ul>
        <!-- time lefts -->
      </div>
    </router-link>

    <a
      v-if="Number(product.quantity) === 0"
      @click="registerOrder(product)"
      class="info-btn mb-2 mt-2"
      >سفارش کالا</a
    >
    <a v-else class="submit-btn mb-2 mt-2" @click="addToCard(product)">
      افزودن به سبد خرید
    </a>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.component";

export default {
  props: ["product"],
  components: { Spinner },
  data() {
    return {
      loading: false,
      // dateTime :new Date(this.product.date).getTime(),
      countDownDate: new Date(this.product.expire_at).getTime(),
      dateTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    };
  },
  mounted() {
    this.countDown();
  },
  methods: {
    addToCard(product) {
      //save in localstorage and update basket
      let selected = {
        image: product.intro_image,
        productId: product.id,
        name: product.name,
        slug: product.slug,
        price: product.price,
        min_limit: product.min_limit,
        max_limit: product.max_limit,
        quantity: 1,
        // publish: this.publish,
        publish_quantity: product.quantity,
        options: product.publish_id,
        special_price: product.special_price
      };

      this.$store
        .dispatch("addToBasket", {
          product: selected
        })
        .then(product => {
          //if user is login save in db
          if (localStorage.getItem("token")) {
            //enable basket loader
            this.$store.commit("setBasketLoading", true);

            this.$store
              .dispatch("addToBasketReq", { product: selected })
              .then(res => {
                if (!res) {
                  this.$swal
                    .fire({
                      position: "center",
                      icon: "warning",
                      title: "خطا در اضافه کردن محصول",
                      showConfirmButton: false,
                      timer: 1500
                    })
                    .then(() => {
                      //failed then remove from basket
                      this.$store.dispatch("removeFromBasket", {
                        product: product
                      });
                    });
                }
                //disable basket loader
                this.$store.commit("setBasketLoading", false);
              });
          }
        });
    },
    registerOrder(product) {
      let selected = {
        productId: product.id,
        attrId: product.publish_id,
        quantity: 1
      };

      //enable loader
      this.loading = true;
      this.$store
        .dispatch("registerOrder", {
          options: selected
        })
        .then(result => {
          if (result === 401) {
            this.$swal
              .fire({
                position: "center",
                icon: "warning",
                title: "لطفا وارد حساب کاربری خود شوید !",
                showConfirmButton: false,
                timer: 1500
              })
              .then(() => {
                this.$router.push({ name: "auth" });
                return;
              });
          } else if (result === 200) {
            this.$swal.fire({
              position: "center",
              icon: "success",
              title: "سفارش شما با موفقیت ثبت شد",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            this.$swal.fire({
              position: "center",
              icon: "error",
              title: "ثبت درخواست با مشکل مواجه شد",
              showConfirmButton: false,
              timer: 1500
            });
          }

          //disable loader
          this.loading = false;
        });
    },
    countDown() {
      let vm = this;
      var x = setInterval(function() {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = vm.countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        vm.dateTime.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        vm.dateTime.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        vm.dateTime.minutes = Math.floor(
          (distance % (1000 * 60 * 60)) / (1000 * 60)
        );
        vm.dateTime.seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
        }
      }, 1000);
    }
  }
};
</script>

<style scoped>
.offer-main-container {
  position: relative;
  cursor: pointer;
  margin: 0 auto;
  /* width: 200px; */
  height: 400px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #e7e7e7;
}
.offer-main-container:hover {
  border: 1px solid #000;
  box-shadow: 0 0 40px 0 rgba(22, 22, 25, 0.1);
}
.offer-main-container a:first-child {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  /* padding-bottom: 50px; */
}
.offer-main-container a:first-child:hover {
  color: #000 !important;
}
.offer-main-container .info-btn,
.offer-main-container .submit-btn {
  position: absolute;
  z-index: 100;
  bottom: 1rem;
  height: 25px;
  right: 1rem;
  width: calc(100% - 300px);
  font-size: 11px;
  align-items: center;
  display: flex;
  /* left: 0; */
  font-size: 13px;
  margin: 0 1rem;
  justify-content: center;
}
.offer-main-container .submit-btn {
  color: #000 !important;
  background-color: rgba(255, 68, 118, 1);
}
.offer-main-container .info-btn {
  color: #000 !important;
  border-color: rgba(255, 68, 118, 1);
}
.offer-main-container img {
  width: 100%;
  margin: 1rem auto;
  object-fit: contain;
  max-height: 350px;
}

.offer-main-container .img-container {
  width: 350px;
  margin: auto 1rem;
  /* height: 200px; */
  display: flex;
  justify-content: center;
}

.offer-main-container .show-product {
  margin-top: 1rem;
}

.offer-main-container .offer-details-container {
  /* background-color: #fff; */
  width: 100%;
  padding-right: 2rem;
  text-align: right;
  display: flex;
  flex-flow: column;
  line-height: 2;
}
/* countdown */
.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  width: 100%;
}
.countdown li {
  width: 100%;
  padding: 5px;
  text-align: center;
  color: gray;
  border-right: 1px solid #e7e7e7;
}
.countdown li:last-child {
  border-right: none;
}
.countdown li > span {
  font-weight: bold;
  color: #000 !important;
  font-size: 20px;
  margin-right: 10px;
}

@media only screen and (max-width: 700px) {
  .offer-main-container .offer-details-container {
    padding-right: 1rem;
  }
  .offer-main-container span,
  .offer-main-container b {
    width: 120px;
  }
  .offer-main-container img {
    width: 90%;
  }
  .offer-main-container .info-btn,
  .offer-main-container .submit-btn {
    font-size: 8px;
    width: calc(110% - 300px);
  }

  .countdown li,
  .countdown li > span {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 500px) {
  .offer-main-container {
    height: 500px;
  }
  .offer-main-container a {
    flex-flow: column !important;
  }
  .offer-main-container img {
    width: auto !important;
    max-height: 200px !important;
  }
  .offer-main-container .offer-details-container {
    align-items: flex-end !important;
  }
  .offer-main-container .img-container {
    margin: auto 0rem;
  }
  .offer-main-container .offer-details-container {
    margin-left: 0 !important;
    font-size: 13px;
  }
  .offer-main-container .info-btn,
  .offer-main-container .submit-btn {
    font-size: 8px;
    bottom: 5rem;
    height: 35px;
    right: unset !important;
    left: 1rem;
    width: max-content;
  }
  .countdown li,
  .countdown li > span {
    font-size: 10px !important;
  }
  .offer-main-container .offer-details-container {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
