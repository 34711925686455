<template>
  <div class="container">
    <Spinner v-if="loading"></Spinner>
    <div v-else class="card">
      <div class="card-header">
        <table class="table">
          <tbody>
            <tr>
              <td colspan="2" class="center" style="width: 25%; line-height: 2">
                <p class="text-rtl">
                  <img src="../../../../public/img/logo.png" width="70px" />
                </p>
                <p class="text-rtl">خریدار: {{ store_name }}</p>
                <p class="text-rtl">
                  نشاني:
                  {{ order.shipping_address }}
                </p>
                <p class="text-rtl">تلفن: {{ order.shipping_phone }}</p>
                <p class="text-rtl">وضعیت سفارش: {{ getStatus }}</p>
              </td>
              <td colspan="4" class="center" style="width: 50%">
                <p>بگذارید کتاب‌ها ما را بخوانند...</p>
                <h3 style="font-family: BNazanin !important">پخش چشمه</h3>
                <p>پیش فاکتور اینترنتی</p>
              </td>
              <td colspan="2" class="center" style="width: 25%; line-height: 2">
                <p class="text-rtl">شماره‌ی صفحه: 1 از 1</p>
                <p class="text-rtl">شماره‌ فاکتور: {{ order.id }}</p>
                <p class="text-rtl">
                  تاریخ فاکتور:
                  {{ order.created_at | moment("jYY/jMM/jDD") }}
                </p>
                <p class="text-rtl">کد مشتری: {{ order.customer_id }}</p>
                <!-- <p class="text-rtl">کد کاربر : 124</p> -->
                <p class="text-rtl">نام انبار: انبار پخش</p>
                <p class="text-rtl">
                  ساعت: {{ order.created_at | moment("HH:mm") }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body">
        <div class="row mb-4">
          <table class="table">
            <tbody>
              <tr>
                <td class="text-center" style="width: 5%">
                  <p>ردیف</p>
                </td>
                <td class="text-center" style="width: 40%">
                  <p>عنوان کتاب</p>
                </td>
                <td class="text-center">
                  <p>تعداد</p>
                </td>
                <td class="text-center">
                  <p>پشت جلد</p>
                </td>
                <td class="text-center">
                  <p>چاپ</p>
                </td>
                <td class="text-center">
                  <p>ناشر</p>
                </td>
                <td class="text-center">
                  <p>تخفیف</p>
                </td>
                <td class="text-center">
                  <p>مبلغ کل</p>
                </td>
                <td class="text-center" style="width: 10%">
                  <p>بارکد</p>
                </td>
              </tr>
              <tr v-for="(product, index) in products" :key="index">
                <td class="text-center">
                  <p>{{ index + 1 }}</p>
                </td>
                <td class="center">{{ product.name }}</td>
                <td class="text-center">{{ product.qty }}</td>
                <td class="text-center">
                  {{ new Intl.NumberFormat().format(product.price) }}
                </td>
                <td class="text-center">{{ product.option }}</td>
                <td class="center">{{ product.brand }}</td>
                <td class="text-center">
                  {{
                    product.special_price &&
                    product.special_price !== product.price
                      ? new Intl.NumberFormat().format(
                          (
                            ((product.price - product.special_price) /
                              product.price) *
                            100
                          ).toFixed(0)
                        ) + "%"
                      : 0
                  }}
                </td>
                <td class="text-center">
                  {{
                    new Intl.NumberFormat().format(
                      Number(product.qty) * Number(product.price)
                    )
                  }}
                </td>
                <td class="text-center">{{ product.isbn }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table">
            <tbody>
              <tr>
                <td class="center">
                  مانده از قبل با احتساب این فاکتور: 0 بدهکار
                </td>
                <td class="center">جمع تعداد: {{ count().total }}</td>
                <td class="center">جمع پشت جلد فاکتور</td>
                <td class="text-center">
                  {{ new Intl.NumberFormat().format(price().total) }} ریال
                </td>
              </tr>
              <tr>
                <td rowspan="4" class="center" style="width: 60%">
                  توضيحات: {{ order.description }}
                </td>
                <td class="center">مبلغ تخفیف فاکتور</td>
                <td colspan="2" class="text-center">
                  {{
                    order.discount && order.discount >= 100
                      ? order.discount + "ریال"
                      : order.discount
                      ? order.discount + "%"
                      : "0"
                  }}
                </td>
              </tr>
              <tr>
                <td class="center">جمع خالص فاکتور</td>
                <td colspan="2" class="text-center">
                  {{ new Intl.NumberFormat().format(order.total_price) }} ریال
                </td>
              </tr>
              <tr>
                <td class="center">هزینه ی ارسال</td>
                <td colspan="2" class="text-center">
                  {{
                    new Intl.NumberFormat().format(
                      order.get_shipping ? order.get_shipping.price : 0
                    )
                  }}
                  ریال
                </td>
              </tr>
              <tr>
                <td class="center">جمع کسورات و اضافات فاکتور</td>
                <td colspan="2" class="text-center">0</td>
              </tr>
              <tr>
                <td class="center">جمع خالص دریافتی فاکتور</td>
                <td colspan="3" class="text-center">
                  {{ new Intl.NumberFormat().format(order.total_price) }} ریال
                </td>
              </tr>
              <!-- <tr>
                <td colspan="6" class="text-left">
                  مبلغ فاکتور به حروف میاد اینجا ریالم هست
                </td>
              </tr> -->
            </tbody>
          </table>
          <!-- This table has no variables and is for display only-->
          <table class="table">
            <tbody>
              <tr rowspan="3">
                <td class="center text-center" style="height: 10rem">
                  مهر و امضاء فروشنده
                </td>
                <td colspan="3" class="center text-center">
                  مهر و امضاء خریدار
                </td>
              </tr>
              <tr>
                <td colspan="6" class="center text-center">
                  <p class="mb-2">
                    شماره حساب: ٧٠٠٨١٧٣٠٨٦٤٩ شماره كارت: ٥٠٤٧٠٦١٠٤١٤٦٦١٦٦ شبا:
                    IR٣٦٠٦١٠٠٠٠٠٠٠٧٠٠٨١٧٣٠٨٦٤٩
                  </p>
                  <p>بانك شهر: به نام آقاي روزبه كيائيان موسوي</p>
                </td>
              </tr>
              <tr>
                <td colspan="6" class="text-center">
                  <p class="mb-2">
                    آدرس: تهران، خيابان دماوند، بعد از سه‌راه تهرانپارس، خيابان
                    اتحاد، اتحاد ١١ غربي، پلاك ٨
                  </p>
                  <div class="d-flex justify-content-around">
                    <p>کد پستی: 1658757611</p>
                    <p>تلفن: 77144808-77788502-77144821-021</p>

                    <p>سفارش تهران: 09107607165</p>
                    <p>سفارش شهرستان: 09193706740</p>
                  </div>
                </td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <button id="printPageButton" class="submit-btn" onclick="window.print();">
      چاپ فاکتور
    </button>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.component";

export default {
  name: "print",
  components: { Spinner },
  data() {
    return {
      order: {},
      products: [],
      status: "",
      shipping_name: "",
      store_name: "",
      loading: false
    };
  },
  computed: {
    getStatus() {
      let res = "";

      switch (this.order.status) {
        case "primary":
          res = "ثبت اولیه";
          break;
        case "delivery":
          res = "در حال ارسال";
          break;
        case "lost":
          res = "لغو سفارش";
          break;
        case "accept":
          res = "تایید سفارش";
          break;
        case "delivered":
          res = "تحویل داده شده";
          break;
        case "returned":
          res = "مرجوع";
          break;
        case "accept_returned":
          res = "تایید مرجوعی";
          break;
        case "reject_returened":
          res = "لغو مرجوعی";
          break;
        default: //do nothing
      }
      return res;
    }
  },
  created() {
    this.loading = true;

    this.$store
      .dispatch("getOrderInfo", { orderId: this.$route.params.id })
      .then(result => {
        this.order = result.order;
        this.products = result.products;
        this.shipping_name = result.shipping_name;
        this.store_name = result.store_name;
        this.loading = false;
      });
  },
  methods: {
    count() {
      let total = 0;

      this.products.forEach(item => {
        total += item.qty;
      });

      return { total };
    },
    price() {
      let total = 0;

      this.products.forEach(item => {
        total += Number(item.price) * Number(item.qty);
      });

      return { total };
    },
    discountCalc() {
      let total = 0;

      this.products.forEach(
        item => (total += Number(item.price) * Number(item.quantity))
      );

      let special_prices = 0;

      this.products.forEach(
        item =>
          (special_prices +=
            item.special_price && item.special_price != 0
              ? Number(item.special_price) * Number(item.quantity)
              : 0)
      );

      let res = (
        ((Number(total) - Number(special_prices)) / Number(total)) *
        100
      ).toFixed(0);
      return { res };
    }
  }
};
</script>
<style scoped>
.container {
  display: grid;
  justify-content: center;
  padding: 3rem;
}
.text-rtl {
  font-family: BNazanin !important;

  text-align: right;
}
.text-center {
  font-family: BNazanin !important;

  text-align: center;
  vertical-align: inherit;
}
.text-left {
  text-align: left;
}

input[type="checkbox"] {
  transform: scale(2);
  margin: 0.5rem;
}

.card {
  border: none !important;
}
.table {
  margin-bottom: 0px;
}
.table p {
  font-family: BNazanin !important;
  margin: 0 !important;
}
.card-header {
  text-align: center;
  border: 1px solid black;
  background-color: #fff;
}

.card .card-body {
  direction: rtl;
  text-align: right;
}
.card-body {
  padding: 1rem;
  padding-top: 0 !important;
}
#printPageButton {
  margin: 2rem auto;
  width: 150px;
}

.table td,
.table th {
  font-family: BNazanin !important;

  border: none;
}
.card-body table td {
  border: 1px solid black;
}
</style>
